import axios from 'axios'

const DELIVERY_NOTIFICATIONS_API_ENDPOINT = 'https://delivery.paykeeper.ru/kvdb/key';

const NotificationsAPI = {

  async getGlobalStatusData() {
    try {
      let pld = (await axios.get(`${DELIVERY_NOTIFICATIONS_API_ENDPOINT}/global_status`)).data;
      if (pld == undefined || pld.result == undefined) {
        return undefined;
      }
      let {result} = pld;
      return result;
    } catch (e) {
      return undefined;
    }
  }

}
export default NotificationsAPI

function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}
