import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import axios from 'axios'

import * as EmailValidator from 'email-validator';

import {PkInput} from "../../ui/Input";
import {PkTextarea} from "../../ui/Textarea";
import DeliveryHelper from "../../helpers/DeliveryHelper";

import {PkButton} from "../../ui/Button";
import PrettyCitySelect from "../../tools/PrettyCitySelect";


export default function UpdateTemplateForm(props) {
  const {
    code, street, building, phone, email, description, buttonName = 'Сохранить',
    onSave = d => {

    },
    canDelete = false,
    onDelete = () => {

    },
    loading = false
  } = props;

  const [formData, setFormData] = useState({
    name: props.name,

    from_code: props.from_code,
    from_name: props.from_name == undefined ? '' : props.from_name,
    from_email: props.from_email == undefined ? '' : props.from_email,
    from_phone: props.from_phone == undefined ? '' : props.from_phone,
    from_street: props.from_street == undefined ? '' : props.from_street,
    from_building: props.from_building == undefined ? '' : props.from_building,
    from_comment: props.from_comment == undefined ? '' : props.from_comment,

    to_code: props.to_code,
    to_name: props.to_name == undefined ? '' : props.to_name,
    to_email: props.to_email == undefined ? '' : props.to_email,
    to_phone: props.to_phone == undefined ? '' : props.to_phone,
    to_street: props.to_street == undefined ? '' : props.to_street,
    to_building: props.to_building == undefined ? '' : props.to_building,
    to_comment: props.to_comment == undefined ? '' : props.to_comment
  });

  const canSubmit = true;

  return (
    <Wrapper>

      <VeryTopPlaceholder>
        <Field>
          <FLeft>
            Название шаблона
          </FLeft>
          <FRight>
            <PkInput placeholder={''} value={formData.name} onChange={evt => {
              setFormData({...formData, name: evt.target.value});
            }}/>
          </FRight>
        </Field>
      </VeryTopPlaceholder>

      <MainRow>

        <Half>

          <HalfHeading>
            <span>
              ОТКУДА
            </span>
          </HalfHeading>

          <Field>
            <FLeft>
              Населенный пункт
            </FLeft>
            <FRight>
              <PrettyCitySelect code={formData.from_code} onChange={newCode => {
                setFormData({...formData, from_code: newCode})
              }}/>
            </FRight>
          </Field>

          <Field>
            <FLeft>
              Улица
            </FLeft>
            <FRight>
              <PkInput placeholder={''} value={formData.from_street} onChange={evt => {
                setFormData({...formData, from_street: evt.target.value});
              }}/>
            </FRight>
          </Field>

          <Field>
            <FLeft>
              Номер дома
            </FLeft>
            <FRight>
              <PkInput placeholder={''} value={formData.from_building} onChange={evt => {
                setFormData({...formData, from_building: evt.target.value});
              }}/>
            </FRight>
          </Field>

          <Field>
            <FLeft>
              Отправитель
            </FLeft>
            <FRight>
              <PkInput placeholder={''} value={formData.from_name} onChange={evt => {
                setFormData({...formData, from_name: evt.target.value});
              }}/>
            </FRight>
          </Field>


          <Field>
            <FLeft>
              Телефон
            </FLeft>
            <FRight>
              <PkInput placeholder={''} value={formData.from_phone} onChange={evt => {
                setFormData({...formData, from_phone: evt.target.value});
              }}/>
            </FRight>
          </Field>

          <Field>
            <FLeft>
              E-mail
            </FLeft>
            <FRight>
              <PkInput
                bad={(formData.from_email.trim().length) > 0 && !EmailValidator.validate(formData.from_email)}
                placeholder={''} value={formData.from_email} onChange={evt => {
                setFormData({...formData, from_email: evt.target.value.trim()});
              }}/>
            </FRight>
          </Field>

          <Field>
            <FLeft>
              Примечание
            </FLeft>
            <FRight>
              <PkTextarea minRows={2}
                          placeholder={''}
                          value={formData.from_comment}
                          onChange={evt => {
                            setFormData({...formData, from_comment: evt.target.value});
                          }}
              />
            </FRight>
          </Field>

        </Half>

        <Half>

          <HalfHeading>
            <span>
              КУДА
            </span>
          </HalfHeading>

          <Field>
            <FLeft>
              Населенный пункт
            </FLeft>
            <FRight>
              <PrettyCitySelect code={formData.to_code} onChange={newCode => {
                setFormData({...formData, to_code: newCode})
              }}/>
            </FRight>
          </Field>

          <Field>
            <FLeft>
              Улица
            </FLeft>
            <FRight>
              <PkInput placeholder={''} value={formData.to_street} onChange={evt => {
                setFormData({...formData, to_street: evt.target.value});
              }}/>
            </FRight>
          </Field>

          <Field>
            <FLeft>
              Номер дома
            </FLeft>
            <FRight>
              <PkInput placeholder={''} value={formData.to_building} onChange={evt => {
                setFormData({...formData, to_building: evt.target.value});
              }}/>
            </FRight>
          </Field>

          <Field>
            <FLeft>
              Получатель
            </FLeft>
            <FRight>
              <PkInput placeholder={''} value={formData.to_name} onChange={evt => {
                setFormData({...formData, to_name: evt.target.value});
              }}/>
            </FRight>
          </Field>

          <Field>
            <FLeft>
              Телефон
            </FLeft>
            <FRight>
              <PkInput placeholder={''} value={formData.to_phone} onChange={evt => {
                setFormData({...formData, to_phone: evt.target.value});
              }}/>
            </FRight>
          </Field>

          <Field>
            <FLeft>
              E-mail
            </FLeft>
            <FRight>
              <PkInput
                bad={formData.to_email.trim().length > 0 && !EmailValidator.validate(formData.to_email)}
                placeholder={''} value={formData.to_email} onChange={evt => {
                setFormData({...formData, to_email: evt.target.value.trim()});
              }}/>
            </FRight>
          </Field>

          <Field>
            <FLeft>
              Примечание
            </FLeft>
            <FRight>
              <PkTextarea minRows={2}
                          placeholder={''}
                          value={formData.to_comment}
                          onChange={evt => {
                            setFormData({...formData, to_comment: evt.target.value});
                          }}
              />
            </FRight>
          </Field>

        </Half>

      </MainRow>


      <BottomPlaceholder>
        <ButtonPlaceholder>
          {canDelete == false ? null :
            <span>
              {loading == true ? <span>...</span> :
                <DeleteSpan onClick={() => {
                  onDelete();
                }}>
                  удалить
                </DeleteSpan>
              }
            </span>

          }
          <PkButton disabled={!canSubmit} onClick={() => {
            if (canSubmit == false) {
              return;
            }
            onSave(formData);
          }}>
            {buttonName}
          </PkButton>
        </ButtonPlaceholder>
      </BottomPlaceholder>

    </Wrapper>
  );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-top: 17px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const FLeft = styled.div`
    width: 170px;
`;

const FRight = styled.div`
    flex: 1;
`;

const BottomPlaceholder = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

const ButtonPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

const DeleteSpan = styled.div`
    color: red;
    cursor: pointer;
    opacity: 0.7;
    margin-right: 20px;
    :hover{
      opacity: 1;
      text-decoration: underline;
    }
`;


const MainRow = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 20px;
    @media(max-width: 720px){
      display: block;
    }
`;

const Hr = styled.div`
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

const Half = styled.div`
    flex: 1;
    padding-right: 15px;
    padding-left: 15px;
    box-sizing: border-box;
    :first-of-type{
      padding-left: 0px;
    }
    :last-of-type{
      padding-right: 0px;
    }
`;

const HalfHeading = styled.div`
    color: #a9a9a9;
    margin-bottom: 3px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const VeryTopPlaceholder = styled.div`
    margin-bottom: 40px;
`;
