import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import axios from 'axios'
import {MAIN_RED_COLOR} from "../ui/Colors";
import THEME from '../theme'
import DeliveryHelper from "../helpers/DeliveryHelper";

const DAY_DURATION = 86400000;
const SQUARE_SIZE = 32;


const {CALENDAR_BACKGROUND_COLOR, PAGINATOR_BUTTON_COLOR, CALENDAR_SELECTED_DAY_COLOR} = THEME;


export default function SimpleMonthCalendar(props) {
    let {
        selectedTimestamp = undefined,
        hasLeftArrow = true, hasRightArrow = true, rightActive = true, leftActive = true,
        onChange = a => {

        },
        canSelectPastDays = true,
        canSelectToday = true
    } = props;

    let [monthTimestamp, setMonthTimestamp] = useState(+moment(selectedTimestamp).startOf('month'));

    const start = +moment(monthTimestamp).startOf('month').startOf('isoweek').startOf('day');
    const end = +moment(monthTimestamp).endOf('month').endOf('isoweek').startOf('day');

    let weeks = [], t = start;
    while (t < end) {
        weeks = weeks.concat(t);
        t = +t + 7 * DAY_DURATION;
    }

    return (
        <Wrapper style={{width: SQUARE_SIZE * 7}}>

            <TopPlaceholder style={{height: SQUARE_SIZE, backgroundColor: '#E8E8E8'}}>
                <Side>
                    {hasLeftArrow == false ? null :
                        <Arrow src={require('../assets/play-button.svg')}
                               style={{transform: `rotate(180deg)`}}
                               onClick={() => {
                                   setMonthTimestamp(+moment(+moment(monthTimestamp).add(-1, 'months')).startOf('month'));
                               }}/>
                    }
                </Side>
                <CurrentMonth>
                    {/*{moment(monthTimestamp).format('MMMM YYYY')}*/}
                    {DeliveryHelper.getRuMonthString(monthTimestamp)}
                </CurrentMonth>
                <Side>
                    {hasRightArrow == false ? null :
                        <Arrow src={require('../assets/play-button.svg')} onClick={() => {
                            setMonthTimestamp(+moment(+moment(monthTimestamp).add(1, 'months')).startOf('month'));
                        }}/>
                    }
                </Side>
            </TopPlaceholder>

            <ContentPlaceholder>
                <Weeks>
                    <WeekRow style={{height: SQUARE_SIZE, backgroundColor: 'white'}}>
                        <Days>
                            {['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'].map((a, k) => {
                                return (
                                    <DayItem key={k}>
                                        <DayName>
                                            {a}
                                        </DayName>
                                    </DayItem>
                                )
                            })}
                        </Days>
                    </WeekRow>
                    {weeks.map((w, k) => {
                        let days = Array.from(new Array(7)).map((x, i) => (+w + i * DAY_DURATION));
                        return (
                            <WeekRow key={`${w}_${k}`}>
                                <Days>
                                    {days.map((d, j) => {
                                        let isSelected = (selectedTimestamp != undefined && +moment(selectedTimestamp).startOf('day') == +moment(d).startOf('day'));
                                        let isPast = (+moment(+d).startOf('day') < +moment().startOf('day'));
                                        let isToday = (+moment(+d).startOf('day') == +moment().startOf('day'));
                                        let isInactive = (isPast && (canSelectPastDays == false)) || (isToday && (canSelectToday == false));
                                        return (
                                            <DayItem selected={isSelected}
                                                     inactive={isInactive}
                                                     key={`${d}_${j}`}
                                                     style={{width: SQUARE_SIZE, height: SQUARE_SIZE}}
                                                     onClick={() => {
                                                         if (isInactive == true) {
                                                             return;
                                                         }
                                                         onChange(d);
                                                     }}
                                            >
                                                <DayName>
                                                    {moment(d).format('D')}
                                                </DayName>
                                            </DayItem>
                                        )
                                    })}
                                </Days>
                            </WeekRow>
                        )
                    })}
                </Weeks>
            </ContentPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    background-color: #F2F2F2;
`;

const TopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
    text-transform: capitalize;
`;

const ContentPlaceholder = styled.div`
    
`;

const Side = styled.div`
    width: 40px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const CurrentMonth = styled.div`
    text-align: center;
    flex: 1;
`;

const Arrow = styled.img`
    width: 14px;
    height: 14px;
    opacity: ${props => (props.active == true ? 0.8 : 0.3)};
    cursor: ${props => (props.active == true ? 'pointer' : 'default')};
    :hover{
      opacity: 1;
    }
`;

const Weeks = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
`;

const WeekRow = styled.div`
    width: 100%;
    flex: 1;
`;

const Days = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;  
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const DayItem = styled.div`
    flex: 1;
    max-height: 120px;
    overflow-y: auto;
    width: ${SQUARE_SIZE}px;
    box-sizing: border-box;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: ${props => (props.inactive == true ? 1 : 1)};
    cursor: ${props => (props.inactive == true ? 'default' : 'pointer')};
    background: ${props => (props.selected == true ? CALENDAR_SELECTED_DAY_COLOR : 'transparent')};
    color: ${props => (props.selected == true ? 'white' : (props.inactive == true ? '#ddd' : 'black'))};
    border-radius: 4px;
    :hover{
      background: ${props => (props.inactive == true ? 'transparent' : CALENDAR_BACKGROUND_COLOR)};
      color: ${props => (props.inactive == true ? '#ddd' : 'white')};
    }
`;

const DayName = styled.div`
    font-size: 14px;
    opacity: 0.9;
`;
