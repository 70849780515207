import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import PkTemplate from "../templates/PkTemplate";
import CalculatorPanel from "../delivery/calculator/CalculatorPanel";
import AllPvzPanel from "../pvz/AllPvzPanel";
import AllPvzMapPanel from "../pvz/AllPvzMapPanel";
import MapWithClusters from "../maps/MapWithClusters";
import NewLeadPanel from "../leads/panels/NewLeadPanel";
import RootDeliveryComponent from "../delivery2/RootDeliveryComponent";

import './style.css'

export default function DemoApp(props) {

    return (
        <SabirWrapper>
            <SabirInner>
                <SabirHeader>

                    <LogoTopLeft onClick={() => {
                        window.location.href = `/`;
                    }}>
                        <TruckLogo src={require('../../assets/images/pk_truck.svg')}/>
                        <SabirLogo src={require('../../assets/images/pak_logo.svg')}/>
                    </LogoTopLeft>


                    <DeliveryRightDiv onClick={() => {
                        // window.alert('Раздел находится в разработке');
                        // window.location.href = `/#/demo`;
                    }}>
                        {/*<PinImg src={require('../../assets/images/del_pin.svg')}/>*/}
                        <span>
                            Демо-версия
                        </span>
                    </DeliveryRightDiv>

                </SabirHeader>

                <SabirContent>

                    <RootDeliveryComponent/>

                </SabirContent>

            </SabirInner>
        </SabirWrapper>
    )
}

const DeliveryRightDiv = styled.div`
    //background: #FFCC00;
    box-sizing: border-box;
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: white;
    //cursor: pointer;
    //:hover{
    //  opacity: 0.97;
    //}
`;

const PinImg = styled.img`
    width: 22px;
    margin-right: 20px;
`;

const Header = styled.div`
    font-size: 36px;
    line-height: 47px;
    font-weight: normal;
    color: #000000;
    margin-bottom: 37px;
`;

const SubHeader = styled.div`
    font-size: 24px;
    line-height: 31px;
    color: #000000;
    margin-bottom: 8px;
    margin-top: 24px;
`;

const LogoTopLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 39px;
    cursor: pointer;
`;

const Section = styled.div`
    margin-bottom: 40px;
    :last-of-type{
      padding-bottom: 20px;
      margin-bottom: 0px;
    }
`;

const SabirWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    background: #F4F4F4;
    font-family: PT Sans;
    overflow-x: hidden !important;
`;

const TruckLogo = styled.img`
    width: 44px;
    height: 29px;
    margin-right: 18px;
`;

const SabirLogo = styled.img`
    height: 27px;
`;

const SabirInner = styled.div`
    //font-family: PT Sans,Arial,sans-serif;
    font-family: "PT Sans";

    width: 1120px;
    margin: 0 auto;
    box-sizing: border-box;
    @meida(max-width: 1120px){
      width: 100%;
    }
    border-left: 1px solid rgba(0,0,0,0.2);
    border-right: 1px solid rgba(0,0,0,0.2);
`;

const SabirHeader = styled.div`
    height: 62px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
    background: #b01c2e;
    justify-content: space-between;
    box-sizing: border-box;
`;

const SabirContent = styled.div`
    background: white;
    box-sizing: border-box;
    padding-left: 9px;
    padding-right: 9px;
    padding-bottom: 30px;
    padding-top: 30px;
`;

const FullPageWrapper = styled.div`
    box-sizing: border-box;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden !important;
`;

const Wrapper100 = styled.div`
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    max-height: 100%;
`;

const Half = styled.div`
    flex: 1;
    box-sizing: border-box;
    height: 100vh;
`;

const Wrapper = styled.div`
    padding: 15px;
    width: 1020px;
    box-sizing: border-box;
    margin: 0 auto;
    
    
    :first-of-type{
      padding-bottom: 15px;
      border-bottom: 1px solid whitesmoke;
      margin-bottom: 15px;
    }
    @media(max-width: 1020px){
       width: 1020px;
    }
`;

const MyHeading = styled.div`
    font-size: 17pt;
    line-height: 20px;
    margin-bottom: 22px;
    color: #3b3838;
`;

const P = styled.div`
    font-size: 18px;
    line-height: 23px;
    color: #000000;
    margin-bottom: 10px;
`;

const MainWrapper = styled.div`
    
`;
