import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import OrderDetailView from "../views/OrderDetailView";

const greyMinus = require('../../assets/grey_minus.png');
const greyPlus = require('../../assets/grey_plus.png');
const orangePlus = require('../../assets/orange_plus.png');

const getIconByVendor = (vendorName = 'cdek') => {
    if (vendorName.toLowerCase() == 'yandex') {
        return require('../../assets/yandex_taxi_logo_square.png')
    }
    return require('../../assets/cdek_logo_square.png')
}

export default function OrdersTable(props) {
    const {
        login, password,
        onDelete = ordId => {

        },
        orders = [
            {
                id: 1,
                order_number: '23432123',
                from_name: 'Сергеева Ирина Геннадьевна',
                to_name: 'Петров Пётр Петрович',
                price_for_customer: 100500,
                status: 'success',
                createdAt: new Date()
            },
            {
                id: 2,
                order_number: '23432123',
                from_name: 'Сергеева Ирина Геннадьевна',
                to_name: 'Петров Пётр Петрович',
                price_for_customer: 100500,
                status: 'success',
                createdAt: new Date()
            },
        ]
    } = props;
    const [expandedSet, setExpandedSet] = useState(Set());

    return (
        <Wrapper>

            <Table className={'table b-table'} id={'delivery-btable'}>
                <Thead>
                <tr>
                    <Th>
                        <ThSpan>
                            Номер
                        </ThSpan>
                    </Th>
                    <Th>
                        <ThSpan>
                            {''}
                        </ThSpan>
                    </Th>
                    <Th>
                        <ThSpan>
                            Дата
                        </ThSpan>
                    </Th>
                    <Th>
                        <ThSpan>
                            Отправитель
                        </ThSpan>
                    </Th>
                    <Th>
                        <ThSpan>
                            Получатель
                        </ThSpan>
                    </Th>
                    <Th style={{minWidth: 90}}>
                        <ThSpan>
                            Цена, RUB
                        </ThSpan>
                    </Th>
                    <Th>
                        <ThSpan>
                            Статус
                        </ThSpan>
                    </Th>

                    <LastTh>
                        <PlusImage/>
                    </LastTh>
                </tr>
                </Thead>

                <Tbody>
                {orders.map((ord, k) => {
                    let {
                        order_number, createdAt,
                        partner,
                        from_name, to_name, price_for_customer, status, id, status_color, status_text
                    } = ord;
                    let expanded = expandedSet.has(id);
                    let vendorIcon = getIconByVendor(`${partner}`.toLowerCase());
                    return (
                        <React.Fragment key={`${ord.id}_${k}_`}>
                            <Tr expanded={expanded} key={ord.id} onClick={() => {
                                if (expanded == true) {
                                    setExpandedSet(expandedSet.delete(ord.id));
                                } else {
                                    setExpandedSet(expandedSet.add(ord.id));
                                }
                            }}>
                                <FirstTd>
                                    {order_number}
                                </FirstTd>
                                <VendorTd>
                                    <VendorInnerPlaceholder>
                                        <VendorIcon src={vendorIcon}/>
                                    </VendorInnerPlaceholder>
                                </VendorTd>
                                <Td>
                                    {moment(createdAt).format('DD.MM.YYYY')}
                                </Td>
                                <NameTd>
                                    {from_name}
                                </NameTd>
                                <NameTd>
                                    {to_name}
                                </NameTd>
                                <Td>
                                    {price_for_customer}
                                </Td>
                                <StatusTd color={status_color}>
                                    {status_text}
                                </StatusTd>

                                <LastTd expanded={expanded}>
                                    {expanded == true ? <MinusImage/> : <PlusImage/>}
                                </LastTd>
                            </Tr>
                            {expanded == false ? null :
                                <tr key={`exp_${ord.id}`} role={'row'} tabIndex={-1} className={'b-table-details'}>
                                    <td colSpan={7} role={'cell'}>
                                        <div>
                                            <div className={'row-detaiReact.Fragmentls-block'}>
                                                <OrderDetailView {...ord} login={login} password={password}
                                                                 onDelete={onDelete}/>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            }
                        </React.Fragment>
                    )
                })}
                </Tbody>

            </Table>

        </Wrapper>
    );
}

const Tbody = styled.tbody`
    
`;

const Wrapper = styled.div`
    width: 100%;
`;

const Table = styled.table`
    width: 100%;
    border-spacing: 0;
    border-radius: 5px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
`;

const Tr = styled.tr`
    background-color: #FFFFFF;
    outline: none;
    cursor: pointer;
`;

const Td = styled.td`
    border: 1px solid #dee2e6;
    padding: 0.75rem;
    vertical-align: middle;
`;

const NameTd = styled.td`
    border: 1px solid #dee2e6;
    padding: 0.75rem;
    vertical-align: middle;
    max-width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const FirstTd = styled.td`
    border: 1px solid #dee2e6;
    padding: 0.75rem;
    vertical-align: middle;
    font-weight: bold;
    font-size: 12px;
`;

const StatusTd = styled.td`
    border: 1px solid #dee2e6;
    vertical-align: middle;
    width: 130px;
    text-align: center;
    background: ${props => (props.color == undefined ? 'transparent' : props.color)};
    color: ${props => (props.color == undefined ? 'black' : 'white')} !important;
    font-size: 12px;
    font-weight: bold;
`;

const VendorTd = styled.td`
    border: 1px solid #dee2e6;
    vertical-align: middle;
    width: 46px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    padding: 0px !important;
    vertical-align: middle !important;
`;

const VendorInnerPlaceholder = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const VendorIcon = styled.img`
    height: 28px;
`;

const LastTd = styled.td`
    font-weight: bold;
    padding: 0;
    color: #444444;
    width: 46px;
    min-width: 46px;
    max-width: 46px;
    background-color: ${props => (props.expanded == false ? '#fff3d8' : '#f1f1f1')};
    line-height: 100%;
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #dee2e6;
`;

const LastTh = styled.th`
    font-weight: bold;
    text-align: center;
    padding: 0;
    color: #444444;
    width: 46px;
    min-width: 46px;
    max-width: 46px;
    background-color: #fff3d8;
    line-height: 100%;
    vertical-align: middle;
    padding-bottom: 0.4rem;
    background-color: inherit;
`;


const Thead = styled.thead`
    background-color: #fafafa;
`;

const Th = styled.th`
    background-position: right calc(0.75rem / 2) center;
    padding: 0.75rem;
    padding-right: calc(0.75rem + 0.65em);
    vertical-align: bottom;
    border: 1px solid #dee2e6;
    border-bottom: 2px solid #dee2e6;
`;

const PlusImage = styled.div`
    display: inline-block;
    width: 17px;
    min-width: 17px;
    height: 17px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;
    background-image: url("${greyPlus}");
    :hover{
      background-image: url("${orangePlus}");  
    }
`;

const MinusImage = styled.div`
    width: 17px;
    min-width: 17px;
    height: 17px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
    background-image: url("${greyMinus}");
    display: inline-block;
`;

const ThSpan = styled.span`
    font-style: normal;
    border-bottom: 1px dotted #212121;
`;
