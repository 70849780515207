import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import axios from 'axios'
import DeliveryAPI from "../api/DeliveryAPI";

export default function SelfLoadingPvzSpan(props) {
  const {code} = props;
  const [loading, setLoading] = useState(true);
  const [pvz, setPvz] = useState(undefined);

  useEffect(() => {
    DeliveryAPI.getPvzByCode(code).then(a => {
      setLoading(false);
      setPvz(a);
    })
  }, [code]);

  if (pvz == undefined) {
    return '...';
  }

  return (
    <Wrapper>
      {pvz.address}
    </Wrapper>
  );
}

const Wrapper = styled.span`
    
`;
