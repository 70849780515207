import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import './style.css'

export default function SimpleLoader(props) {
  return (
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
