import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import {BROWN, DARK_SIDE, LIGHT_BROWN, MAIN_ORANGE_COLOR, RED} from "./Colors";

const DEFAULT_BACKGROUND = LIGHT_BROWN;

export const Button = styled.div`
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    cursor: ${props => (props.disabled == true ? 'default' : 'pointer')};
    background: ${props => (props.disabled == true ? 'lightgrey' : MAIN_ORANGE_COLOR)};
    display: inline-block;
    vertical-align: top;
    border-radius: 4px;
    :hover{
      opacity: 0.8;
    }
`;

export const PkButton = styled.div`
    padding: 6px 24px;
    color: #FFFFFF;
    text-transform: uppercase;
    text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.3);
    border: none;
    border-bottom: 1px solid #acacac;
    border-top: 1px solid #fbd1a6;
    cursor: pointer;
    outline: 0;
    border-top-color:${props => (props.disabled == true ? '#FFFFFF' : '#fbd1a6')};
    background-color: ${props => (props.disabled == true ? '#d6d6d6' : '#f7a330')};
    background-image: linear-gradient(0deg, #f7a330 0%, #f58d20 100%);
    box-shadow: 1px 0 1px #dfdfdf;
    font: 400 14px system-ui;
    border-radius: 3px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 24px;
    padding-right: 24px;
`;


export const OutlinedButton = styled.div`
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;
    cursor: ${props => (props.disabled == true ? 'default' : 'pointer')};
    background: transparent;
    color: ${BROWN};
    border: 1px solid ${BROWN};
    border-radius: 4px;
    display: inline-block;
    vertical-align: top;
    :hover{
      opacity: 0.8;
    }
`;



export const DangerButton =  function (props) {
    const onClick = (props.onClick == undefined) ? () => {} : props.onClick;
    return (
        <DangerButtonStyle onClick={() => {
            if (window.confirm('Вы уверены') == false){
                return;
            }
            onClick();
        }} >
            {props.children}
        </DangerButtonStyle>
    );
}

const DangerButtonStyle = styled.div`
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    cursor: ${props => (props.disabled == true ? 'default' : 'pointer')};
    background: ${props => (props.disabled == true ? 'lightgrey' : RED)};
    display: inline-block;
    vertical-align: top;
    border-radius: 4px;
    :hover{
      opacity: 0.8;
    }
`;
