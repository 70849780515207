import {DELIVERY_API_ENDPOINT} from "../api/DeliveryAPI";
import * as EmailValidator from 'email-validator';

import moment from 'moment'

const PK_PAYMENT_URL = `https://devliery-paykeeper.server.paykeeper.ru/create/`;

export const SERVICE_RATE = 0.03;
export const INSURANCE_RATE = 0.0075;

const MIN_PHONE_LENGTH = 9;
const MAX_PHONE_LENGTH = 24;

const DeliveryHelper = {

    getRuMonthString(t) {
        let yyy = moment(t).format('YYYY');
        let m = [
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Май',
            'Июнь',
            'Июль',
            'Август',
            'Сентябрь',
            'Октябрь',
            'Ноябрь',
            'Декабрь'
        ][+moment(t).format('M') - 1];
        return `${m} ${yyy}`
    },

    getRuDayString(t) {
        let yyy = moment(t).format('YYYY');
        let m = [
            'Января',
            'Февраля',
            'Марта',
            'Апреля',
            'Мая',
            'Июня',
            'Июля',
            'Августа',
            'Сентября',
            'Октября',
            'Ноября',
            'Декабря'
        ][+moment(t).format('M') - 1];
        return `${moment(t).format('D')} ${m} ${yyy} г`
    },

    sendPost(path, params, method = 'post') {
        const form = document.createElement('form');
        form.method = method;
        form.action = path;
        form.target = '_blank';
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const hiddenField = document.createElement('input');
                hiddenField.type = 'hidden';
                hiddenField.name = key;
                hiddenField.value = params[key];
                form.appendChild(hiddenField);
            }
        }
        document.body.appendChild(form);
        form.submit();
    },

    submitReplenishmentRequest(sum, clientId = +new Date(), orderId = +new Date()) {
        let params = {
            sum: sum,
            orderid: orderId,
            service_name: 'Пополнение счета доставки',
            clientid: clientId,
            user_result_callback: window.location.href
        };
        this.sendPost(PK_PAYMENT_URL, params);
    },

    getCurrentTimezone() {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },

    getTotalWeight(width, height, length, weight) {
        const hasAnyBadNumber = arr => {
            try {
                let f = arr.reduce((f, a) => ((a == undefined) || (`${a}`.trim() == '') || window.isNaN(a) || f), false);
                return f;
            } catch (exc) {
                return true;
            }
        }
        if (hasAnyBadNumber([weight, height, length, width]) == true) {
            return undefined;
        }
        const vWeight = ((+width) * (+height) * (+length)) / 5000.0;
        let totalWeight = Math.max(+weight, +vWeight);
        return totalWeight;
    },

    createDeliveryFromVueTable(item) {
        try {
            window.localStorage.setItem(`new_delivery_order`, JSON.stringify({t: +new Date(), info: item.info}));
            window.location.href = './delivery';
        } catch (exc) {

        }
    },

    getOrderFormDataBySettings(settings) {
        if (settings == undefined) {
            return undefined;
        }
        let gf = (role, key) => (settings[role] == undefined ? '' : settings[role][key]);
        return {
            from_code: gf('sender', 'code'),
            from_name: gf('sender', 'name'),
            from_street: gf('sender', 'street'),
            from_building: gf('sender', 'building'),
            from_phone: gf('sender', 'phone'),
            from_email: gf('sender', 'email'),
            from_comment: gf('sender', 'comment'),

            to_code: gf('receiver', 'code'),
            to_name: gf('receiver', 'name'),
            to_street: gf('receiver', 'street'),
            to_building: gf('receiver', 'building'),
            to_phone: gf('receiver', 'phone'),
            to_email: gf('receiver', 'email'),
            to_comment: gf('receiver', 'comment')
        }
    },

    getBillUrl(pkId, sum) {
        return `${DELIVERY_API_ENDPOINT}/bill/${pkId}?sum=${window.encodeURIComponent(sum)}`;
    },

    getAllExtraPaymentsObject(codData) {
        let {goods = [], codEnabled = false, deliveryPrice = 0, deliveryPaymentEnabled = false, insuranceEnabled = false} = codData;
        const totalGoodsSum = goods.reduce((sum, a) => (+sum + ((+a.quantity) * (+a.pricePerOne))), 0);
        let serviceSum = 0, insuranceSum = 0;
        if (codEnabled == true) {
            serviceSum = +serviceSum + ((+totalGoodsSum) * SERVICE_RATE);
        }
        // if (deliveryPaymentEnabled == true) {
        //   serviceSum = +serviceSum + ((+deliveryPrice) * SERVICE_RATE);
        // }
        if (insuranceEnabled == true) {
            insuranceSum = (+totalGoodsSum) * INSURANCE_RATE;
        }
        return {
            insuranceSum: insuranceSum,
            serviceSum: serviceSum,
            totalGoodsSum: +totalGoodsSum + (deliveryPaymentEnabled == true ? +deliveryPrice : 0),
            hasService: (+serviceSum > 0 || deliveryPaymentEnabled == true),
            hasInsurance: (+insuranceSum > 0)
        }

    },

    isDev() {
        return (window.location.href.indexOf('localhost') > -1 || window.location.href.indexOf('sab-dev') > -1 || window.location.href.indexOf('sales') > -1);
    },

    getJoinedPhonesString(firstPhone, secondPhone) {
        let ggsF = a => ((a == undefined || a.trim().length < MIN_PHONE_LENGTH) ? undefined : a.trim());
        let s1 = ggsF(firstPhone);
        let s2 = ggsF(secondPhone);
        return [s1, s2].filter(a => (a != undefined)).join(', ')
    },

    checkIfPhoneIsGood(s) { //
        let s2 = (s == undefined) ? '' : s.trim();
        if (s2.length == 0) {
            return true;
        }
        let f = false;
        if (s2.indexOf('8') == 0 || s2.indexOf('+7') == 0 || s2.indexOf('7') == 0 || s2.indexOf('9') == 0) {
            f = true;
        }

        if (s2.length > MAX_PHONE_LENGTH) {
            return f;
        }
        let intsLength = (s2.match(/\d/g) || []).length;
        if (intsLength < MIN_PHONE_LENGTH) {
            return false;
        }
        return f;
    },

    checkIfCanUpdatePhoneInput(s, isFirst = true) {
        let s2 = (s == undefined) ? '' : s.trim();
        if (s2.length > MAX_PHONE_LENGTH) {
            return false;
        }
        let f = true;
        // let f = false;
        // if (s2.indexOf('8') == 0 || s2.indexOf('+7') == 0 || s2.indexOf('7') == 0 || s2.indexOf('9') == 0) {
        //   f = true;
        // }

        let re = /^[0-9\(\) \+\-]*$/i;
        let intsLength = (s2.match(/\d/g) || []).length;
        let canHaveBadNums = (intsLength > MIN_PHONE_LENGTH);
        let hasAnyBad = aa => !re.test(aa);
        if (canHaveBadNums == false && hasAnyBad(s2)) {
            return f;
        }
        if (isFirst == false && hasAnyBad(s2)) {
            return f;
        }
        if (hasAnyBad(s2) && s2.length < MIN_PHONE_LENGTH) {
            return f;
        }
        return f;
    },

    ltrim(str) {
        if (!str) return str;
        str = str.replace(/ +(?= )/g, '');
        return str.replace(/^\s+/g, '').slice(0, MAX_PHONE_LENGTH);
    },

    isGoodDimParam(x) {
        let s = `${x}`.trim();
        if (window.isNaN(x) == true) {
            return false;
        }
        return (+x > 0 && +x <= 1500);
    },

    isGoodWeightParam(x) {
        let s = `${x}`.trim();
        if (window.isNaN(x) == true) {
            return false;
        }
        return (+x > 0 && +x <= 30);
    },

    enoughPhoneNumbersAtTheBeginning(s) {
        if (s == undefined) {
            return false;
        }
        let s2 = this.ltrim(s.trim());
        let a = s2.slice(0, Math.round(MIN_PHONE_LENGTH * 1.5));
        let intsLength = (s2.match(/\d/g) || []).length;
        return (intsLength >= MIN_PHONE_LENGTH);
    },

    getAnyInputErrorMessage(formData, vendor = 'cdek') {
        let isCdek = (vendor.toLowerCase() == 'cdek');
        let isYandex = (vendor.toLowerCase() == 'yandex');

        console.log('getAnyInputErrorMessage occured! formData = ', formData);
        let {
            from_code, to_code, order_type, from_email, to_email, width, height, length, weight,
            to_street, to_building, from_pvz_code, to_pvz_code,
            from_phone, to_phone, from_building, from_street, from_name, to_name,
            skip_door_to_door, from_sflat, to_sflat, from_porch, to_porch, from_door_code, to_door_code
        } = formData;
        let ie = a => (a == undefined || `${a}`.trim() == '');
        // let isGoodPhone = a => (!ie(a) && a.split(',').map(x => x.trim()).reduce((f, x) => (f && this.checkIfPhoneIsGood(x)), true));
        let isGoodPhone = a => (!ie(a) && this.checkIfPhoneIsGood(a.split(',')[0]));

        if (ie(from_code)) {
            return 'Укажите, пожалуйста, населенный пункт отправителя'
        }
        if (ie(to_code)) {
            return 'Укажите, пожалуйста, населенный пункт получателя'
        }

        if (ie(from_name)) {
            return 'Укажите, пожалуйста, имя отправителя'
        }

        if (ie(to_name)) {
            return 'Укажите, пожалуйста, имя получателя'
        }

        if (order_type != undefined && order_type.indexOf('door-') > -1) {
            if (ie(from_street)) {
                return 'Укажите, пожалуйста, улицу отправителя'
            }
            if (ie(from_building)) {
                return 'Укажите, пожалуйста, номер дома отправителя'
            }
        }

        if (order_type != undefined && order_type.indexOf('-door') > -1) {
            if (ie(to_street)) {
                return 'Укажите, пожалуйста, улицу получателя'
            }
            if (ie(to_building)) {
                return 'Укажите, пожалуйста, номер дома получателя'
            }
        }

        if (ie(from_email) || EmailValidator.validate(from_email) == false) {
            return 'Укажите, пожалуйста, E-mail отправителя в формате ****@*****.**'
        }
        if (ie(to_email) || EmailValidator.validate(to_email) == false) {
            return 'Укажите, пожалуйста, E-mail получателя в формате ****@*****.**'
        }
        if (!isGoodPhone(from_phone)) {
            return 'Укажите, пожалуйста, телефон отправителя в следующем формате: +7**********'
        }
        if (!isGoodPhone(to_phone)) {
            return 'Укажите, пожалуйста, телефон получателя в следующем формате: +7**********'
        }

        if (order_type != undefined && order_type.indexOf('-warehouse') > -1) {
            if (ie(to_pvz_code)) {
                return 'Укажите, пожалуйста, ПВЗ получателя'
            }
        }

        if (order_type != undefined && order_type.indexOf('warehouse-') > -1) {
            if (ie(from_pvz_code)) {
                return 'Укажите, пожалуйста, ПВЗ отправителя'
            }
        }

        if (!this.isGoodDimParam(width)) {
            return 'Укажите, пожалуйста, ширину груза в диапазоне от 1 до 1500 см'
        }

        if (!this.isGoodDimParam(length)) {
            return 'Укажите, пожалуйста, длину груза в диапазоне от 1 до 1500 см'
        }

        if (!this.isGoodDimParam(height)) {
            return 'Укажите, пожалуйста, высоты груза в диапазоне от 1 до 1500 см'
        }

        if (!this.isGoodWeightParam(weight)) {
            return 'Укажите, пожалуйста, вес груза в диапазоне от 0.1 до 30 кг'
        }
        let vWeight = width * height * length / 5000.0;
        if (isCdek) {
            if (vWeight > 30.0) {
                return 'Слишком габаритный груз.'
            }
        }
        if (isYandex) {
            if (skip_door_to_door == false) {
                if (ie(from_sflat)) {
                    return 'Укажите, пожалуйста, номер квартиры отправителя'
                }
                if (ie(to_sflat)) {
                    return 'Укажите, пожалуйста, номер квартиры получателя'
                }
                if (ie(from_porch)) {
                    return 'Укажите, пожалуйста, номер подъезда отправителя'
                }
                if (ie(to_porch)) {
                    return 'Укажите, пожалуйста, номер подъезда получателя'
                }
                if (ie(from_door_code)) {
                    return 'Укажите, пожалуйста, код домофона отправителя, либо напишите "Без кода", если домофона нет'
                }
                if (ie(to_door_code)) {
                    return 'Укажите, пожалуйста, код домофона получателя, либо напишите "Без кода", если домофона нет'
                }
            }
        }

    },

    isBigVolume(formData = {}) {
        let {
            height, length, width
        } = formData;
        if (window.isNaN(height) == false && window.isNaN(width) == false && window.isNaN(length) == false) {
            let vWeight = width * height * length / 5000.0;
            return (vWeight > 30.0);
        }
        return false;
    }

}

export default DeliveryHelper
