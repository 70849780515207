import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import {BROWN, DARK_SIDE, LIGHT_BROWN, MAIN_ORANGE_COLOR, RED} from "./Colors";

import THEME from '../theme'

const {
  PRIMARY_BUTTON_ICON, PRIMARY_BUTTON_COLOR, PRIMARY_BUTTON_FONT_SIZE,
  PRIMARY_BUTTON_FONT_WEIGHT, PRIMARY_BUTTON_BACKGROUND, PRIMARY_BUTTON_BORDER_RADIUS,
  PRIMARY_BUTTON_BOX_SHADOW, REPLENISHMENT_BUTTON_ICON, SECONDARY_BUTTON_BACKGROUND,
  PRIMARY_BUTTON_TEXT_MARGIN_TOP, REPLENISHMENT_BUTTON_TEXT_MARGIN_LEFT, REPLENISHMENT_BUTTON_PADDING_RIGHT
} = THEME;

const DEFAULT_BACKGROUND = LIGHT_BROWN;

export const Button = styled.div`
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    cursor: ${props => (props.disabled == true ? 'default' : 'pointer')};
    background: ${props => (props.disabled == true ? 'lightgrey' : MAIN_ORANGE_COLOR)};
    display: inline-block;
    vertical-align: top;
    border-radius: 4px;
    :hover{
      opacity: 0.8;
    }
`;

export const PkButton = styled.div`
    padding: 6px 24px;
    color: #FFFFFF;
    text-transform: uppercase;
    text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.3);
    border: none;
    border-bottom: 1px solid #acacac;
    border-top: 1px solid #fbd1a6;
    cursor: pointer;
    outline: 0;
    border-top-color:${props => (props.disabled == true ? '#FFFFFF' : SECONDARY_BUTTON_BACKGROUND)};
    background-color: ${props => (props.disabled == true ? '#d6d6d6' : SECONDARY_BUTTON_BACKGROUND)};
    
   
    box-shadow: 1px 0 1px #dfdfdf;
    font: 400 14px system-ui;
    border-radius: 3px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 24px;
    padding-right: 24px;
`;


export const OutlinedButton = styled.div`
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;
    cursor: ${props => (props.disabled == true ? 'default' : 'pointer')};
    background: transparent;
    color: ${BROWN};
    border: 1px solid ${BROWN};
    border-radius: 4px;
    display: inline-block;
    vertical-align: top;
    :hover{
      opacity: 0.8;
    }
`;


export const DangerButton = function (props) {
  const onClick = (props.onClick == undefined) ? () => {
  } : props.onClick;
  return (
    <DangerButtonStyle onClick={() => {
      if (window.confirm('Вы уверены') == false) {
        return;
      }
      onClick();
    }}>
      {props.children}
    </DangerButtonStyle>
  );
}

const DangerButtonStyle = styled.div`
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    cursor: ${props => (props.disabled == true ? 'default' : 'pointer')};
    background: ${props => (props.disabled == true ? 'lightgrey' : RED)};
    display: inline-block;
    vertical-align: top;
    border-radius: 4px;
    :hover{
      opacity: 0.8;
    }
`;

const ButtonWithImage = styled.div`
    height: ${props => (props.height == undefined ? 27 : props.height)}px !important;
    box-sizing: border-box !important;;
    text-transform: uppercase;
    color: ${props => props.color} !important;
    cursor: pointer;
    
    line-height: ${props => (props.height == undefined ? 27 : props.height)}px !important;
    font-size: ${props => (props.fontSize == undefined ? 12 : props.fontSize)}px !important;
    font-weight: ${props => (props.fontWeight == undefined ? 'normal' : props.fontWeight)} !important;;
    line-height: ${props => (props.fontSize == undefined ? 12 : props.fontSize)}px !important;
    
    border-radius: ${props => (props.borderRadius == undefined ? 3 : props.borderRadius)}px !important;
    background: ${props => (props.background == undefined ? 'linear-gradient(90deg,rgba(0,0,0,.05882),rgba(0,0,0,.03137))' : props.background)};
    box-shadow: ${props => (props.boxShadow == undefined ? '0 1px 3px 0 #000' : props.boxShadow)};
    margin-bottom: 1px;
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    
    padding-left: 9px;
    padding-right: ${props => (props.paddingRight == undefined ? 9 : props.paddingRight)}px;
`;

const ButtonImg = styled.img`
    //width: 16px;
    //height: 16px;
    margin-right: 6px;
`;

export const PrimaryPlusButton = function (props) {
  let {
    fontSize, fontWeight, imgSrc, background,
    boxShadow,
    borderRadius, name, color, onClick = () => {

    }
  } = props;
  let src = (imgSrc == undefined) ? PRIMARY_BUTTON_ICON : imgSrc;
  let col = (color == undefined) ? PRIMARY_BUTTON_COLOR : color;
  let fSize = (fontSize == undefined) ? PRIMARY_BUTTON_FONT_SIZE : fontSize;
  let fWeight = (fontWeight == undefined) ? PRIMARY_BUTTON_FONT_WEIGHT : fontWeight;
  let bg = (background == undefined) ? PRIMARY_BUTTON_BACKGROUND : background;
  let br = (borderRadius == undefined) ? PRIMARY_BUTTON_BORDER_RADIUS : borderRadius;
  let bShadow = (boxShadow == undefined) ? PRIMARY_BUTTON_BOX_SHADOW : boxShadow;
  return (
    <ButtonWithImage {...props} color={col}
                     fontSize={fSize}
                     fontWeight={fWeight}
                     background={bg}
                     borderRadius={br}
                     boxShadow={bShadow}
                     onClick={() => {
                       onClick();
                     }}>
      <ButtonImg src={src}/>
      <span style={{marginTop: PRIMARY_BUTTON_TEXT_MARGIN_TOP}}>
        {props.children}
      </span>
    </ButtonWithImage>
  )
}

export const ReplenishmentButton = function (props) {
  let {
    fontSize, fontWeight, imgSrc, background,
    boxShadow,
    borderRadius, name, color, onClick = () => {

    }
  } = props;
  let src = (imgSrc == undefined) ? REPLENISHMENT_BUTTON_ICON : imgSrc;
  let col = (color == undefined) ? PRIMARY_BUTTON_COLOR : color;
  let fSize = (fontSize == undefined) ? PRIMARY_BUTTON_FONT_SIZE : fontSize;
  let fWeight = (fontWeight == undefined) ? PRIMARY_BUTTON_FONT_WEIGHT : fontWeight;
  let bg = (background == undefined) ? PRIMARY_BUTTON_BACKGROUND : background;
  let br = (borderRadius == undefined) ? PRIMARY_BUTTON_BORDER_RADIUS : borderRadius;
  let bShadow = (boxShadow == undefined) ? PRIMARY_BUTTON_BOX_SHADOW : boxShadow;
  return (
    <ButtonWithImage {...props} color={col}
                     fontSize={fSize}
                     fontWeight={fWeight}
                     background={bg}
                     borderRadius={br}
                     boxShadow={bShadow}
                     paddingRight={REPLENISHMENT_BUTTON_PADDING_RIGHT}
                     onClick={() => {
                       onClick();
                     }}>
      <ButtonImg src={src}/>
      <span style={{marginLeft: REPLENISHMENT_BUTTON_TEXT_MARGIN_LEFT}}>
        {props.children}
      </span>
    </ButtonWithImage>
  )
}
