import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import axios from 'axios'

import PkModal from '../../modals/PkModal'

import {PkInput} from "../../ui/Input";
import {PkButton} from "../../ui/Button";
import DeliveryAPI from "../../api/DeliveryAPI";


export default function ContactCreationSpan(props) {
  const {
    login, password,
    data = {}
  } = props;
  const [visible, setVisible] = useState(false);
  const [successVisible, setSuccessVisible] = useState(false);
  const [name, setName] = useState(props.data == undefined || props.data.name == undefined ? '' : props.data.name);

  return (
    <Wrapper>

      <Content onClick={() => {
        setVisible(true);
      }}>
        {props.children}
      </Content>

      {visible == false ? null :
        <PkModal name={'ДОБАВЛЕНИЕ КОНТАКТА'} width={620} zIndex={1500} onClose={() => {
          setVisible(false);
        }}>

          <Inner>
            <FormPlaceholder>
              <TopPlaceholder>
                <SearchPlaceholder>
                  <PkInput placeholder={'Название контакта'}
                           autoFocus={true}
                           value={name} onChange={evt => {
                    setName(evt.target.value);
                  }}/>
                </SearchPlaceholder>
                <ControlsPlaceholder>
                  <PkButton style={{fontSize: 12, textAlign: 'center'}} onClick={async () => {
                    let d = {...data, name: name};
                    delete d.id;
                    await DeliveryAPI.createOrUpdateContact(d, login, password);
                    setVisible(false);
                    setSuccessVisible(true);
                  }}>
                    Сохранить контакт
                  </PkButton>
                </ControlsPlaceholder>
              </TopPlaceholder>

            </FormPlaceholder>
          </Inner>

        </PkModal>
      }

      {successVisible == false ? null :
        <PkModal hasHeaderBorder={false} name={'АДРЕС УСПЕШНО ДОБАВЛЕН'} width={620} zIndex={1500} onClose={() => {
          setSuccessVisible(false);
        }}>

        </PkModal>
      }

    </Wrapper>
  );
}

const Wrapper = styled.div`
    display: inline-block;
`;

const Content = styled.div`
    
`;

const Inner = styled.div`
    box-sizing: border-box;
`;

const FromPlaceholder = styled.div`
    
`;

const SearchPlaceholder = styled.div`
    flex: 1;
`;

const TopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
`;

const ControlsPlaceholder = styled.div`
    margin-left: 10px;
    width: 185px;
`;

const FormPlaceholder = styled.div`
    
`;
