import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import axios from 'axios'
import PvzSelectorTool from "./PvzSelectorTool";
import {MAIN_ORANGE_COLOR, MAIN_RED_COLOR} from "../ui/Colors";

import {YMaps, Map, Placemark, ObjectManager, Clusterer} from 'react-yandex-maps';

export default function AllPvzMapPanel(props) {
    const [items, setItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(undefined);

    const [mapVisible, setMapVisible] = useState(false);

    useEffect(() => {
        axios.get(`https://delivery.paykeeper.ru/api2/pvz/all`).then(d => d.data).then(arr => {
            setItems(arr.filter(a => (a.fullAddress != undefined && a.fullAddress.indexOf('Россия') > -1)));
        })
    }, []);

    const map = useRef(null);
    useEffect(() => {
        console.log('map ref = ', map.current);
    }, []);

    // if (items.length == 0){
    //     return null;
    // }

    return (
        <Wrapper>

            <Content>

                {(mapVisible == false || items.length == 0) ? null :
                    <MapPlaceholder>
                        <YMaps>
                            <Map instanceRef={map}
                                 height="100%"
                                 width="100%"
                                 defaultState={{center: [64.5, 93.5], zoom: 3}}>

                                <Clusterer
                                    options={{}}
                                >

                                    {/*<ObjectManager*/}
                                    {/*options={{*/}
                                    {/*clusterize: true*/}
                                    {/*}}*/}
                                    {/*clusters={{*/}
                                    {/*preset: 'islands#redClusterIcons',*/}
                                    {/*}}*/}
                                    {/*/>*/}

                                    {items.map((it, k) => {
                                        let isSelected = (selectedItem != undefined && it.code == selectedItem.code);
                                        return (
                                            <Placemark
                                                key={it.code}
                                                geometry={[it.coordY, it.coordX]}
                                                options={{
                                                    preset: `islands#${isSelected == true ? 'icon' : 'icon'}`,
                                                    // Setting the placemark color (in RGB format).
                                                    iconColor: (isSelected == true ? MAIN_RED_COLOR : MAIN_ORANGE_COLOR)
                                                }}
                                                properties={
                                                    {
                                                        balloonContent: (isSelected == false) ? null :
                                                            `<div>
                                       selected!
                                     </div>`
                                                    }
                                                }
                                                onClick={() => {
                                                    setSelectedItem(it);
                                                }}/>
                                        )
                                    })}
                                </Clusterer>
                            </Map>
                        </YMaps>
                    </MapPlaceholder>
                }

            </Content>

            {selectedItem == undefined ? null :
                <BottomPlaceholder>
                    {selectedItem.fullAddress}
                </BottomPlaceholder>
            }

            {mapVisible == true ? null :
                <MapOverlay>
                    <OverHeading onClick={() => {
                        setMapVisible(true);
                    }}>
                        {`Показать ${items.length} ПВЗ`}
                    </OverHeading>
                </MapOverlay>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: 600px;
`;

const MapPlaceholder = styled.div`
    width: 100%;
    height: 100%;
    background-color: white;
`;


const Content = styled.div`
    height: calc(100% - 50px);
    width: 100%;
`;

const BottomPlaceholder = styled.div`
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const MapOverlay = styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const OverHeading = styled.div`
    cursor: pointer;
    text-decoration: underline;
    font-size: 24px;
`;
