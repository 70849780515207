import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import axios from 'axios'

const GOOD_PK_ID_REGEXP = /\d{6}-\d{3}-\d+/;

const voidCheck = require('../../../assets/images/void_check.svg');
const filledCheck = require('../../../assets/images/pk_checked.svg');

const agreementUrl = './paykeeper_oferta.pdf';

function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const renderTick = (selected, isFirst, onClick = () => {
}) => {
    return (
        <>
            <CheckImg src={(selected == true) ? filledCheck : voidCheck} onClick={() => {
                onClick();
            }}/>
            <CheckSpan onClick={() => {
                onClick();
            }}>
                {isFirst == true ?
                    <span>
                    Я принимаю условия <Link href={agreementUrl} target={'_blank'}>договора оферты</Link>
                </span>
                    :
                    <span>
                    Я хочу получить 1000 рублей на лицевой счёт
                </span>
                }
            </CheckSpan>
        </>
    )
}

export default function NewLeadPanel(props) {

    const [loading, setLoading] = useState(false);

    const [pkId, setPkId] = useState(props.pkId == undefined ? '' : props.pkId);
    const [email, setEmail] = useState(props.email == undefined ? '' : props.email);

    const [firstAgree, setFirstAgree] = useState(true);
    const [secondAgree, setSecondAgree] = useState(true);

    const [sent, setSent] = useState(false);
    const [error, setError] = useState(undefined);

    let isGoodPkId = GOOD_PK_ID_REGEXP.test(pkId);
    console.log('render: isGoodPkId = ', isGoodPkId);

    let emailRed = ((validateEmail(email) == false) && (error != undefined));
    let pkIdRed = ((isGoodPkId == false) && (error != undefined));

    return (
        <Wrapper>

            {/*<TopPlaceholder>*/}
            {/*    <Link target={'_blank'} href={agreementUrl}>*/}
            {/*        ОФЕРТА на оказание услуг доставки*/}
            {/*    </Link>*/}
            {/*</TopPlaceholder>*/}

            <MiddleContent>
                <Half>
                    <Label>
                        Номер договора
                    </Label>
                    <Input red={pkIdRed} value={pkId} onChange={evt => {
                        setPkId(evt.target.value.trim());
                        setError(undefined);
                    }}/>
                </Half>
                <Half>
                    <Label>
                        Электронная почта
                    </Label>
                    <Input red={emailRed} value={email} onChange={evt => {
                        setEmail(evt.target.value.trim().replace(/ ]/g, ''));
                        setError(undefined);
                    }}/>
                </Half>
            </MiddleContent>

            <BottomPlaceholder>

                <div style={{display: 'flex', flexDirection: 'row'}} >
                    <CheckRow  >
                        {renderTick(firstAgree, true, () => {
                            setFirstAgree(!firstAgree);
                        })}
                    </CheckRow>
                    <CheckRow>
                        {renderTick(secondAgree, false, () => {
                            setSecondAgree(!secondAgree);
                        })}
                    </CheckRow>
                </div>

                {error == undefined ? null :
                    <ErrorPlaceholder>
                        {error}
                    </ErrorPlaceholder>
                }


                <ButtonPlaceholder>
                    {sent == false ? null :
                        <div>
                            Спасибо! В ближайшее время мы с Вами свяжемся
                        </div>
                    }
                    {sent == true ? null :
                        <ButtonPlaceholder>
                            <SubmitButton loading={loading} onClick={async () => {
                                console.log('pkId, email, isGoodPkId');
                                if (loading == true) {
                                    return;
                                }

                                if (pkId == undefined || isGoodPkId == false) {
                                    setError('Неверно введен номер договора');
                                    return;
                                }

                                if (email == undefined || validateEmail(email) == false) {
                                    setError('Неверно введен email');
                                    return;
                                }

                                if (pkId == undefined || email == undefined || isGoodPkId == false || (validateEmail(email) == false)) {
                                    return;
                                }
                                setLoading(true);
                                axios.post(`https://delivery.paykeeper.ru/kvdb/slack/message/new_lead`, {
                                    text: `Заявка с сайта delivery.paykeeper.ru на подключение. Номер догвора: ${pkId}, email: ${email}`,
                                    pkId: pkId
                                }, {
                                    params: {
                                        token: 'sabirmipt'
                                    }
                                });
                                setSent(true);
                                setLoading(false);
                            }}>
                                Подключить доставку
                            </SubmitButton>
                        </ButtonPlaceholder>
                    }

                </ButtonPlaceholder>

            </BottomPlaceholder>

        </Wrapper>
    );
}

const ErrorPlaceholder = styled.div`
  color: red;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ButtonPlaceholder = styled.div`
  margin-top: 20px;
`;

const CheckSpan = styled.div`
  display: flex;
  align-items: center;
  color: #000000;
  cursor: pointer;
  font-size: 16px;
  line-height: 145%;

  :hover {
    opacity: 0.8;
  }
`;

const Wrapper = styled.div`
  margin-top: 20px;
  box-sizing: border-box;
  padding: 50px;
  padding-top: 30px;
  background: #FFFFFF;
  box-shadow: 0px 5.54444px 27.7222px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
`;

const TopPlaceholder = styled.div`
  text-decoration: underline;
  text-transform: uppercase;
  color: #B01C2E;
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 26px;
`;

const Link = styled.a`
  color: #CC1626;
`;

const Label = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: justify;
  color: #292828;
  opacity: 0.5;
`;

const CheckImg = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;
`;

const CheckRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  :first-of-type{
    margin-right: 10px;
  }
  :last-of-type{
    margin-left: 10px;
  }
`;

const Input = styled.input`
  border: 1px solid ${props => (props.red == true ? 'red' : '#F1F1F1')};
  box-sizing: border-box;
  width: 100%;
  color: #000000;
  font-size: 18px;
  line-height: 72px;
  height: 72px;
  padding-left: 6px;
  
  background: #FAFAFA;
  border-radius: 5px;
`;

const MiddleContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const BottomPlaceholder = styled.div`
  margin-top: 23px;
`;

const Half = styled.div`
  flex: 1;
  box-sizing: border-box;

  :first-of-type {
    padding-right: 25px;
  }

  :last-of-type {
    padding-left: 25px;
  }
`;


const SubmitButton = styled.div`
  width: 350px;
  height: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #CC1626 !important;
  border-radius: 5px;

  font-weight: bold;
  font-size: 16px;
  line-height: 15px;

  padding: 12px 35px;

  color: #ffffff !important;
  float: none;
  border: 0;
  opacity: ${props => (props.loading == 'true' ? 0.5 : 1)};
  cursor: ${props => (props.loading == 'true' ? 'default' : 'pointer')};
`;
