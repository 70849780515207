import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import PkTemplate from "../templates/PkTemplate";
import CalculatorPanel from "../delivery/calculator/CalculatorPanel";
import axios from 'axios'
import {PkButton} from "../ui/Button";
import {PkInput} from "../ui/Input";

async function getIp(address) {
    let s = address.replace('https://', '').replace('http://', '').replace(/\//g, '');
    let pld = (await axios.get(`https://www.englishpatient.org/api/ip/${s}/info`)).data;
    return pld;
}

export default function CheckIPApp(props) {
    const [text, setText] = useState('');
    const [result, setResult] = useState(undefined);
    const [loading, setLoading] = useState(false);

    return (
        <PkTemplate active={'ip'}>
            <Wrapper>

                <Field>
                    <PkInput placeholder={'адрес сайта'} onChange={evt => {
                        setText(evt.target.value);
                        setResult(undefined);
                    }}/>
                </Field>

                <Field>
                    {loading == true ? 'загрузка...' :
                        <PkButton onClick={async () => {
                            setLoading(true);
                            setResult(undefined);
                            setResult(await getIp(text));
                            setLoading(false);
                        }}>
                            Получить IP
                        </PkButton>
                    }

                </Field>

                {result == undefined ? null :
                    <Field>
                        {`IP: ${result.ip}`}
                    </Field>
                }


            </Wrapper>
        </PkTemplate>
    );
}

const Wrapper = styled.div`
    padding: 15px;
`;

const P = styled.div`
    line-height: 32px;
    margin-bottom: 10px;
`;

const Field = styled.div`
    margin-bottom: 10px;
`;
