import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import axios from 'axios'
import NumberEditor from "./NumberEditor";

import {PkInput} from "../../ui/Input";

export default function UpdateGoodItemTool(props) {
  const {
    data = {quantity: 1, vendorCode: '', name: ''},
    onChange = newData => {

    },
    onDelete = () => {

    },
    onCopy = () => {

    },
    onAdd = () => {

    },
    canAdd = false,
    canClone = true,
    canDelete = true
  } = props;

  return (
    <Wrapper>
      <Row>
        <Col>
          <Label>
            Наименование товара
          </Label>
          <ValuePlaceholder>
            <PkInput value={data.name} onChange={evt => {
              let d = {...data, name: evt.target.value};
              onChange(d);
            }}/>
          </ValuePlaceholder>
        </Col>
        <NumEditorPlaceholder>
          <Label style={{textAlign: 'center', alignItems: 'center'}}>
            Кол-во, ед
          </Label>
          <NumberEditor value={data.quantity} onChange={v => {
            let d = {...data, quantity: +v};
            onChange(d);
          }}/>
        </NumEditorPlaceholder>
      </Row>

      <Row>
        <Col>
          <Label>
            Код товара/артикул
          </Label>
          <ValuePlaceholder>
            <PkInput value={data.vendorCode} onChange={evt => {
              let d = {...data, vendorCode: evt.target.value};
              console.log('onChnage: d = ', d);
              onChange(d);
            }}/>
          </ValuePlaceholder>
        </Col>
        <Col>
          <Label>
            Стоимость ед товара, руб (для страховки)
          </Label>
          <ValuePlaceholder>
            <PkInput value={data.insurancePrice} onChange={evt => {
              let d = {...data, insurancePrice: evt.target.value}
              onChange(d);
            }}/>
          </ValuePlaceholder>
        </Col>
        <Col>
          <Label>
            Оплата с получателя за ед товара в т.ч. НДС, руб
          </Label>
          <ValuePlaceholder>
            <PkInput value={data.customerPrice} onChange={evt => {
              let d = {...data, customerPrice: evt.target.value}
              onChange(d);
            }}/>
          </ValuePlaceholder>
        </Col>

        <LastControlsCol>
          <Label style={{opacity: 0}}>
            {' _ '}
          </Label>
          <ControlsPlaceholder>
            {canDelete == false ? null :
              <DeleteSpan onClick={() => {
                onDelete();
              }}>
                Удалить
              </DeleteSpan>
            }
            {canClone == false ? null :
              <CopyButton onClick={() => {
                onCopy();
              }}>
                Копировать
              </CopyButton>
            }
            {canAdd == false ? null :
              <CopyButton onClick={() => {
                onAdd();
              }}>
                Добавить
              </CopyButton>
            }
          </ControlsPlaceholder>
        </LastControlsCol>
      </Row>

    </Wrapper>
  );
}

const Wrapper = styled.div`
        
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
`;

const Col = styled.div`
    flex: 1;
`;

const NumEditorPlaceholder = styled.div`
    width: 200px;
`;

const Label = styled.div`
    font-size: 14px;
    line-height: 18px;
    height: 36px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
`;

const ValuePlaceholder = styled.div`
    box-sizing: border-box;
    padding-right: 10px;
`;


const ControlsPlaceholder = styled.div`
    width: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const DeleteSpan = styled.div`
    color: red;
    opacity: 0.9;
    margin-right: 10px;
    cursor: pointer;
    border-bottom: 1px dashed red;
    :hover{
      opacity: 1;
    }
`;

const CopyButton = styled.div`
    cursor: pointer;
    opacity: 0.9;
    margin-right: 10px;
    border-bottom: 1px dashed lightgrey;
    :hover{
      opacity: 1;
    }
`;

const LastControlsCol = styled.div`
    width: 200px;
`;
