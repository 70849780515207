import styled from 'styled-components';
import {BROWN, LIGHT_BROWN, MAIN_ORANGE_COLOR} from "./Colors";

const DEFAULT_INPUT_HEIGHT = 28;
const DEFAULT_INPUT_FONT_SIZE = 14;

export const Textarea = styled.textarea`
    width: 100%;
    box-sizing: border-box;
    min-height: 6em;
    line-height: ${DEFAULT_INPUT_HEIGHT}px;
    border: 1px solid whitesmoke;
    border-radius: 4px;
    outline: none;
    font-size: ${DEFAULT_INPUT_FONT_SIZE}px;
    :focus{
      border-color: ${MAIN_ORANGE_COLOR};
    }
`;

export const PkTextarea = styled.textarea`
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    box-sizing: border-box;
    :focus{
       color: #495057;
       background-color: #fff;
       border-color: #80bdff;
       outline: 0;
       box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
`;
