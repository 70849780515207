import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import NotificationsAPI from '../../api/NotificationsAPI'

export default function GlobalDeliveryNotificationPanel(props) {
  const [detailsData, setDetailsData] = useState();

  useEffect(() => {
    NotificationsAPI.getGlobalStatusData().then(d => {
      console.log('getGlobalStatusData: d = ', d);
      setDetailsData(d);
    })
  }, []);

  if (detailsData == undefined) {
    return null;
  }

  let {visible, message} = detailsData;
  if (visible == false) {
    return null;
  }

  return (
    <Wrapper>
      <Inner>
        <div dangerouslySetInnerHTML={{__html: message}}></div>
      </Inner>
    </Wrapper>
  );
}

const Wrapper = styled.div`
    margin-bottom: 20px;
`;

const Inner = styled.div`
    padding: 15px 20px;
    background: #fae042;
    box-sizing: border-box;
    border-radius: 3px;
    border-bottom: 1px solid #acacac;
    border-top: 1px solid #fbd1a6;
`;
