import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import NewLeadPanel from "../../leads/panels/NewLeadPanel";

import pkLogo from '../../../assets/images/pk_logo_2.svg'

export default function FooterSection(props) {

    return (
        <Wrapper>

            <InnerPlaceholder>
                <TopPlaceholder>

                    <LeftPart>
                        <PkLogoImgBottom src={pkLogo}/>
                    </LeftPart>

                    <RightPart>
                        <div style={{marginRight: 20}} >
                            <div style={{color: 'white', fontSize: 18, lineHeight: '18px', fontWeight: 'bold'}}>
                                8 (800) 775-37-51
                            </div>
                            <div style={{textAlign: 'right', color: 'white', opacity: 0.5, fontSize: 14}}>
                                Контакт центр
                            </div>
                        </div>

                        <DemoButton onClick={() => {
                            window.location.href = `/#/demo`;
                        }}>
                            Демо версия
                        </DemoButton>

                    </RightPart>

                </TopPlaceholder>

                <BottomPlaceholder>
                    <LeftPart>
                        © PayKeeper Ltd. Все права защищены.
                    </LeftPart>

                    <RightPart>
                        ® ООО «ПЭЙКИПЕР-ПРОЦЕССИНГ» 2010-2021
                    </RightPart>
                </BottomPlaceholder>

            </InnerPlaceholder>


        </Wrapper>
    );
}

const DemoButton = styled.div`
  background: #CC1626;
  border-radius: 5px;
  height: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  padding-left: 40px;
  padding-right: 40px;
  color: white;
  font-weight: bold;

  :hover {
    opacity: 0.75;
  }
`;

const BottomPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #FFFFFF;
  opacity: 0.3;
`;

const breakWidth = 1200;

const Wrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  width: 100vw;
  margin-top: 80px;
  padding-bottom: 40px;
  background: #292828;
`;

const LeftPart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PkLogoImgBottom = styled.img`

`;

const RightPart = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: right;
`;


const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  justify-content: space-between;
  padding-bottom: 20px;
  margin-bottom: 20px;
`;

const InnerPlaceholder = styled.div`
  width: ${breakWidth}px;
  margin: 0 auto;
  box-sizing: border-box;
  @media (max-width: ${breakWidth}px) {
    width: 100%;
  }
`;