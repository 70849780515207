import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {Map, Set} from 'immutable'
import styled from 'styled-components';

import {Async} from 'react-select';
import './style.css'
import DeliveryAPI from "../api/DeliveryAPI";

const MAX_RESULTS_VALUE = 50;

const DEFAULT_OPTIONS = [
  // {
  //   label: 'Москва',
  //   value: '44'
  // },
  // {
  //   label: 'Санкт-Петербург',
  //   value: '137'
  // },
]


const loadOptions = (inputValue, callback) => {
  if (inputValue == undefined || inputValue.trim() == '') {
    return callback([]);
  }
  DeliveryAPI.getDropdownCityOptions(inputValue).then(options => {
    callback(options);
  });
};

const getCityFriendlyName = (obj) => {
  if (obj == undefined) {
    return '';
  }
  let {cityName, region, subRegion} = obj;
  let opts = [cityName, subRegion, region].filter(a => (a != undefined && a.trim() != ''));
  return opts.join(`, `);
}

export default function CitySelect(props) {
  const {
    code, onChange = newCode => {

    }
  } = props;
  const [cacheMap, setCacheMap] = useState(Map());
  const [loading, setLoading] = useState(true);
  const [defaultOptions, setDefaultOptions] = useState(DEFAULT_OPTIONS);
  const [value, setValue] = useState(props.code == undefined ? undefined : props.code);

  useEffect(() => {
    if (code == undefined) {
      setLoading(false);
      return;
    }
    DeliveryAPI.getCityByCode(code).then(city => {
      if (city != undefined) {
        setCacheMap(cacheMap.set(`${city.code}`, city));
        setDefaultOptions([...DEFAULT_OPTIONS, {label: city.fullName, value: city.code}]);
        setValue(city.code);
      }
      setLoading(false);
    });
  }, [code]);

  if (loading == true) {
    return <span>загрузка...</span>
  }

  let selOption = (value == undefined || cacheMap.get(`${value}`) == undefined) ? undefined : {
    value: value,
    label: cacheMap.get(`${value}`).fullName
  };

  // console.log('selOption: ', selOption);

  return (
    <Wrapper>

      <Async
        className={'react-select-container'}
        classNamePrefix={'react-select'}
        cacheOptions
        loadOptions={loadOptions}
        defaultOptions={defaultOptions}
        isClearable={true}
        placeholder={'Название населённого пункта'}
        value={selOption}
        onInputChange={a => {

        }}
        noOptionsMessage={() => (`Ничего не найдено`)}
        onChange={a => {
          if (a == undefined) {
            setValue(undefined);
          } else {
            setValue(a.value);
            onChange(a.value);
          }

        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
    
`;
