import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import scr1Img from '../../../assets/images/scr_01.jpg';
import scr2Img from '../../../assets/images/scr_02.jpg';
import scr3Img from '../../../assets/images/scr_03.jpg';

import icon1Img from '../../../assets/images/user.svg';
import icon2Img from '../../../assets/images/search.svg';
import icon3Img from '../../../assets/images/briefcase.svg';

const ITEMS = [
    {
        name: 'Управление из кабинета PayKeeper',
        description: 'Создавать заказы на доставку, а так же отслеживать статусы можно в личном кабинете PayKeeper',
        icon: icon1Img,
        image: scr1Img
    },
    {
        name: 'Отслеживание в реальном времени',
        description: 'Отслеживайте изменение статуса заказа в личном кабинете',
        icon: icon2Img,
        image: scr2Img
    },
    {
        name: 'Личный счет, страхование, наложенный платеж',
        description: 'Принимайте оплату заказов по наложенному платежу, оформляйте страхование отправлений',
        icon: icon3Img,
        image: scr3Img
    },

];

export default function ScreensSection(props) {
    const [selectedNumber, setSelectedNumber] = useState(0);

    const selectedItem = ITEMS[selectedNumber];
    let rightImg = selectedItem.image;

    return (
        <Wrapper>

            <InnerPlaceholder>

                <Left>
                    <LeftHeading>
                        Работайте удобно
                    </LeftHeading>

                    <ItemsPlaceholder>
                        {ITEMS.map((x, i) => {
                            let isSelected = (i == selectedNumber);
                            return (
                                <Item key={i} selected={isSelected} onClick={() => {
                                    setSelectedNumber(i);
                                }}>
                                    <ItemTop selected={isSelected}>
                                        <TopCircle>
                                            <TopCircleImage src={x.icon}/>
                                        </TopCircle>
                                        <TopCircleText selected={isSelected}>
                                            {x.name}
                                        </TopCircleText>
                                    </ItemTop>

                                    {isSelected == false ? null :
                                        <TopDescription>
                                            {x.description}
                                        </TopDescription>
                                    }

                                </Item>
                            )
                        })}
                    </ItemsPlaceholder>

                </Left>

                <Right key={selectedNumber}>
                    <RightImg src={rightImg}/>
                </Right>


            </InnerPlaceholder>
        </Wrapper>
    );
}

const Item = styled.div`

`;

const TopDescription = styled.div`
  margin-bottom: 40px;
  font-weight: 300;
  font-size: 16px;
  line-height: 170%;
  color: #000000;
`;

const ItemTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: ${props => (props.selected == true ? '2px solid #FFCC00' : 'none')};
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
  padding-bottom: 20px;
  margin-bottom: 20px;
`;

const TopCircleText = styled.div`
  font-size: 16px;
  font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
  color: #292828;
`;

const TopCircle = styled.div`
  background: white;
  border-radius: 1000px;
  width: 44px;
  height: 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
`;

const TopCircleImage = styled.img`
  width: 28px;
  height: 28px;
`;

const ItemsPlaceholder = styled.div`

`;

const breakWidth = 1200;

const Left = styled.div`
  width: 400px;
`;

const RightImg = styled.img`
  width: 100%;
  border-radius: 10px;
  border: 0.550594px solid #EAEDF3;
`;

const Right = styled.div`
  flex: 1;
  margin-left: 40px;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100vw;
  margin-top: 80px;
  padding-bottom: 40px;
`;


const InnerPlaceholder = styled.div`
  width: ${breakWidth}px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const LeftHeading = styled.div`
  color: #292828;
  font-size: 36px;
  margin-bottom: 40px;
  font-weight: bold;
`;

const SubLeftHeader = styled.div`
  font-size: 16px;
  color: #000000;
  margin-bottom: 40px;
`;