import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import axios from 'axios'

import {YMaps, Map, Placemark} from 'react-yandex-maps';
import {MAIN_ORANGE_COLOR, MAIN_RED_COLOR} from "../../ui/Colors";

export default function PvzSelectorTool(props) {
  const {
    items = [], selectedCode = undefined, onSelect = it => {

    }
  } = props;
  const [text, setText] = useState('');
  const map = useRef(null);
  useEffect(() => {
  }, []);

  if (items.length == 0) {
    return null;
  }

  const filteredItems = items.filter(a => {
    if (text == undefined || text.trim() == '') {
      return true;
    }
    return (`${a.address} ${a.fullAddress} ${a.name}`.toLowerCase().indexOf(text.toLowerCase()) > -1);
  });

  return (
    <Wrapper>
      <Left>
        <ListPlaceholder>
          <SearchPlaceholder>
            <SearchInput value={text} placeholder={'Поиск'} onChange={evt => {
              setText(evt.target.value);
            }}/>
          </SearchPlaceholder>

          {filteredItems.map((it, k) => {
            let isSelected = (it.code == selectedCode);
            return (
              <Item selected={isSelected} key={it.code} onClick={() => {
                onSelect(it);
              }}>
                <Name>
                  {`${it.name}`}
                </Name>
                <Address>
                  {it.address}
                </Address>
              </Item>
            )
          })}
        </ListPlaceholder>
      </Left>
      <Right>
        <MapPlaceholder>
          <YMaps>
            <Map instanceRef={map}
                 height="100%"
                 width="100%"
                 defaultState={{center: [items[0].coordY, items[0].coordX], zoom: 9}}>
              {filteredItems.map((it, k) => {
                let isSelected = (it.code == selectedCode);
                return (
                  <React.Fragment key={it.code}>
                    <Placemark geometry={[it.coordY, it.coordX]}
                               options={{
                                 preset: `islands#${isSelected == true ? 'icon' : 'icon'}`,
                                 // Setting the placemark color (in RGB format).
                                 iconColor: (isSelected == true ? MAIN_RED_COLOR : MAIN_ORANGE_COLOR)
                               }}
                               properties={
                                 {
                                   balloonContent: (isSelected == false) ? null :
                                     `<div>
                                       selected!
                                     </div>`
                                 }
                               }
                               onClick={() => {
                                 onSelect(it);
                               }}/>
                  </React.Fragment>
                )
              })}
            </Map>
          </YMaps>
        </MapPlaceholder>
      </Right>

    </Wrapper>
  );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    border-top: 1px solid whitesmoke;
    border-bottom: 1px solid whitesmoke;
`;

const Left = styled.div`
    width: 200px;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
`;

const Right = styled.div`
   flex: 1;  
`;


const MapPlaceholder = styled.div`
    width: 100%;
    height: 100%;
    background-color: whitesmoke;
`;

const ListPlaceholder = styled.div`
    width: 100%;
`;

const Item = styled.div`
    background: ${props => (props.selected == true ? MAIN_ORANGE_COLOR : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
    padding: 5px;
    width: 100%;
    border-bottom: 1px solid lightgrey;
    box-sizing: border-box;
`;

const Name = styled.div`
    font-weight: bold;
`;

const Address = styled.div`
    opacity: 0.5;
    font-size: 12px;
    font-style: italic;
`;

const SearchPlaceholder = styled.div`
    
`;

const SearchInput = styled.input`
    outline: none;
    border: none;
    border-bottom: 1px solid lightgrey;
    height: 32px;
    line-height: 32px;
    padding-left: 5px;
    box-sizing: border-box;
    width: 100%;
`;
