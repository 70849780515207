import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import axios from 'axios'

import {PkInput} from "../../ui/Input";
import {PkButton} from "../../ui/Button";
import SimpleDropdown from "../../dropdown/SimpleDropdown";

import AnalyticsAPI from '../../api/AnalyticsAPI';

import PkModal from "../../modals/PkModal";
import {SERVICE_RATE, INSURANCE_RATE} from "../../helpers/DeliveryHelper";
import GoodsEditorTool from "./GoodsEditorTool";

const TAX_OPTIONS = [
  {
    value: 'x',
    label: 'НДС не облагается'
  },
  {
    value: '20',
    label: 'НДС 20%'
  }
];

const DEFAULT_NEW_DATA = {
  quantity: 1,
  pricePerOne: 0,
  weightPerOne: 0,
  name: '',
  tax: '20'
};


export default function CodEditorTool(props) {
  const {
    onCancel = () => {

    },
    onSave = d => {
      console.log('CodEditorTool: onSave: d = ', d);
    }
  } = props;
  const [newFormData, setNewFormData] = useState(DEFAULT_NEW_DATA);
  const [goods, setGoods] = useState(props.goods == undefined ? [] : props.goods);
  const [newGoodModalVisible, setNewGoodModalVisible] = useState(false);

  let newFormTotalPrice = (window.isNaN(newFormData.quantity) || window.isNaN(newFormData.pricePerOne)) ? 0 : ((+newFormData.quantity) * (+newFormData.pricePerOne));
  let canAddNew = (newFormData.name.trim() != '' && !window.isNaN(newFormData.quantity) && !window.isNaN(newFormData.weightPerOne) && !window.isNaN(newFormData.pricePerOne) && (+newFormData.pricePerOne > 0) && (+newFormData.weightPerOne > 0));

  const [deliveryTax, setDeliveryTax] = useState(props.deliveryTax == undefined ? '20' : props.deliveryTax);
  const [deliveryPrice, setDeliveryPrice] = useState(props.deliveryPrice == undefined ? 0 : +(+props.deliveryPrice).toFixed(2));
  const [codEnabled, setCodEnabled] = useState(props.codEnabled == undefined ? true : props.codEnabled);
  const [insuranceEnabled, setInsuranceEnabled] = useState(props.insuranceEnabled == undefined ? true : props.insuranceEnabled);
  const [deliveryPaymentEnabled, setDeliveryPaymentEnabled] = useState(props.deliveryPaymentEnabled == undefined ? false : props.deliveryPaymentEnabled);

  const [deliveryModalVisible, setDeliveryModalVisible] = useState(false);

  const totalGoodsSum = goods.reduce((sum, a) => (+sum + ((+a.quantity) * (+a.pricePerOne))), 0);

  let canSave = (((goods.length > 0 && (codEnabled || insuranceEnabled)) || deliveryPaymentEnabled) && !window.isNaN(deliveryPrice));

  console.log('render: canSave = ', canSave);

  return (
    <Wrapper>

      <TopPlaceholder>
        Укажите, пожалуйста, товары. Эта информация будет отображаться в чеке получателя.
      </TopPlaceholder>

      <GoodsEditorTool goods={goods} onChange={newGoods => {
        console.log('onChange: newGoods = ', newGoods);
        setGoods(newGoods);
      }}/>

      <BottomChecksPlaceholder>

        {goods.length == 0 ?
          <BottomRow style={{opacity: 0.5}}>
            <CheckPlaceholder>
              <CheckImage style={{cursor: 'default'}} src={require('../../assets/sel_check.png')}/>
            </CheckPlaceholder>
            <BottomLabel>
              {`Добавить наложенный платёж`}
            </BottomLabel>
          </BottomRow>
          :
          <BottomRow onClick={() => {
            setCodEnabled(!codEnabled);
          }}>
            <CheckPlaceholder>
              <CheckImage
                onClick={() => {
                  setCodEnabled(!codEnabled);
                }}
                src={(codEnabled == true) ? require('../../assets/sel_check.png') : require('../../assets/empty_box.png')}/>
            </CheckPlaceholder>
            <BottomLabel>
              Добавить наложенный платёж на сумму {` ${(+totalGoodsSum).toFixed(2)} рублей. `} {' '}
              {`Сервисный сбор составит ${((+totalGoodsSum) * SERVICE_RATE).toFixed(2)} рублей`}
            </BottomLabel>
          </BottomRow>
        }

        {(window.isNaN(deliveryPrice) == true) ? null :
          <BottomRow>
            <CheckPlaceholder>
              <CheckImage style={{cursor: 'default'}}
                          onClick={() => {
                            let newDelPEn = !deliveryPaymentEnabled;
                            AnalyticsAPI.trackEvent(`COD_DELIVERY_CHECK_CLICK`, {enabled: newDelPEn});
                            setDeliveryPaymentEnabled(newDelPEn);
                          }}
                          src={(deliveryPaymentEnabled == true) ? require('../../assets/sel_check.png') : require('../../assets/empty_box.png')}/>
            </CheckPlaceholder>
            <BottomLabel>
              {`Добавить стоимость доставки `}
              <DeliveryTogglerSpan onClick={() => {
                setDeliveryModalVisible(true);
              }}>
                {`${(deliveryModalVisible == true) ? '...' : (window.isNaN(deliveryPrice) ? '0' : (+deliveryPrice).toFixed(2))} рублей (${deliveryTax == 'x' ? `без НДС` : `в т.ч. НДС ${(deliveryModalVisible == true) ? '...' : ((+deliveryPrice) * (+deliveryTax) / 100.0).toFixed(2)} ₽`})`}
              </DeliveryTogglerSpan>
              {` к наложенному платежу.`}
            </BottomLabel>
          </BottomRow>
        }

        {goods.length == 0 ?
          <BottomRow style={{opacity: 0.5}}>
            <CheckPlaceholder>
              <CheckImage src={require('../../assets/sel_check.png')}/>
            </CheckPlaceholder>
            <BottomLabel>
              {`Добавить страхование груза`}
            </BottomLabel>
          </BottomRow>
          :
          <BottomRow>
            <CheckPlaceholder>
              <CheckImage
                onClick={() => {
                  let newInsEnabled = !insuranceEnabled;
                  AnalyticsAPI.trackEvent(`INSURANCE_CHECK_CLICK`, {enabled: newInsEnabled});
                  setInsuranceEnabled(newInsEnabled);
                }}
                src={(insuranceEnabled == true) ? require('../../assets/sel_check.png') : require('../../assets/empty_box.png')}/>
            </CheckPlaceholder>
            <BottomLabel>
              {`Добавить страхование груза за ${((+totalGoodsSum) * INSURANCE_RATE).toFixed(2)} рублей`}
            </BottomLabel>
          </BottomRow>
        }

      </BottomChecksPlaceholder>

      <BottomConfirmPlaceholder>
        <PkButton disabled={!canSave} onClick={() => {
          if (canSave == false) {
            return;
          }
          let dd = {
            codEnabled: codEnabled,
            insuranceEnabled: insuranceEnabled,
            deliveryPaymentEnabled: deliveryPaymentEnabled,
            goods: goods,
            deliveryTax: deliveryTax,
            deliveryPrice: deliveryPrice
          };
          AnalyticsAPI.trackEvent(`SAVE_COD_SETTINGS_BUTTON_CLICK`, {data: dd});
          onSave(dd);
        }}>
          СОХРАНИТЬ
        </PkButton>
        <span style={{marginLeft: 15, marginRight: 15}}>
            или
        </span>
        <DeliveryTogglerSpan onClick={() => {
          onCancel();
        }}>
          Отменить
        </DeliveryTogglerSpan>
      </BottomConfirmPlaceholder>


      {deliveryModalVisible == false ? null :
        <PkModal name={'СТОИМОСТЬ ДОСТАВКИ'} width={620} onClose={() => {
          setDeliveryModalVisible(false);
        }}>

          <NewItemFormPlaceholder>

            <NewItemForm>

              <Row>
                <Label>
                  Стоимость
                </Label>
                <FCont>
                  <PkInput value={deliveryPrice} placeholder={''} onChange={evt => {
                    let s = evt.target.value;
                    console.log('new delivery price = ', s);
                    setDeliveryPrice(s);
                  }}/>
                </FCont>
              </Row>

              <Row>
                <Label>
                  Налог
                </Label>
                <FCont>
                  <SimpleDropdown options={TAX_OPTIONS}
                                  value={TAX_OPTIONS.filter(a => (a.value == deliveryTax))[0]}
                                  onChange={a => {
                                    setDeliveryTax(a.value);
                                  }}
                  />
                </FCont>
              </Row>

              <NewItemButtonPlaceholder>
                <PkButton onClick={() => {
                  setDeliveryPaymentEnabled(true);
                  setDeliveryModalVisible(false);
                }}>
                  СОХРАНИТЬ
                </PkButton>

              </NewItemButtonPlaceholder>

            </NewItemForm>

          </NewItemFormPlaceholder>

        </PkModal>
      }

    </Wrapper>
  );
}

const Wrapper = styled.div`
    
`;

const TopPlaceholder = styled.div`
    margin-bottom: 40px;
`;

const VoidMessagePlaceholder = styled.div`
    margin-top: 40px;
    margin-bottom: 40px;
    box-sizing: border-box;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const VoidMessagePlaceholderHeading = styled.div`
    text-align: center;
    font-size: 18px;
`;

const VoidMessagePlusSignPlaceholder = styled.div`
    padding: 20px;
`;

const VoidMessagePlusImage = styled.img`
    width: 90px;
    height: 90px;
    cursor: pointer;
    :hover{
      opacity: 0.9;
    }
`;

const VoidMessageBottomHeadingPlaceholder = styled.div`
    text-align: center;
`;

const VoidMessageBottomButtonSpan = styled.span`
    cursor: pointer;
    font-size: 16px;
    border-bottom: 1px dotted #425684;
    color: #425684;
    :hover{
      opacity: 0.9;
    }
`;

const NewItemFormPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const NewItemForm = styled.div`
    width: 480px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
`;

const Label = styled.div`
    box-sizing: border-box;
    width: 220px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const FCont = styled.div`
    flex: 1;
`;

const RowValsField = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const RowValItem = styled.div`
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const FirstRowValItem = styled(RowValItem)`
    position: relative;
`;

const ItemRubPlaceholder = styled.div`
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    padding-left: 4px;
    padding-right: 4px;
    height: 100%;
    border-left: 1px solid #a9a9a9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #a9a9a9;
`;

const NewItemButtonPlaceholder = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 10px;
    justify-content: center;
`;

const AddGoodRightPlaceholder = styled.div`
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;


const TablePlaceholder = styled.div`
    width: 100%;
    margin-top: 20px;
    margin-bottom: 40px;
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    border: 1px solid whitesmoke;
`;

const Thead = styled.thead`
    background: #FAFAFA;
`;

const Tbody = styled.tbody`
    
`;

const Tr = styled.tr`
    
`;

const Td = styled.td`
    text-align: right;
    padding: 3px;
    border: 1px solid whitesmoke;
`;

const Th = styled.th`
    text-align: right;
    font-weight: normal;
`;

const DelSpan = styled.span`
    cursor: pointer;
    border-bottom: 1px dotted #425684;
    color: #425684;
    opacity: 0.7;
    :hover{
      opacity: 0.99;
    }
`;

const BottomRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
`;

const CheckPlaceholder = styled.div`
    width: 30px;
    display: flex;
    align-items: center;
    flex-direction: row;
`;

const BottomLabel = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const BottomChecksPlaceholder = styled.div`
    margin-top: 30px;
`;

const CheckImage = styled.img`
    width: 16px;
    height: 16px;
    cursor: pointer;
    :hover{
      opacity: 0.9;
    }
`;

const DeliveryTogglerSpan = styled.span`
    display: inline-block;
    cursor: pointer;
    border-bottom: 1px dotted #425684;
    color: #425684;
    opacity: 0.99;
    margin-left: 5px;
    margin-right: 5px;
    :hover{
      opacity: 0.8;
    }
`;

const BottomConfirmPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
`;
