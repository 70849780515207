import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import axios from 'axios'

import {PkButton} from "../ui/Button";
import CityPvzPanel from "./CityPvzPanel";
import PrettyCitySelect from "../delivery/tools/PrettyCitySelect";

export default function AllPvzPanel(props) {
    const {hasSelector = false} = props;
    const [selectedCode, setSelectedCode] = useState('44');

    return (
        <Wrapper>

            <TopPlaceholder>

                <TopRightPlaceholder>
                    <PrettyCitySelect code={selectedCode} onChange={newCode => {
                        setSelectedCode(newCode);
                    }}/>
                </TopRightPlaceholder>
            </TopPlaceholder>

            <MapPlaceholder hasSelected={(selectedCode != undefined)}>
                <CityPvzPanel code={selectedCode}/>
            </MapPlaceholder>

        </Wrapper>
    );
}

const TopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 27px;
`;

const MapPlaceholder = styled.div`
    width: 100%;
    height: ${props => (props.hasSelected == true ? 434 : 404)}px;
    box-sizing: border-box;
`;

const TopLeftPlaceholder = styled.div`
    font-size: 20px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-align: justify;
    text-transform: uppercase;
`;

const TopRightPlaceholder = styled.div`
    min-width: 200px;  
`;

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    background: #F7F7F7;
    padding: 50px;
    padding-top: 25px;
`;

const PvzPlaceholder = styled.div`
    flex: 1;
    overflow-y: auto;
`;

const PvzPlaceholderInner = styled.div`
    width: 100%;
    height: 100%;
`;

const SearchPlaceholder = styled.div`
    
`;

