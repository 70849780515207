import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import moment from 'moment'

import Select from 'react-select'
import '../../tools/style.css'
import SimpleDateSelector from "../../calendar/SimpleDateSelector";

import SimpleDropdown from '../../dropdown/SimpleDropdown'

const START_OF_TODAY = moment().startOf('day');
const END_OF_TODAY = moment().endOf('day');
const DAY = 86400000;

const OPTIONS = [
  {
    label: 'Сегодня',
    value: {
      start: START_OF_TODAY,
      end: END_OF_TODAY
    },
  },
  {
    label: 'Вчера',
    value: {
      start: START_OF_TODAY - DAY,
      end: END_OF_TODAY - DAY
    },
  },
  {
    label: 'Неделю',
    value: {
      start: START_OF_TODAY - 7 * DAY,
      end: END_OF_TODAY
    },
  },
  {
    label: 'Месяц',
    value: {
      start: START_OF_TODAY - 30 * DAY,
      end: END_OF_TODAY
    },
  },
  {
    label: 'Год',
    value: {
      start: +moment().add(-1, 'years').startOf('day'),
      end: END_OF_TODAY
    },
  },
  {
    label: 'Период',
    value: {
      start: START_OF_TODAY,
      end: END_OF_TODAY
    }
  },
];

const getOrdersNumberInPeriod = (ords, start, end) => {
  let fOrders = ords.filter(a => (+new Date(a.createdAt) > +start && +new Date(a.createdAt) < +end));
  return fOrders.length;
}

export default function DatesRangeFilterTool(props) {
  const {
    fromTimestamp = OPTIONS[3].value.start, toTimestamp = OPTIONS[3].value.end, onChange = (start_, end_) => {
    },
    orders = []
  } = props;
  const [start, setStart] = useState(START_OF_TODAY - 30 * DAY);
  const [end, setEnd] = useState(END_OF_TODAY);

  let selectedOption = OPTIONS.filter(a => (a.value.start == start && a.value.end == end))[0];
  if (selectedOption == undefined) {
    selectedOption = OPTIONS[OPTIONS.length - 1];
  }
  let opts = OPTIONS.map((a, k) => ({
    ...a,
    number: ((k == OPTIONS.length - 1) ? undefined : getOrdersNumberInPeriod(orders, a.value.start, a.value.end))
  }));

  return (
    <Wrapper>

      <DelItem>
        {'Показать доставки за: '}
      </DelItem>

      <FirstItem>
        <SimpleDropdown
          options={opts}
          value={selectedOption}
          hasRightNumbers={true}
          onChange={a => {
            setStart(a.value.start)
            setEnd(a.value.end);
            onChange(a.value.start, a.value.end);
          }}/>
      </FirstItem>

      <DelItem style={{marginLeft: 9, marginRight: 9}}>
        {'c '}
      </DelItem>

      <Item>
        <SimpleDateSelector selectedTimestamp={start} onChange={a => {
          setStart(moment(a).startOf('day'));
          onChange(moment(a).startOf('day'), end);
        }}/>
      </Item>

      <DelItem>
        {' до '}
      </DelItem>

      <Item>
        <SimpleDateSelector selectedTimestamp={end} onChange={a => {
          setEnd(moment(a).endOf('day'));
          onChange(start, moment(a).endOf('day'));
        }}/>
      </Item>

    </Wrapper>
  );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    line-height: 21px;
`;

const Item = styled.div`
    min-width: 160px;
`;

const FirstItem = styled.div`
    
`;

const DelItem = styled.div`
    min-width: 15px;
    text-align: center;
    margin-left: 13px;
    margin-right: 13px;
`;
