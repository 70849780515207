import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import axios from 'axios'
import UpdateGoodItemTool from "./UpdateGoodItemTool";
import AnalyticsAPI from "../../api/AnalyticsAPI";

import PkModal from "../../modals/PkModal";
import {PkButton} from "../../ui/Button";
import SimpleDropdown from "../../dropdown/SimpleDropdown";
import UpdateGoodForm from "./goods/UpdateGoodForm";

const DEFAULT_NEW_DATA = {
  quantity: 1,
  pricePerOne: 0,
  weightPerOne: 0,
  name: '',
  tax: '20'
};

const TAX_OPTIONS = [
  {
    value: 'x',
    label: 'НДС не облагается'
  },
  {
    value: '20',
    label: 'НДС 20%'
  }
];

export default function GoodsEditorTool(props) {
  const {
    onChange = newGoods => {

    }
  } = props;
  const [newGoodModalVisible, setNewGoodModalVisible] = useState(false);
  const [goods, setGoods] = useState(props.goods == undefined ? [] : props.goods);

  const [selectedGoodId, setSelectedGoodId] = useState(undefined);
  let selectedGood = (selectedGoodId == undefined) ? undefined : goods.filter(a => (a.id == selectedGoodId))[0];
  const totalGoodsSum = goods.reduce((sum, a) => (+sum + ((+a.quantity) * (+a.pricePerOne))), 0);
  const totalTaxSum = goods.reduce((sum, a) => (+sum + ((window.isNaN(a.tax) == true) ? 0 : (+a.tax) * (+a.quantity) * (+a.pricePerOne) / 100.0)), 0);


  return (
    <Wrapper>

      {goods.length > 0 ? null :
        <VoidMessagePlaceholder>
          <VoidMessagePlaceholderHeading>
            Пока нет ни одного товара
          </VoidMessagePlaceholderHeading>
          <VoidMessagePlusSignPlaceholder>
            <VoidMessagePlusImage src={require('../../assets/plus_ko.svg')} onClick={() => {
              setNewGoodModalVisible(true);
            }}/>
          </VoidMessagePlusSignPlaceholder>
          <VoidMessageBottomHeadingPlaceholder>
            <VoidMessageBottomButtonSpan onClick={() => {
              setNewGoodModalVisible(true);
            }}>
              Добавить
            </VoidMessageBottomButtonSpan>
          </VoidMessageBottomHeadingPlaceholder>
        </VoidMessagePlaceholder>
      }

      {goods.map((good, i) => {
        let ndsMessage = (window.isNaN(good.tax) == true) ? 'НДС не облагается' : `НДС ${good.tax}%`
        let taxValue = (good.tax == 'x') ? 0 : ((+good.tax) * (+good.pricePerOne) * (+good.quantity) / 100.0);
        return (
          <GoodItem key={`${i}_${good.id}`}>
            <GoodLeft>
              <GoodName>
                {`${+i + 1}. ${good.name}`}
              </GoodName>
              <GoodDescription>
                {`${ndsMessage}, товар`}
              </GoodDescription>
            </GoodLeft>
            <GoodRight>
              <GoodName>
                {`${good.quantity} x ${good.pricePerOne} = ${((+good.quantity) * (+good.pricePerOne)).toFixed(2)}`}
              </GoodName>
              <GoodDescription>
                <DescrRow>
                  {`${taxValue.toFixed(2)}`}
                </DescrRow>
              </GoodDescription>
            </GoodRight>
            <GoodVeryRight>
              <ControlIconImage src={require('../../assets/edit_pic.png')} onClick={() => {
                setSelectedGoodId(good.id);
              }}/>
              <ControlIconImage src={require('../../assets/delete_bin.png')} onClick={() => {
                let newGoods = goods.filter(a => (a.id != good.id));
                setGoods(newGoods);
                onChange(newGoods);
              }}/>
            </GoodVeryRight>
          </GoodItem>
        )
      })}

      {goods.length == 0 ? null :
        <GoodItem style={{marginTop: 30, marginBottom: 30}}>
          <GoodLeft>
            <GoodName>
              {`ИТОГО`}
            </GoodName>
            <GoodDescription>
              <DescrRow>
                {'В том числе налоги'}
              </DescrRow>
              <DescrRow>
                {'НДС 20%'}
              </DescrRow>
            </GoodDescription>
          </GoodLeft>
          <GoodRight style={{flex: 1, textAlign: 'right'}}>
            <GoodName>
              {`= ${totalGoodsSum.toFixed(2)}`}
            </GoodName>
            <GoodDescription>
              <DescrRow>
                {''}
              </DescrRow>
              <DescrRow>
                {totalTaxSum.toFixed(2)}
              </DescrRow>
            </GoodDescription>
          </GoodRight>
          <GoodVeryRight>
            <GoodsRightAddButtonImage
              src={require('../../assets/plus_ko.svg')}
              onClick={() => {
                setNewGoodModalVisible(true);
              }}
            />
          </GoodVeryRight>
        </GoodItem>
      }


      {newGoodModalVisible == false ? null :
        <PkModal name={'ТОВАР'} width={620} canClose={false} onClose={() => {
          setNewGoodModalVisible(false);
        }}>
          <UpdateGoodForm {...DEFAULT_NEW_DATA}
                          onSave={d => {
                            let newGoods = goods.concat([{...JSON.parse(JSON.stringify(d)), id: uuid()}]);
                            setGoods(newGoods);
                            onChange(newGoods);
                            setNewGoodModalVisible(false);
                            AnalyticsAPI.trackEvent(`ADD_GOOD_BUTTON_CLICK`);
                          }}
                          onCancel={() => {
                            setNewGoodModalVisible(false);
                          }}/>
        </PkModal>
      }

      {selectedGood == undefined ? null :
        <PkModal name={'ТОВАР'} width={620} canClose={false} onClose={() => {
          setSelectedGoodId(undefined);
        }}>
          <UpdateGoodForm {...selectedGood}
                          onSave={d => {
                            let newGoods = goods.map(a => (a.id == selectedGood.id ? ({
                              ...d,
                              id: selectedGoodId
                            }) : a));
                            setGoods(newGoods);
                            AnalyticsAPI.trackEvent(`ADD_GOOD_BUTTON_CLICK`);
                            onChange(newGoods);
                            setSelectedGoodId(undefined);
                          }}
                          onCancel={() => {
                            setSelectedGoodId(undefined);
                          }}/>
        </PkModal>
      }


    </Wrapper>
  );
}

const Wrapper = styled.div`
    
`;

const GoodItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start; 
  justify-content: space-between; 
  margin-bottom: 10px;  
`;

const GoodLeft = styled.div`
    
`;

const GoodRight = styled.div`
    flex: 1;
    text-align: right;
    box-sizing: border-box;
    padding-right: 20px;
`;

const GoodVeryRight = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 70px;
`;

const GoodsRightAddButtonImage = styled.img`
    width: 64px;
    cursor: pointer;
    :hover{
      opacity: 0.9;
    }
`;

const ControlIconImage = styled.img`
    margin-left: 4px;
    margin-right: 4px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const GoodName = styled.div`
    font-size: 18px;
    color: #212529;
`;

const GoodDescription = styled.div`
    color: #a9a9a9;
    font-size: 16px;
`;

const DescrRow = styled.div`
    height: 20px;
    line-height: 20px;
`;

const VoidMessagePlaceholder = styled.div`
    margin-top: 40px;
    margin-bottom: 40px;
    box-sizing: border-box;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const VoidMessagePlaceholderHeading = styled.div`
    text-align: center;
    font-size: 18px;
`;

const VoidMessagePlusSignPlaceholder = styled.div`
    padding: 20px;
`;

const VoidMessagePlusImage = styled.img`
    width: 90px;
    height: 90px;
    cursor: pointer;
    :hover{
      opacity: 0.9;
    }
`;

const VoidMessageBottomHeadingPlaceholder = styled.div`
    text-align: center;
`;

const VoidMessageBottomButtonSpan = styled.span`
    cursor: pointer;
    font-size: 16px;
    border-bottom: 1px dotted #425684;
    color: #425684;
    :hover{
      opacity: 0.9;
    }
`;


const ItemRubPlaceholder = styled.div`
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    padding-left: 4px;
    padding-right: 4px;
    height: 100%;
    border-left: 1px solid #a9a9a9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #a9a9a9;
`;

const NewItemButtonPlaceholder = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 10px;
    justify-content: center;
`;

const AddGoodRightPlaceholder = styled.div`
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;
