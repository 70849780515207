import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {MAIN_ORANGE_COLOR} from "../ui/Colors";
import DeliveryAPI from "../api/DeliveryAPI";

export default function PrettyCitySelect(props) {
  const {
    code, onChange = newCode => {

    },
    red = false,
    placeholder = 'Название населённого пункта'
  } = props;

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [text, setText] = useState('');
  const [searching, setSearching] = useState(false);
  const [results, setResults] = useState([]);

  useEffect(() => {
    setSearching(true);
    DeliveryAPI.getCityByCode(code).then(city => {
      setSearching(false);
      if (city != undefined) {
        setSelectedOption({label: city.fullName, value: city.code});
      }
    })
  }, [code]);

  const node = useRef();
  const handleClick = e => {
    if (node == undefined || node.current == undefined) {
      return;
    }
    if (node.current.contains(e.target)) {
      return;
    }
    setDropdownVisible(false);
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  useEffect(() => {
    setSearching(true);
    DeliveryAPI.getDropdownCityOptions(text).then(options => {
      setSearching(false);
      setResults(options);
    });
  }, [text]);

  let isNotFound = (searching == false && text.trim() != '' && results.length == 0);

  return (
    <Wrapper ref={node}>
      <InputPlaceholder>
        <InputDiv red={red} selected={dropdownVisible} onClick={() => {
          setDropdownVisible(!dropdownVisible);
        }}>
          <MonthName>
            {selectedOption == undefined ? <span style={{opacity: 0.6}}>{placeholder}</span> : selectedOption.label}
          </MonthName>
          <ArrowImage src={require('./caret-down.svg')}
                      style={{transform: `rotate(${dropdownVisible == true ? 180 : 0}deg)`}}/>
        </InputDiv>
      </InputPlaceholder>
      {dropdownVisible == false ? null :
        <DropdownPlaceholder>
          <InnerInputPlaceholder>
            <Input autoFocus={true} value={text} onChange={evt => {
              setText(evt.target.value);
            }}/>
          </InnerInputPlaceholder>
          {!(text == undefined || text.trim() == '') ? null :
            <VoidPlaceholder>
              Начните набирать и выберите из выпадающего списка
            </VoidPlaceholder>
          }
          {isNotFound == false ? null :
            <VoidPlaceholder>
              Ничего не найдено
            </VoidPlaceholder>
          }
          {searching == false ? null :
            <VoidPlaceholder style={{opacity: 0.5}}>
              Поиск...
            </VoidPlaceholder>
          }

          {results.length == 0 ? null :
            <ResultsPlaceholder>
              {results.map((res, k) => {
                return (
                  <Item selected={((selectedOption != undefined) && (+selectedOption.value == +res.value))}
                        key={res.value} onClick={() => {
                    setSelectedOption(res);
                    setDropdownVisible(false);
                    onChange(res.value);
                  }}>
                    {res.label}
                  </Item>
                )
              })}
            </ResultsPlaceholder>
          }

        </DropdownPlaceholder>
      }
    </Wrapper>
  );
}

const Wrapper = styled.div`
    position: relative;
`;

const InputPlaceholder = styled.div`
    
`;

const DropdownPlaceholder = styled.div`
    position: absolute;
    top: 27px;
    background: white;
    border: 1px solid #a9a9a9;
    z-index: 1000;
    left: 0px;
    right: 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
`;

const InputDiv = styled.div`
    font-size: 15px;
    background: #FFFFFF;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 100%;
    color: #444;
    padding-left: 5px;
    height: 28px;
    line-height: 28px;
    box-sizing: border-box;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    
    border-bottom-right-radius: ${props => (props.selected == true ? 0 : 4)}px;
    border-bottom-left-radius: ${props => (props.selected == true ? 0 : 4)}px;
    
    border: 1px solid ${props => (props.red == true ? 'red' : '#a9a9a9')};
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    :hover{
      opacity: 0.85;
    }
`;

const MonthName = styled.div`
    
`;


const ArrowImage = styled.img`
    width: 8px;
    height: 8px;
    margin-right: 5px;
    opacity: 0.5;
    transition-duration: 0.2s
`;

const Input = styled.input`
    box-sizing: border-box;
    width: 100%;
    font-size: 16px;
    border: 1px solid rgb(170, 170, 170);
    line-height: 16px;
    height: 26px;
    padding: 4px;
    outline: none;
`;

const VoidPlaceholder = styled.div`
    padding: 4px;
    line-height: 20px;
    font-size: 14px;
    margin-bottom: 4px;
    font-family: PT Sans;
`;

const InnerInputPlaceholder = styled.div`
    padding: 4px;
    box-sizing: border-box;
`;

const ResultsPlaceholder = styled.div`
    max-height: 200px;
    overflow-y: auto;
`;

const Item = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: PT Sans;
    width: 100%;
    box-sizing: border-box;
    height: 32px;
    padding: 6px;
    line-height: 20px;
    font-size: 14px;
    cursor: pointer;
    background: ${props => (props.selected == true ? 'lightgrey' : 'transparent')};
    :hover{
      background-color: ${MAIN_ORANGE_COLOR};
      color: white;
    }
`;
