import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import axios from 'axios'
import SimpleLoader from "../../loaders/SimpleLoader";
import DeliveryAPI from "../../api/DeliveryAPI";

import {PkInput} from "../../ui/Input";
import {PkButton} from "../../ui/Button";
import PkModal from '../../modals/PkModal'
import UpdateTemplateForm from "../forms/UpdateTemplateForm";

export default function AllTemplatesPanel(props) {
  const {
    login, password,
    onTemplateClick = c => {

    }
  } = props;

  const [loading, setLoading] = useState(true);
  const [templates, setTemplates] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedId, setSelectedId] = useState(undefined);
  const [addModalVisible, setAddModalVisible] = useState(false);


  useEffect(() => {
    DeliveryAPI.getTemplates(login, password).then(arr => {
      setLoading(false);
      setTemplates(arr);
    })
  }, []);


  const filteredTemplates = templates.filter(a => {
    if (searchText == undefined || searchText.trim() == '') {
      return true;
    }
    let s = `${a.name} ${a.email} ${a.phone} ${a.street} ${a.building} ${a.description == undefined ? '' : a.description}`.toLowerCase();
    return (s.indexOf(searchText.toLowerCase()) > -1);
  });

  const selectedTemplate = (selectedId == undefined) ? undefined : templates.filter(a => (a.id == selectedId))[0];

  return (
    <Wrapper>

      <TopPlaceholder>
        <SearchPlaceholder>
          <PkInput value={searchText} placeholder={'Поиск по названию и контактам'} onChange={evt => {
            setSearchText(evt.target.value);
          }}/>
        </SearchPlaceholder>
        <ControlsPlaceholder>
          <PkButton style={{fontSize: 12}} onClick={() => {
            setAddModalVisible(true);
          }}>
            + ШАБЛОН
          </PkButton>
        </ControlsPlaceholder>
      </TopPlaceholder>


      {loading == true ? <div>
        <SimpleLoader/>
      </div> : null}


      <TemplatesList>
        {filteredTemplates.map((c, k) => {
          let {name, description} = c;
          return (
            <ContactItem key={`${c.id}`}>
              <InfoItem onClick={() => {
                onTemplateClick(c);
              }}>
                <Name>
                  {c.name}
                </Name>
                {description == undefined || description.trim() == '' ? null :
                  <DescriptionPlaceholder>
                    {description}
                  </DescriptionPlaceholder>
                }
              </InfoItem>
              <ItemControlsPlaceholder>
                <EditSpan onClick={() => {
                  setSelectedId(c.id);
                }}>
                  <EditImg src={require('../../assets/edit_icon.svg')}/>
                  <span>
                    редактировать
                  </span>
                </EditSpan>
              </ItemControlsPlaceholder>
            </ContactItem>
          );
        })}
      </TemplatesList>

      {selectedTemplate == undefined ? null :
        <PkModal zIndex={3000} width={1020} name={'РЕДАКТИРОВАНИЕ ШАБЛОНА'} onClose={() => {
          setSelectedId(undefined)
        }}>

          <UpdateTemplateForm {...selectedTemplate}
                              canDelete={true}
                              onDelete={async () => {
                                setLoading(true);
                                await DeliveryAPI.deleteTemplate(selectedTemplate.id, login, password);
                                let arr = await DeliveryAPI.getTemplates(login, password);
                                setLoading(false);
                                setTemplates(arr);
                                setSelectedId(undefined);
                              }}
                              onSave={async d => {
                                setLoading(true);
                                await DeliveryAPI.createOrUpdateTemplate({
                                  ...d,
                                  id: selectedTemplate.id
                                }, login, password);
                                let arr = await DeliveryAPI.getTemplates(login, password);
                                setLoading(false);
                                setTemplates(arr);
                                setSelectedId(undefined);
                              }}/>

        </PkModal>
      }

      {addModalVisible == false ? null :
        <PkModal zIndex={3000} width={1020} name={'ДОБАВЛЕНИЕ ШАБЛОНА'} onClose={() => {
          setAddModalVisible(false)
        }}>

          <UpdateTemplateForm onSave={async d => {
            setLoading(true);
            await DeliveryAPI.createOrUpdateTemplate(d, login, password);
            let arr = await DeliveryAPI.getTemplates(login, password);
            setLoading(false);
            setTemplates(arr);
            setAddModalVisible(false);
          }}/>

        </PkModal>
      }


    </Wrapper>
  );
}

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
`;

const TemplatesList = styled.div`
    
`;

const ContactItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    opacity: 0.8;
    border-radius: 4px;
    border: 1px solid lightgrey;
    padding: 5px;
    margin-bottom: 10px;
    background: white;
    :hover{
      opacity: 1;
    }
`;

const InfoItem = styled.div`
    flex: 1;
`;

const ItemControlsPlaceholder = styled.div`
    width: 80px;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

const Name = styled.div`
    font-weight: bold;
    cursor: pointer;
    :hover{
      text-decoration: underline;
    }
`;

const ItemTemplates = styled.div`
    
`;

const DescriptionPlaceholder = styled.div`
    font-size: 12px;
    opacity: 0.4;
    font-style: italic;
`;

const SearchPlaceholder = styled.div`
    flex: 1;
`;

const TopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
`;

const ControlsPlaceholder = styled.div`
    margin-left: 10px;
    width: 120px;
`;

const EditSpan = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    opacity: 0.5;
    :hover{
      opacity: 1;
      text-decoration: underline;
    }
`;

const EditImg = styled.img`
    width: 10px;
    height: 10px;
    margin-right: 4px;
`;
