import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import TaxiAPI from "../../api/TaxiAPI";
import SimpleLoader from "../../loaders/SimpleLoader";
import FromToMapSelectorTool from "./FromToMapSelectorTool";

import axios from 'axios'

import THEME from '../../theme'

import {PkInput} from "../../ui/Input";

const {DELIVERY_TYPE_BORDER_COLOR, REMOVE_ADDITIONAL_PHONE_PLUS_ICON, ADD_ADDITIONAL_PHONE_PLUS_ICON} = THEME;

async function getStreetAndHouseByCoords(lat, lon) {
    console.log('getStreetAndHouseByCoords occured! lat, lon = ', lat, lon)
    let pld = (await axios.get(`https://api.taxi.sabir.pro/geocoder/address_by_coords`, {
        params: {
            lat: lat,
            lon: lon
        }
    })).data.result;
    if (pld == undefined || pld.data == undefined) {
        return undefined;
    }
    let {street_with_type, house, block_type, block} = pld.data;
    let blockPart = (block == undefined) ? '' : (block_type == undefined || block_type == '' ? '' : `${block_type} ${block}`);
    let housePart = (house == undefined) ? '' : `${house} ${blockPart}`.trim();
    return {
        street: `${street_with_type}`.replace('ул ', ''),
        building: housePart
    }
}

export default function YandexCreateOrderInfoTool(props) {
    const {
        from_code,
        to_code,
        from_street,
        to_street,
        from_building,
        to_building,

        onFromAddressChange = (d) => {

        },

        onToAddressChange = (d) => {

        },

        onSkipDoorToDoorChange = f => {

        },

        onFromToPositionChange = (newFrom, newTo) => {
            console.log('onFromToPositionChange: newFrom, newTo = ', newFrom, newTo);
        }

    } = props;

    const [skip_door_to_door, setSkip_door_to_door] = useState(props.skip_door_to_door == undefined ? true : props.skip_door_to_door);

    const [priceLoading, setPriceLoading] = useState(false);

    const [priceData, setPriceData] = useState(undefined);
    const [position, setPosition] = useState({});

    const debouncedFormData = useDebounce(`${from_code}_${to_code}_${from_street}_${to_street}_${from_building}_${to_building}`, 1000);
    const debouncedPosition = useDebounce(position, 500);
    const debouncedFromPosition = useDebounce(`${position.from == undefined ? '' : `${position.from.lat}_${position.from.lon}`}`, 1500);
    const debouncedToPosition = useDebounce(`${position.to == undefined ? '' : `${position.to.lat}_${position.to.lon}`}`, 1500);

    useEffect(() => {
        console.log('debouncedFormData changed!');
        TaxiAPI.getCoordsByData(props).then(a => {
            console.log('setting position = ', a);
            if (a == undefined || a.from == undefined || a.to == undefined) {
                return;
            }
            setPosition(a);
        });
    }, [debouncedFormData]);

    useEffect(() => {
        console.log('debouncedPosition changed!');
        if (position == undefined || position.from == undefined || position.to == undefined) {
            return;
        }
        setPriceLoading(true);
        TaxiAPI.getPriceByFromToPositions(position.from, position.to).then(tPld => {
            console.log('got price data! tPld = ', tPld);
            setPriceData(tPld);
            setPriceLoading(false);
            console.log('rPld = ', tPld);
        }).catch(e => {
            setPriceLoading(false);
        });
    }, [debouncedPosition]);

    useEffect(() => {
        if (position == undefined || position.from == undefined || position.to == undefined) {
            return;
        }
        getStreetAndHouseByCoords(position.from.lat, position.from.lon).then(d => {
            if (d == undefined) {
                return;
            }
            onFromAddressChange({
                from_street: d.street,
                from_building: d.building,
            })
        });
    }, [debouncedFromPosition]);

    useEffect(() => {
        if (position == undefined || position.from == undefined || position.to == undefined) {
            return;
        }
        getStreetAndHouseByCoords(position.to.lat, position.to.lon).then(d => {
            if (d == undefined) {
                return;
            }
            onToAddressChange({
                to_street: d.street,
                to_building: d.building,
            })
        });
    }, [debouncedToPosition]);


    console.log('render: priceData = ', priceData);

    return (
        <Wrapper>

            <PricingPlaceholder>

                <DeliveryType selected={(skip_door_to_door == true)} onClick={() => {
                    setSkip_door_to_door(true);
                    onSkipDoorToDoorChange(true);
                }}>
                    <TypeDetailsSpan>
                        <TypeDetSpan>
                            {'Дом - Дом'}
                        </TypeDetSpan>
                        <span style={{fontSize: 16, lineHeight: '16px', marginTop: 4}}>
                            Нужно будет выйти к машине такси для передачи и забора груза
                        </span>
                    </TypeDetailsSpan>
                    <TypeDetailsSpan style={{fontSize: 32}}>
                        {(priceData == undefined || priceData.streetPrice == undefined) ? '' : `${(+priceData.streetPrice).toFixed(2)} р.`.replace('.00', '')}
                    </TypeDetailsSpan>
                </DeliveryType>

                <DeliveryType selected={(skip_door_to_door == false)} onClick={() => {
                    setSkip_door_to_door(false);
                    onSkipDoorToDoorChange(false);
                }}>

                    <TypeDetailsSpan>
                        <TypeDetSpan>
                            {'Дверь - Дверь'}
                        </TypeDetSpan>
                        <span style={{fontSize: 16, lineHeight: '16px', marginTop: 4}}>
                            Водитель такси отнесёт и заберёт грузы сам
                        </span>
                    </TypeDetailsSpan>
                    <TypeDetailsSpan style={{fontSize: 32}}>
                        {(priceData == undefined || priceData.price == undefined) ? '' : `${(+priceData.price).toFixed(2)} р.`.replace('.00', '')}
                    </TypeDetailsSpan>

                </DeliveryType>

            </PricingPlaceholder>

            {position == undefined || position.from == undefined || position.to == undefined ? null :
                <BottomPlaceholder>
                    <MapPlaceholder>
                        <MapPlaceholderInner>
                            <FromToMapSelectorTool {...position} onChange={d => {
                                setPosition(d);
                                onFromToPositionChange(d.from, d.to);
                            }}/>
                        </MapPlaceholderInner>
                    </MapPlaceholder>

                </BottomPlaceholder>

            }

            <VeryBottomPlaceholder>
                <InsuranceSpan>
                    Ваш груз застрахован на 500 000 рублей.
                </InsuranceSpan>
            </VeryBottomPlaceholder>

            {priceLoading == false ? null :
                <Overlay>
                    <OverlayInner>
                        <SimpleLoader/>
                    </OverlayInner>
                </Overlay>
            }
        </Wrapper>
    );
}

const doorInfoWidth = 280;

const RightHeading = styled.div`
    color: #a9a9a9;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 15px;
`;

const RightSection = styled.div`
    padding-bottom: 10px;
    margin-bottom: 20px;
`;

const RightField = styled.div`
    margin-bottom: 13px;
    display: flex;
    flex-direction: row;
`;

const RightLabel = styled.div`
    width: 260px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const DoorInfoPlaceholder = styled.div`
    width: ${doorInfoWidth}px;
    box-sizing: border-box;
    padding-left: 15px;  
`;

const PricingPlaceholder = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
`;

const TypeDetailsSpan = styled.div`
    font-size: 24px;
    line-height: 24px;
    color: #696969;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const TypeDetSpan = styled.div`
    width: 160px;
    :first-of-type{
      margin-right: 20px;
    }
`;

const DeliveryType = styled.div`
  
    height: 48px;
    margin-bottom: 10px;
    border-radius: 6px;
    padding: 5px;
    border: ${props => (props.selected == true ? `2px solid ${DELIVERY_TYPE_BORDER_COLOR}` : '2px dashed whitesmoke')};
    background: ${props => (props.selected == true ? 'whitesmoke' : 'white')};
    cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    :hover{
      border: 2px solid ${DELIVERY_TYPE_BORDER_COLOR};
    }
`;

const PriceField = styled.div`
    
`;

const PriceFieldLeft = styled.div`
    
`;

const PriceFieldRight = styled.div`
    
`;

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    position: relative;
`;

const VeryBottomPlaceholder = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
`;

const InsuranceSpan = styled.span`
    font-weight: normal;
    color: #425684;
    display: inline-block;
    border-bottom: 1px dotted #425684;
    font-size: 14px;
`;

const BottomPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
    box-sizing: border-box;
`;

const MapPlaceholder = styled.div`
    height: 340px;
    background: lightgrey;
    flex: 1 0 auto; 
    width: 100%;
`;

const MapPlaceholderInner = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`;

const Overlay = styled.div`
    z-index: 1;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(255, 255, 255, 0.7);
`;

const OverlayInner = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;


function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
        () => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay]
    );
    return debouncedValue;
}
