import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import {PkInput} from "../../ui/Input";
import {PkButton} from "../../ui/Button";
import {MAIN_ORANGE_COLOR} from "../../ui/Colors";

import THEME from '../../theme'

const {REPLENISHMENT_PANELS_BACKGROUND, SECONDARY_BUTTON_BACKGROUND} = THEME;

const OPTIONS = [
  {
    value: 'bill',
    heading: 'Банковский перевод',
    subHeading: 'Оплата с расчётного счёта организации.',
    description: 'Вам будет выставлен счёт, который необходимо оплатить с расчетного счёта организации.',
    image: require('../../assets/Bill.png')
  },
  {
    value: 'visa',
    heading: 'Карта Visa',
    subHeading: 'Оплата пластиковыми картами Visa.',
    description: 'Приём платежей обеспечивает ЗАО «Банк Русский Стандарт».',
    image: require('../../assets/Visa.png')
  },
  {
    value: 'master',
    heading: 'Карта MasterCard',
    subHeading: 'Оплата пластиковыми картами MasterCard.',
    description: 'Приём платежей обеспечивает ЗАО «Банк Русский Стандарт».',
    image: require('../../assets/MasterCard.png')
  },
  {
    value: 'mir',
    heading: 'Карта МИР',
    subHeading: 'Оплата пластиковыми картами МИР.',
    description: 'Приём платежей обеспечивает ЗАО «Банк Русский Стандарт».',
    image: require('../../assets/Mir.png')
  }
]

export default function ReplenishmentForm(props) {
  const [text, setText] = useState('1000');
  const [method, setMethod] = useState('bill');
  const {
    onSubmit = sum => {

    }
  } = props;

  return (
    <Wrapper>

      <Field>
        <FLeft>
          Сумма пополнения
        </FLeft>
        <FRight>
          <InputPlaceholder>
            <PkInput placeholder={'1000'} value={text} onChange={evt => {
              setText(evt.target.value);
            }}/>
          </InputPlaceholder>
        </FRight>
      </Field>

      <CenterPlaceholder>

        {OPTIONS.map((opt, k) => {
          let isSelected = (method == opt.value);
          return (
            <BRow selected={isSelected} key={k} onClick={() => {
              setMethod(opt.value)
            }}>
              <BLeft selected={isSelected} className={'repl_b_left'}>
                <LeftImage src={opt.image}/>
              </BLeft>
              <BRight>
                <RHeading>
                  {opt.heading}
                </RHeading>
                <RSubHeading>
                  {opt.subHeading}
                </RSubHeading>
                <RParagraph>
                  {opt.description}
                </RParagraph>
              </BRight>
            </BRow>
          )
        })}

      </CenterPlaceholder>

      <BottomButtonPlaceholder>
        <ButtonPlaceholder>
          <PkButton style={{textAlign: 'center', background: `${SECONDARY_BUTTON_BACKGROUND} !important`}} disabled={window.isNaN(text)} onClick={() => {
            if (window.isNaN(text) == true) {
              return;
            }
            onSubmit(+text, method);
          }}>
            Пополнить
          </PkButton>
        </ButtonPlaceholder>
      </BottomButtonPlaceholder>


    </Wrapper>
  );
}

const Wrapper = styled.div`
    
`;

const BottomButtonPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 15px;
    margin-top: 30px;
`;

const CenterPlaceholder = styled.div`
    margin-top: 40px;
`;

const BRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
    background: ${props => (props.selected == true ? 'whitesmoke' : 'transparent')};
    cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
    border-radius: 3px;
    :hover{
      background: whitesmoke;
    }
    &:hover .repl_b_left{
      background: #c3c3c3; 
    }
`;


const BLeft = styled.div`
    width: 140px;
    height: 60px;
    box-sizing: border-box;
    margin-right: 20px;
    padding: 5px;
    border-radius: 3px;
    background: ${props => (props.selected == true ? `${REPLENISHMENT_PANELS_BACKGROUND} !important` : 'transparent')};
`;

const LeftImage = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${props => (props.src)});
    border-radius: 3px;
`;

const BRight = styled.div`
    flex: 1;
`;

const RHeading = styled.div`
    margin-bottom: 2px;
    line-height: 16px !important;
    font-size: 14px !important;
    padding: 0px !important;
    color: #3b3838;
    font-weight: bold;
`;

const RSubHeading = styled.div`
    font-size: 14px;
    line-height: 16px !important;
    color: gray !important;
    letter-spacing: 0;
`;

const RParagraph = styled.div`
    line-height: 12px !important;
    color: gray !important;
    font-size: 11px !important;
    margin-top: 3px !important;
`;

const Field = styled.div`
    margin-top: 17px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const FLeft = styled.div`
    width: 150px;
`;

const FRight = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ButtonPlaceholder = styled.div`
    //flex: 1;
    //padding-left: 10px;
    //box-sizing: border-box;
`;

const InputPlaceholder = styled.div`
    width: 100%;
    padding-right: 0px;
    box-sizing: border-box;
`;

const BottomPlaceholder = styled.div`
    
`;
