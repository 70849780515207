import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import courierImg from '../../../assets/images/my_frame.svg'
import cardImg from '../../../assets/images/card_coins.svg'
import protImg from '../../../assets/images/prot_del.svg'
import boxImg from '../../../assets/images/box_del.svg'

export default function FeaturesSection(props) {


    return (
        <Wrapper>
            <InnerPlaceholder>
                <Left>
                    <LeftHeading>
                        Услуги доставки
                    </LeftHeading>
                    <LeftContent>
                        <p>
                            Модуль доставки доступен в Личном Кабинете PayKeeper и позволяет быстро и удобно создавать
                            отправки по любым направлениям внутри России.
                        </p>
                        <p>
                            Выберите доставку, которая лучше всего подойдет вам по срокам пересылки и стоимости.
                        </p>
                    </LeftContent>
                </Left>
                <Right>
                    <WhiteBoxRow>
                        <WhiteBox>
                            <WhiteBoxLabel>
                                Курьерская доставка
                            </WhiteBoxLabel>
                            <BoxImg src={courierImg} />
                        </WhiteBox>
                        <WhiteBox>
                            <WhiteBoxLabel>
                                Страхование посылки
                            </WhiteBoxLabel>
                            <BoxImg src={protImg} />
                        </WhiteBox>
                    </WhiteBoxRow>
                    <WhiteBoxRow>
                        <WhiteBox>
                            <WhiteBoxLabel>
                                Доставка по всей России
                            </WhiteBoxLabel>
                            <BoxImg src={boxImg} />
                        </WhiteBox>
                        <WhiteBox>
                            <WhiteBoxLabel>
                                Наложенный платеж
                            </WhiteBoxLabel>
                            <BoxImg src={cardImg} />
                        </WhiteBox>
                    </WhiteBoxRow>

                </Right>
            </InnerPlaceholder>
        </Wrapper>
    );
}

const breakWidth = 1200;

const BoxImg = styled.img`
    height: 120px;
`;

const WhiteBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  background: white;
  margin-bottom: 40px;
  border-radius: 5px;
  padding: 20px;
  
  :first-of-type {
    margin-right: 20px;
  }

  :last-of-type {
    margin-left: 20px;
  }
`;

const WhiteBoxLabel = styled.div`
  font-weight: bold;
  color: #292828;
  font-size: 20px;
`;

const WhiteBoxRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100vw;
  margin-top: 80px;
`;

const LeftContent = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 170%;
`;

const InnerPlaceholder = styled.div`
  width: ${breakWidth}px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const Left = styled.div`
  width: 380px;
`;

const LeftHeading = styled.div`
  color: #292828;
  font-size: 36px;
  margin-bottom: 20px;
  font-weight: bold;
`;

const Right = styled.div`
  flex: 1;
  box-sizing: border-box;
  padding-left: 40px;
`;