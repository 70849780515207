import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import styled from 'styled-components';

import Select from 'react-select'
import '../../tools/style.css'
import SimpleDropdown from "../../dropdown/SimpleDropdown";

const DAY_OPTIONS = [
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00'
].map(a => ({label: a, value: a}));

const ALL_OPTIONS = [
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
].map(a => ({label: a, value: a}));

export default function TimeSelector(props) {
  let {
    time = '09:00', mode = 'day', onChange = newTime => {

    },
    canSelectPast = true
  } = props;
  let options = (mode == 'day') ? DAY_OPTIONS : ALL_OPTIONS;


  let nowHour = +moment().format('H');
  if (canSelectPast == false){
    options = options.filter(a => (+a.value.split(':')[0].replace('0', '') >= +nowHour));
  }

  let selectedOption = (time == undefined) ? undefined : options.filter(a => (a.value == time))[0];

  return (
    <Wrapper>

      <SimpleDropdown
        className={'react-select-container'}
        classNamePrefix={'react-select'}
        options={options}
        value={selectedOption}
        onChange={a => {
          onChange(a.value);
        }}
      />

    </Wrapper>
  );
}

const Wrapper = styled.div`
    
`;
