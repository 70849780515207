import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo, useLayoutEffect} from 'react';
import styled from 'styled-components';

import THEME from '../theme'

const {MODAL_BORDER_COLOR} = THEME;

const hasAnyOtherOpenModals = () => {
  try {
    let modalRegistry = window.modalRegistry || {};
    let k = 0;
    for (let key in modalRegistry) { // doing the old way because IE can have problems with Object.keys()
      k++;
    }
    return (k > 0);
  } catch (e) {
    return false;
  }
}

export default function PkModal(props) {
  const {
    name = 'ПОДГОТОВИТЬ ДОСТАВКУ', onClose = () => {

    },
    hasHeaderBorder = true,
    z = 1400,
    width = 1000,
    canClose = true,
    additionalTopOffset = 0
  } = props;

  const modalOpeningTimestamp = useRef();

  useEffect(() => {
    modalOpeningTimestamp.current = +new Date();
    let modalRegistry = window.modalRegistry || {};
    let hasAnyOther = hasAnyOtherOpenModals();
    modalRegistry[`modal_${modalOpeningTimestamp.current}`] = hasAnyOther;
    window.modalRegistry = modalRegistry;
    const originalStyle = window.getComputedStyle(document.body).overflow;
    const originalAppStyle = window.getComputedStyle(document.getElementById('app')).width;

    document.body.style.setProperty('overflow', 'hidden', 'important');

    if (hasAnyOther == false) {
      document.getElementById('app').style.width = originalAppStyle;
    }

    return () => {
      if (modalRegistry[`modal_${modalOpeningTimestamp.current}`] == false) {
        console.log('setting originalStyle');
        document.body.style.overflow = originalStyle;
        try {
          document.getElementById('app').style.width = originalAppStyle;
        } catch (e) {

        }
      }
      try {
        delete window.modalRegistry[`modal_${modalOpeningTimestamp.current}`];
      } catch (e) {
        console.log('close modal: e = ', e);
      }
    };
  }, []);

  return (
    <Wrapper style={{zIndex: z}} className={'pk_del_sa_modal_ssh'}>
      <InnerPlaceholder width={width} additionalTopOffset={additionalTopOffset}>
        <ModalContent>
          <ModalHeader hasHeaderBorder={hasHeaderBorder}>
            <ModalNameHeaderPlaceholder>
              <NamePlaceholder>
                {name}
              </NamePlaceholder>
            </ModalNameHeaderPlaceholder>
            {canClose == false ? null :
              <ModalHeaderClosePlaceholder>
                <CloseImg src={require('../assets/cross.svg')} onClick={() => {
                  onClose()
                }}/>
              </ModalHeaderClosePlaceholder>
            }
          </ModalHeader>
          {props.children == undefined ? null :
            <ModalBody>
              {props.children}
            </ModalBody>
          }
        </ModalContent>
      </InnerPlaceholder>
    </Wrapper>
  );
}

const Wrapper = styled.div`
    position: fixed;
    z-index: 1400;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    transition: opacity 0.15s linear;
    background-color: rgba(0, 0, 0, 0.5);
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    //display: flex;
    //flex-direction: column;
    //justify-content: center;
    //align-items: center;
    box-sizing: border-box;
`;

const InnerPlaceholder = styled.div`
    width: ${props => (props.width == undefined ? 1000 : props.width)}px;
    margin: 0 auto;
    margin-top: ${props => ((props.additionalTopOffset == undefined ? 0 : +props.additionalTopOffset) + 30)}px;
    margin-bottom: 30px;
    @media(max-width: ${props => (props.width == undefined ? 1000 : props.width)}px){
      width: 100%;
    }
`;

const ModalContent = styled.div`
    min-width: 285px;
    background-color: #FFFFFF;
    border: 3px solid ${MODAL_BORDER_COLOR};
    border-radius: 6px;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    background-clip: padding-box;
    padding: 24px 27px 12px;
    outline: 0;
`;

const ModalHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: ${props => (props.hasHeaderBorder != false ? '1px solid #dee2e6' : 'none')};
    margin-bottom: 20px;
`;

const ModalNameHeaderPlaceholder = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ModalHeaderClosePlaceholder = styled.div`
    
`;

const NamePlaceholder = styled.div`
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
`;

const CloseImg = styled.img`
    cursor: pointer;
    height: 21px;
    width: 21px;
    :hover{
      opacity: 0.8;
    }
`;

const ModalBody = styled.div`
    
`;
