import axios from 'axios'
import DeliveryAPI from './DeliveryAPI'

const TAXI_API_ENDPOINT = 'https://api.taxi.sabir.pro';

const ie = a => (a == undefined || a.trim() == '');

const TaxiAPI = {

    async getCoordsByAddress(address) {
        try {
            // let pld = (await axios.get(`${TAXI_API_ENDPOINT}/geocoder/address`, {
            let pld = (await axios.get(`${TAXI_API_ENDPOINT}/geocoder/address_dadata`, {
                params: {
                    address: address
                }
            })).data.result;
            console.log('getCoordsByAddress: pld = ', pld);
            return pld;
        } catch (e) {
            return undefined;
        }
    },

    async getCoordsByData(data) {
        console.log('getCoordsByData: data = ', data);
        let {code, from_street, to_street, to_building, from_building, from_code, to_code} = data;
        let c = (code == undefined) ? from_code : to_code;
        if (c == undefined || ie(to_street) || ie(from_street) || ie(from_building) || ie(to_building)) {
            return {}
        }
        let city = await DeliveryAPI.getCityByCode(c);
        let fromAddress = `${city.fullName}, ${from_street} ${from_building}`;
        let toAddress = `${city.fullName}, ${to_street} ${to_building}`;
        let fromCoords = await this.getCoordsByAddress(fromAddress);
        let toCoords = await this.getCoordsByAddress(toAddress);
        console.log('getCoordsByData: fromAddress = ', fromAddress);
        console.log('getCoordsByData: fromCoords = ', fromCoords);
        console.log('getCoordsByData: toAddress = ', toAddress);
        console.log('getCoordsByData: toCoords = ', toCoords);

        return {
            from: fromCoords,
            to: toCoords
        }
    },

    async getPrice(data) {
        let coordsData = await this.getCoordsByData(data);
        console.log('getPrice: coordsData = ', coordsData);
        if (coordsData == undefined || coordsData.from == undefined || coordsData.to == undefined) {
            return undefined;
        }
        let pld = (await axios.post(`${TAXI_API_ENDPOINT}/taxi/estimate`, {
            ...coordsData
        })).data.result;
        return {
            streetPrice: pld.streetPrice,
            price: +pld.price,
            minutes: +pld.eta,
            ...coordsData
        };
    },

    async getPriceByAddresses(data) {
        console.log('Yandex: getPriceByAddresses: data = ', data);
        try {
            let {from_building, to_building, from_street, to_street, from_code, to_code} = data;
            // let d = await this.getCoordsByData(data);
            // console.log('Yandex: coords = ', d);
            // let {from, to} = d;
            let pld = await this.getPrice(data);
            console.log('Yandex: price pld = ', pld)
            return {
                streetPrice: pld.streetPrice,
                price: +pld.price,
                minutes: +pld.eta
            };
        } catch (exc) {
            return undefined;
        }

    },

    async getPriceByFromToPositions(from, to) {
        let key = `price_from_to_${from.lat}_${from.lon}_${to.lat}_${to.lon}`;
        let val = window[key];
        if (val != undefined) {
            return val;
        }
        console.log('getPriceByFromToPositions occured! from, to = ', from, to);
        let pld = (await axios.post(`${TAXI_API_ENDPOINT}/taxi/estimate`, {
            from: from,
            to: to
        })).data.result;
        let d = {
            streetPrice: pld.streetPrice,
            price: +pld.price,
            minutes: +pld.eta
        }
        window[key] = d;
        return d;
    },

    async createOrder(d) {
        if (d.paykeeper_id == undefined) {
            window.alert('pk id is not provided!!!');
            return;
        }
        try {
            let pld = (await axios.post(`${TAXI_API_ENDPOINT}/orders`, {
                ...d
            })).data.result;
            return {order: pld};
        } catch (error) {
            console.log('exception occured while creating new order');
            if (error.response) {
                return {
                    error: error.response.data
                };
            } else {
                if (error.request) {
                    return {
                        error: {
                            message: 'Проблема с интернет-соединением.'
                        }
                    };
                }
            }
        }

    }


}
export default TaxiAPI

function sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
}
