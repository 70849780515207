import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import PvzSelectorTool from "./PvzSelectorTool";
import DeliveryAPI from "../delivery/api/DeliveryAPI";

export default function CityPvzPanel(props) {
    const {code} = props;
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedItem, setSelectedItem] = useState(undefined);

    useEffect(() => {
        setLoading(true);
        setSelectedItem(undefined);
        DeliveryAPI.getPvz(code).then(arr => {
            setItems(arr);
            setLoading(false);
        })
    }, [code]);

    if (code == undefined) {
        return null;
    }
    if (loading == true) {
        return (
            <div>
                загрузка...
            </div>
        )
    }

    if (loading == false && items.length == 0) {
        return (
            <div>
                В данном населенном пункте нет ни одного пункта выдачи заказов.
            </div>
        )
    }

    return (
        <Wrapper>
            <Content>
                <PvzSelectorTool
                    items={items}
                    selectedCode={(selectedItem == undefined) ? undefined : selectedItem.code}
                    onSelect={it => {
                        setSelectedItem(it);
                    }}
                />
            </Content>
            {selectedItem == undefined ? null :
                <BottomPlaceholder>
                    {selectedItem.fullAddress}
                </BottomPlaceholder>
            }
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-sizing: border-box;
`;

const Content = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 404px;
    border: 1px solid #B01C2E;
`;

const BottomPlaceholder = styled.div`
    height: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid rgba(0,0,0,.1);
`;
