import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import axios from 'axios'
import OrdersTable from "./OrdersTable";
import SimpleDropdown from "../../dropdown/SimpleDropdown";

import DeliveryAPI from '../../api/DeliveryAPI'

import THEME from '../../theme'
import AnalyticsAPI from "../../api/AnalyticsAPI";

const {CALENDAR_BACKGROUND_COLOR, PAGINATOR_BUTTON_COLOR} = THEME;

const PAGE_SIZE_OPTIONS = [
  {
    value: 10,
    label: '10'
  },
  {
    value: 50,
    label: '50'
  },
  {
    value: 100,
    label: '100'
  }
]

export default function PaginatedOrdersTable(props) {
  const {
    orders = [], topRightContent = null, login, password,
    onDelete = ordId => {

    }
  } = props;
  const [labelsArr, setLabelsArr] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [appliedSearchQuery, setAppliedSearchQuery] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [statusesSet, setStatusesSet] = useState(orders.reduce((st, ord) => st.add(ord.status_text), Set()));
  const [filterVisible, setFilterVisible] = useState(false);

  useEffect(() => {
    DeliveryAPI.getAllStatuses(login, password).then(arr => {
      setLabelsArr(arr);
      setStatusesSet(arr.reduce((st, a) => st.add(a.name), Set()));
    });
  }, [login, password]);

  let ordersFilteredBySearchQuery = orders.filter((ord, k) => {
    if (appliedSearchQuery == undefined || appliedSearchQuery.trim() == '') {
      return true;
    }
    return (`${ord.order_number}`.indexOf(appliedSearchQuery.toLowerCase().trim()) > -1)
  });

  let filteredOrders = ordersFilteredBySearchQuery.filter(a => (statusesSet.has(a.status_text)));
  const currentOrders = filteredOrders.slice(page * pageSize, (+page + 1) * pageSize);
  const pages = Array.from({length: Math.ceil(1.0 * filteredOrders.length / pageSize)});
  let statusLabels = orders.reduce((st, ord) => st.add(ord.status_text), Set()).toArray();
  let statusesColorsMap = orders.reduce((mp, ord) => mp.set(ord.status_text, ord.status_color), Map());

  return (
    <Wrapper>

      <VeryTopPlaceholder>
        <TopTopLeft>
          <FilterToggler onClick={() => {
            AnalyticsAPI.trackEvent(`FILTER_BUTTON_CLICK`);
            setFilterVisible(!filterVisible);
          }}>
            <FilterTogglerImage src={require('../../assets/funnel.png')}/>
            <TogglerSpan>
              Фильтр доставок
            </TogglerSpan>
          </FilterToggler>
        </TopTopLeft>
        <TopTopRight>
          {topRightContent}
        </TopTopRight>
      </VeryTopPlaceholder>

      {filterVisible == false ? null :
        <StatusFilterContentPlaceholder>
          <StatusesPlaceholder>
            {labelsArr.map((stat, k) => {
              let st = stat.name;
              let col = stat.color;
              let selected = statusesSet.has(st);
              let ords = ordersFilteredBySearchQuery.filter(a => (a.status_text == st));
              return (
                <StatusItem key={`${k}_${st}`} onClick={() => {
                  setStatusesSet((selected == true) ? statusesSet.delete(st) : statusesSet.add(st));
                  setPage(0);
                }}>
                  <StatusCheckPlaceholder>
                    <StatusCheckImage
                      src={(selected == true) ? require('../../assets/sel_check.png') : require('../../assets/empty_box.png')}/>
                  </StatusCheckPlaceholder>
                  <StatusColorBoxPlaceholder color={col}>
                    {`${st}: ${ords.length}`}
                  </StatusColorBoxPlaceholder>
                </StatusItem>
              )
            })}
          </StatusesPlaceholder>
        </StatusFilterContentPlaceholder>
      }

      {(currentOrders.length == 0 && searchQuery.trim() == '') ?
        <VoidPlaceholder>
          Нет доставок за выбранный период
        </VoidPlaceholder>
        :
        <div>
          <TopPlaceholder>
            <TopLeftPlaceholder>
          <span>
            {'Показать по: '}
          </span>
              <SelectPageSizePlaceholder>
                <SimpleDropdown
                  options={PAGE_SIZE_OPTIONS}
                  value={{value: pageSize, label: `${pageSize}`}}
                  onChange={a => {
                    setPageSize(a.value);
                    setPage(0);
                  }}
                />
              </SelectPageSizePlaceholder>
              <span>
            отправок в таблице
          </span>

            </TopLeftPlaceholder>
            <TopRightPlaceholder>
              <SearchPlaceholder>
                <div style={{marginRight: 7}}>
                  Поиск доставок за период:
                </div>
                <SearchInput placeholder={'Номер доставки'} value={searchQuery} onChange={evt => {
                  setSearchQuery(evt.target.value);
                  if (evt.target.value.trim() == '') {
                    setAppliedSearchQuery('');
                    setPage(0);
                  }
                }}/>
                <SearchButton onClick={() => {
                  setAppliedSearchQuery(searchQuery);
                  setPage(0);
                }}>
                  <SearchImg src={require('../../assets/search_icon.png')}/>
                </SearchButton>
              </SearchPlaceholder>
            </TopRightPlaceholder>
          </TopPlaceholder>

          <TablePlaceholder>
            <OrdersTable orders={currentOrders}
                         login={login} password={password}
                         onDelete={onDelete}
            />
          </TablePlaceholder>

          {pages.length < 2 ? null :
            <PagesPlaceholder>
              <PagesInnerPlaceholder>
                {pages.map((p, k) => {
                  let selected = (+k == +page);
                  return (
                    <PageItem selected={selected}
                              key={`${pageSize}_${k}`}
                              onClick={() => {
                                setPage(k);
                              }}
                    >
                      {+k + 1}
                    </PageItem>
                  )
                })}
              </PagesInnerPlaceholder>
            </PagesPlaceholder>
          }

          <BottomPlaceholder>
            {`В таблице `} <b>{filteredOrders.length}</b> {` отправки на `} <b>{pages.length}</b> страницах
          </BottomPlaceholder>
        </div>
      }

    </Wrapper>
  );
}

const Wrapper = styled.div`
    
`;

const TablePlaceholder = styled.div`
    
`;

const TopPlaceholder = styled.div`
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #212529;
`;

const TopLeftPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const SelectPageSizePlaceholder = styled.div`
    margin-left: 4px;
    margin-right: 4px;
`;

const TopRightPlaceholder = styled.div`
    
`;

const PagesPlaceholder = styled.div`
    max-width: 100%;
    overflow-x: auto;
    margin-top: 30px;
`;

const PagesInnerPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const PageItem = styled.div`
    border-radius: 3px;
    cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
    padding: 4px 10px;
    color: ${props => (props.selected == true ? PAGINATOR_BUTTON_COLOR : '#535252')};
    font-size: 16px;
    text-decoration: none;
    background-color: ${props => (props.selected == true ? 'transparent' : '#f4f4f4')};
    border-right: ${props => (props.selected ? 'none' : '1px solid #c4c4c4')};
    border-bottom: ${props => (props.selected ? 'none' : '1px solid #c4c4c4')};
    border-top: ${props => (props.selected ? 'none' : '1px solid #f4f4f4')};
    border-left: ${props => (props.selected ? 'none' : '1px solid #f4f4f4')};
    font-weight: bold;
    margin: 0 14px 10px 14px;
    box-shadow: ${props => (props.selected ? 'none' : '1px 0 1px #dfdfdf')};
    outline: none;
    text-transform: uppercase;
`;


const BottomPlaceholder = styled.div`
    font-size: 12px;
    margin-bottom: 20px;
    margin-top: 5px;
`;

const VeryTopPlaceholder = styled.div`
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const TopTopLeft = styled.div`
    
`;

const TopTopRight = styled.div`
    
`;

const FilterToggler = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const FilterTogglerImage = styled.img`
    width: 12px;
    height: 12px;
    margin-right: 7px;
`;

const TogglerSpan = styled.span`
    color: #212121;
    border-bottom: 1px dotted #212121;
    font-size: 16px;
`;

const StatusFilterContentPlaceholder = styled.div`
    min-height: 30px;
    padding-bottom: 30px;
    margin-bottom: 0px;
`;

const SearchPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #212529;
`;

const SearchInput = styled.input`
    min-width: 280px;
    width: 280px;
    font-size: 14px;
    display: block;
    height: 28px;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: 400;
    line-height: 26px;
    box-sizing: border-box;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    :focus{
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
`;

const SearchButton = styled.div`
    margin-left: 8px;
    width: 30px;
    min-width: 30px;
    height: 30px;
    padding: 0;
    text-align: center;
    vertical-align: top;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    color: #212121;
    cursor: pointer;
    border-radius: 3px;
    background-color: #f2f2f2;
    background: linear-gradient(0deg, #f2f2f2 0%, #fdfdfd 100%);
    border-top: 1px solid #f6f6f6;
    border-right: 1px solid #dfdfdf;
    border-left: 1px solid #dfdfdf;
    border-bottom: 1px solid #bababa;
    box-shadow: 1px 0 1px #dfdfdf;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover{
      outline: none;
      color: #212121;
      background-color: #e5e5e5;
      background: linear-gradient(0deg, #e5e5e5 0%, #f1f1f1 100%);
      border-top: 1px solid #f6f6f6;
      border-right: 1px solid #dfdfdf;
      border-left: 1px solid #dfdfdf;
      border-bottom: 1px solid #bababa;
    }
`;

const SearchImg = styled.img`
    width: 13px;
    height: 13px;
`;

const StatusesPlaceholder = styled.div`
    width: calc(100% + 30px);
    margin-left: -15px;
`;

const StatusItem = styled.div`
    display: inline-flex;
    box-sizing: border-box;
    vertical-align: top;
    width: 25%;
    cursor: pointer;
    margin-bottom: 15px;
    height: 30px;
    flex-direction: row;
    align-items: stretch;
    padding-right: 15px;
    :hover{
      opacity: 0.8;
    }
`;

const StatusCheckPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 40px;
`;

const StatusCheckImage = styled.img`
    width: 16px;
    height: 16px;
`;

const StatusColorBoxPlaceholder = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: ${props => (props.color)};
    color: white;
    font-weight: bold;
    font-size: 12px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
`;

const VoidPlaceholder = styled.div`
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    color: rgb(33, 37, 41);
`;
