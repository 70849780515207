import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import NewLeadPanel from "../../leads/panels/NewLeadPanel";

import boxShadowImg from '../../../assets/images/box_shadow.svg'

export default function NewLeadSection(props) {

    return (
        <Wrapper>

            <InnerPlaceholder>
                <TopPlaceholder>
                    <LeftPart>
                        <LeftHeading>
                            Подарок на первые доставки
                        </LeftHeading>
                        <SubLeftHeader>
                            <span style={{color: '#CC1626', fontWeight: 'bold'}}>Мы дарим вам 1000 рублей</span>, чтобы
                            вы могли
                            попробовать модуль
                            доставки и оценить качество и удобство нашего сервиса.
                            Чтобы начать использование доставки, примите, пожалуйста, условия договора оферты. Вкладка
                            доставка
                            сразу появится в личном кабинете PayKeeper.
                        </SubLeftHeader>
                    </LeftPart>

                    <RightPart>
                        <RightPartImg src={boxShadowImg}/>
                    </RightPart>

                </TopPlaceholder>

                <FormPlaceholder>
                    <NewLeadPanel/>
                </FormPlaceholder>

            </InnerPlaceholder>


        </Wrapper>
    );
}

const breakWidth = 1200;

const FormPlaceholder = styled.div`

`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100vw;
  margin-top: 80px;
  padding-bottom: 40px;
`;

const LeftPart = styled.div`
  flex: 1;
`;

const RightPart = styled.div`
  width: 340px;
  box-sizing: border-box;
  position: relative;
  margin-left: 40px;
`;

const RightPartImg = styled.img`
  width: 100%;
  position: absolute;
  top: -120px;
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const InnerPlaceholder = styled.div`
  width: ${breakWidth}px;
  margin: 0 auto;
  box-sizing: border-box;
`;

const LeftHeading = styled.div`
  color: #CC1626;
  font-size: 36px;
  margin-bottom: 10px;
  font-weight: bold;
`;

const SubLeftHeader = styled.div`
  font-size: 16px;
  line-height: 170%;
  color: #000000;
  margin-bottom: 40px;
`;