import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import axios from 'axios'
import Tabs from "../../ui/Tabs";
import StatusesHistoryPanel from "../panels/StatusesHistoryPanel";

import PkModal from '../../modals/PkModal'
import OrderDialogView from "./OrderDialogView";
import DeliveryHelper from '../../helpers/DeliveryHelper'
import {DELIVERY_API_ENDPOINT} from "../../api/DeliveryAPI";
import SelfLoadingPvzSpan from "../../tools/SelfLoadingPvzSpan";

export default function OrderDetailView(props) {
    const {
        createdAt, updatedAt, from_city_name, to_city_name, partner,
        weight, width, height, length, status_text, codData, to_pvz_code, from_pvz_code,
        price_for_customer, to_date, to_time_start, to_time_end, partner_order_id,
        from_name, from_street, from_building, from_email, from_phone, from_comment,
        to_name, to_street, to_building, to_email, to_phone, to_comment, id, order_type,
        onDelete = ordId => {

        }
    } = props;
    const [modalVisible, setModalVisible] = useState(false);


    return (
        <Wrapper>
            <Tabs tabs={[
                (partner.toLowerCase() == 'yandex') ? undefined :
                    {
                        label: 'Информация о доставке',
                        content: (
                            <TabItem>
                                <Row>
                                    <Field onClick={() => {
                                        setModalVisible(true);
                                    }}>
                                        <Label>
                                            Откуда:
                                        </Label>
                                        <Value>
                                            {from_city_name}
                                        </Value>
                                    </Field>
                                    <Field>
                                        <Label>
                                            Дата:
                                        </Label>
                                        <Value>
                                            {moment(createdAt).format('YYYY-MM-DD')}
                                        </Value>
                                    </Field>
                                    <Field>
                                        <Label>
                                            Цена:
                                        </Label>
                                        <Value>
                                            {`${price_for_customer} руб.`}
                                        </Value>
                                    </Field>
                                    <Field>
                                        <Label>
                                            Вес:
                                        </Label>
                                        <Value>
                                            {`${weight} кг`}
                                        </Value>
                                    </Field>
                                </Row>
                                <Row>
                                    <Field>
                                        <Label>
                                            Куда:
                                        </Label>
                                        <Value>
                                            {to_city_name}
                                        </Value>
                                    </Field>
                                    <Field>
                                        <Label>
                                            Дата забора:
                                        </Label>
                                        <Value>
                                            {to_date}
                                        </Value>
                                    </Field>
                                    <Field>
                                        <Label>
                                            Время забора:
                                        </Label>
                                        <Value>
                                            {`с ${to_time_start} по ${to_time_end}`}
                                        </Value>
                                    </Field>
                                    <Field>
                                        <Label>
                                            Габариты(см):
                                        </Label>
                                        <Value>
                                            {`${width} x ${height} x ${length}`}
                                        </Value>
                                    </Field>
                                </Row>

                                {codData == undefined ? null :
                                    <Row>
                                        <Field>
                                            <Label>
                                                Наложенный платёж:
                                            </Label>
                                            <Value>
                                                {`${DeliveryHelper.getAllExtraPaymentsObject(codData).serviceSum} руб`}
                                            </Value>
                                        </Field>

                                        <Field>
                                            <Label>
                                                Страхование:
                                            </Label>
                                            <Value>
                                                {`${DeliveryHelper.getAllExtraPaymentsObject(codData).insuranceSum} руб`}
                                            </Value>
                                        </Field>

                                        <Field>
                                            <Label>

                                            </Label>
                                            <Value>

                                            </Value>
                                        </Field>

                                        <Field>
                                            <Label>

                                            </Label>
                                            <Value>

                                            </Value>
                                        </Field>
                                    </Row>
                                }

                            </TabItem>
                        )
                    },
                {
                    label: 'Отправитель',
                    content: (
                        <TabItem>
                            <Row>
                                <Field>
                                    <Label>
                                        ФИО:
                                    </Label>
                                    <Value>
                                        {from_name}
                                    </Value>
                                </Field>
                                <Field>
                                    <Label>
                                        Телефон:
                                    </Label>
                                    <Value>
                                        {from_phone}
                                    </Value>
                                </Field>
                                <Field>
                                    <Label>
                                        Email:
                                    </Label>
                                    <Value>
                                        {from_email}
                                    </Value>
                                </Field>
                                <Field>
                                    <Label>
                                        Примечание:
                                    </Label>
                                    <Value>
                                        {from_comment}
                                    </Value>
                                </Field>
                            </Row>
                            <Row>

                                <Field>
                                    <Label>
                                        Тип доставки:
                                    </Label>
                                    <Value>
                                        {order_type.indexOf('warehouse-') == -1 ? 'от двери' : `от ПВЗ`}
                                    </Value>
                                </Field>

                                <Field>
                                    <Label>
                                        Населённый пункт:
                                    </Label>
                                    <Value>
                                        {from_city_name}
                                    </Value>
                                </Field>

                                {order_type.indexOf('warehouse-') > -1 ?
                                    <React.Fragment>
                                        <Field>
                                            <Label>
                                                {from_pvz_code == undefined ? '' : <span>Адрес ПВЗ</span>}
                                            </Label>
                                            <Value>
                                                {from_pvz_code == undefined ? '' :
                                                    <SelfLoadingPvzSpan code={from_pvz_code}/>}
                                            </Value>
                                        </Field>
                                        <Field>

                                        </Field>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <Field>
                                            <Label>
                                                Улица:
                                            </Label>
                                            <Value>
                                                {from_street}
                                            </Value>
                                        </Field>
                                        <Field>
                                            <Label>
                                                Номер дома:
                                            </Label>
                                            <Value>
                                                {from_building}
                                            </Value>
                                        </Field>
                                    </React.Fragment>
                                }
                            </Row>
                        </TabItem>
                    )
                },
                {
                    label: 'Получатель',
                    content: (
                        <TabItem>
                            <Row>
                                <Field>
                                    <Label>
                                        ФИО:
                                    </Label>
                                    <Value>
                                        {to_name}
                                    </Value>
                                </Field>
                                <Field>
                                    <Label>
                                        Телефон:
                                    </Label>
                                    <Value>
                                        {to_phone}
                                    </Value>
                                </Field>
                                <Field>
                                    <Label>
                                        Email:
                                    </Label>
                                    <Value>
                                        {to_email}
                                    </Value>
                                </Field>
                                <Field>
                                    <Label>
                                        Примечание:
                                    </Label>
                                    <Value>
                                        {to_comment}
                                    </Value>
                                </Field>
                            </Row>
                            <Row>

                                <Field>
                                    <Label>
                                        Тип доставки:
                                    </Label>
                                    <Value>
                                        {order_type.indexOf('-warehouse') == -1 ? 'до двери' : `до ПВЗ`}
                                    </Value>
                                </Field>

                                <Field>
                                    <Label>
                                        Населённый пункт:
                                    </Label>
                                    <Value>
                                        {to_city_name}
                                    </Value>
                                </Field>

                                {order_type.indexOf('-warehouse') > -1 ?
                                    <React.Fragment>
                                        <Field>
                                            <Label>
                                                {to_pvz_code == undefined ? '' : <span>Адрес ПВЗ</span>}
                                            </Label>
                                            <Value>
                                                {to_pvz_code == undefined ? '' :
                                                    <SelfLoadingPvzSpan code={to_pvz_code}/>}
                                            </Value>
                                        </Field>
                                        <Field>

                                        </Field>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <Field>
                                            <Label>
                                                Улица:
                                            </Label>
                                            <Value>
                                                {to_street}
                                            </Value>
                                        </Field>
                                        <Field>
                                            <Label>
                                                Номер дома:
                                            </Label>
                                            <Value>
                                                {to_building}
                                            </Value>
                                        </Field>
                                    </React.Fragment>
                                }
                            </Row>
                        </TabItem>
                    )
                },
                (partner.toLowerCase() == 'yandex') ? undefined :
                    {
                        label: 'Отслеживание статуса',
                        content: (
                            <TabItem>
                                <StatusesHistoryPanel {...props} id={id}/>

                                {status_text != 'Создан' ? null :
                                    <div style={{marginTop: 10}}>
                                        <DeleteSpan onClick={() => {
                                            if (window.confirm('Вы уверены?') == false) {
                                                return;
                                            }
                                            onDelete(id);
                                        }}>
                                            Удалить доставку
                                        </DeleteSpan>
                                    </div>
                                }

                            </TabItem>
                        )
                    },
                {
                    label: {'CDEK': 'Накладная', 'YANDEX': 'Отследить заказ'}[partner],
                    isExtraLink: true,
                    url: (partner == 'CDEK') ? `https://delivery.paykeeper.ru/api/order/${id}/consignment` : `https://share.taxi.delivery.paykeeper.ru/?id=${partner_order_id}`
                },
            ].filter(a => (a != undefined))}/>

            {modalVisible == false ? null :
                <PkModal name={'Заявка на доставку создана'} width={1020} onClose={() => {
                    setModalVisible(false);
                }}>

                    <OrderDialogView {...{...props}} onClose={(isPrinted) => {
                        setModalVisible(false);
                    }}/>

                </PkModal>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    padding: 10px;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
`;

const TabItem = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 20px;
    :last-of-type{
      margin-bottom: 0px;
    }
`;

const Field = styled.div`
    flex: 1;
`;

const Label = styled.div`
    font-size: 12px;
    color: #535252;
`;

const Value = styled.div`
    word-wrap: break-word;
    max-height: 80px;
    width: 100%;
    line-height: normal;
    padding-right: 20px;
`;

const DeleteSpan = styled.span`
    cursor: pointer;
    opacity: 0.8;
    color: red;
    border-bottom: 1px dashed red;
    :hover{
      opacity: 0.9;
    }
`;
