import axios from 'axios'
const DELIVERY_ANALYTICS_API_ENDPOINT = 'https://delivery.paykeeper.ru/analytics';
const ANALYTICS_INTERVAL = 3 * 1000;

const AnalyticsAPI = {

  getRegistry() {
    if (window.deliveryAnalyticsRegistry == undefined) {
      window.deliveryAnalyticsRegistry = {};
    }
    return window.deliveryAnalyticsRegistry;
  },

  trackEvent(action, details) {
    try {
      let det = (details == undefined) ? '' : details;
      if (action == undefined || window.analyticsPaykeeperId == undefined) {
        return;
      }
      let id = `ev_${Math.random() * 10000}${+new Date()}`;
      let d = {
        event: {
          details: (typeof det != 'string') ? JSON.stringify(det) : det,
          userId: window.analyticsPaykeeperId,
          action: action.toUpperCase(),
          t: +new Date()
        },
        id: id,
        t: +new Date()
      }
      this.getRegistry();
      window.deliveryAnalyticsRegistry[id] = d;
    } catch (exc) {
      console.log('can not track event!');
    }
  },

  async sendNotSentEvents() {
    this.getRegistry();
    for (let key in window.deliveryAnalyticsRegistry) {
      let d = window.deliveryAnalyticsRegistry[key];
      try {
        await axios.post(`${DELIVERY_ANALYTICS_API_ENDPOINT}/event`, {
          ...d.event
        });
        delete window.deliveryAnalyticsRegistry[d.id];
      } catch (exc) {
        console.log('can not send event ', d.event);
        console.log('exc = ', exc);
      }
    }
  },

  async runInfiniteEventsSending() {
    while (true) {
      try {
        await this.sendNotSentEvents();
      } catch (exc) {
        console.log('can not send not sent events! exc = ', exc);
      }
      await sleep(ANALYTICS_INTERVAL);
    }
  },

  init(paykeeperId) {
    try {
      window.analyticsPaykeeperId = paykeeperId;
      let d = this.getRegistry();
      this.runInfiniteEventsSending().then(inf => {

      });
    } catch (e) {
      console.log('AnalyticsAPI: init: e = ');
    }
  }

}
export default AnalyticsAPI

function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}
