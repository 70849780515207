import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import PkTemplate from "../templates/PkTemplate";
import CalculatorPanel, {gup} from "../delivery/calculator/CalculatorPanel";
import AllPvzPanel from "../pvz/AllPvzPanel";
import AllPvzMapPanel from "../pvz/AllPvzMapPanel";
import MapWithClusters from "../maps/MapWithClusters";
import NewLeadPanel from "../leads/panels/NewLeadPanel";
import RootLandingComponent from "../landing/RootLandingComponent";

export default function IndexApp(props) {
    const pkId = gup('pkId');
    const defaultEmail = gup('email');

    return <RootLandingComponent />

    return (
        <SabirWrapper>
            <SabirInner>
                <SabirHeader>

                    <LogoTopLeft>
                        <TruckLogo src={require('../../assets/images/pk_truck.svg')}/>
                        <SabirLogo src={require('../../assets/images/pak_logo.svg')}/>
                    </LogoTopLeft>


                    <DeliveryRightDiv onClick={() => {
                        // window.alert('Раздел находится в разработке');
                        window.location.href = `/#/demo`;
                    }}>
                        <PinImg src={require('../../assets/images/del_pin.svg')}/>
                        <span>
                            Демо-версия
                        </span>
                    </DeliveryRightDiv>

                </SabirHeader>

                <SabirContent>

                    <Section style={{paddingTop: 37}}>
                        <Header>
                            PayKeeper Доставка
                        </Header>

                        <P>
                            Платёжная платформа PayKeeper рада предложить Вам услуги курьерской доставки.
                        </P>

                        <P>
                            Модуль доставки встраивается в Личный Кабинет PayKeeper и позволяет быстро и удобно
                            создавать отправки по любым направлениям внутри России.
                        </P>

                        <SubHeader>
                            Калькулятор доставки
                        </SubHeader>

                        <P>
                            Мы предлагаем низкие тарифы на доставку по Москве и Московской области. Узнать цены на
                            различные направления можно с помощью нашего калькулятора.
                        </P>

                    </Section>

                    <Section>
                        <CalculatorPanel/>
                    </Section>

                    <Section>
                        <SubHeader>
                            Пункты выдачи
                        </SubHeader>

                        <P>
                            В России работают 1900 пунктов выдачи заказов.
                        </P>

                        <P>
                            Пункты выдачи работают без выходных и позволяют получателю забрать свой заказ в удобное
                            время.
                        </P>

                        <div>
                            <AllPvzPanel hasSelector={true}/>
                        </div>
                    </Section>

                    <Section>
                        <SubHeader>
                            1000 рублей в подарок на первые доставки
                        </SubHeader>

                        <P>
                            Мы дарим вам 1000 рублей, чтобы вы могли попробовать модуль доставки и оценить качество и удобство нашего сервиса.
                        </P>

                        <P>
                            Чтобы начать использование доставки, примите, пожалуйста, условия договора оферты. Вкладка доставка сразу появится в личном кабинете PayKeeper.
                        </P>

                        <div>
                            <NewLeadPanel pkId={pkId} email={defaultEmail == undefined ? '' : defaultEmail} />
                        </div>

                    </Section>

                </SabirContent>

            </SabirInner>
        </SabirWrapper>
    )
}

const DeliveryRightDiv = styled.div`
    background: #FFCC00;
    box-sizing: border-box;
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #000000;
    cursor: pointer;
    :hover{
      opacity: 0.97;
    }
`;

const PinImg = styled.img`
    width: 22px;
    margin-right: 20px;
`;

const Header = styled.div`
    font-size: 36px;
    line-height: 47px;
    font-weight: normal;
    color: #000000;
    margin-bottom: 37px;
`;

const SubHeader = styled.div`
    font-size: 24px;
    line-height: 31px;
    color: #000000;
    margin-bottom: 8px;
    margin-top: 24px;
`;

const LogoTopLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 39px;
`;

const Section = styled.div`
    margin-bottom: 40px;
    :last-of-type{
      padding-bottom: 20px;
      margin-bottom: 0px;
    }
`;

const SabirWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    background: #F4F4F4;
    font-family: PT Sans;
    overflow-x: hidden !important;
`;

const TruckLogo = styled.img`
    width: 44px;
    height: 29px;
    margin-right: 18px;
`;

const SabirLogo = styled.img`
    height: 27px;
`;

const SabirInner = styled.div`
    width: 1120px;
    margin: 0 auto;
    box-sizing: border-box;
    @meida(max-width: 1120px){
      width: 100%;
    }
    border-left: 1px solid rgba(0,0,0,0.2);
    border-right: 1px solid rgba(0,0,0,0.2);
`;

const SabirHeader = styled.div`
    height: 62px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
    background: #b01c2e;
    justify-content: space-between;
    box-sizing: border-box;
`;

const SabirContent = styled.div`
    background: white;
    box-sizing: border-box;
    padding-left: 39px;
    padding-right: 39px;
`;

const FullPageWrapper = styled.div`
    box-sizing: border-box;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden !important;
`;

const Wrapper100 = styled.div`
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    max-height: 100%;
`;

const Half = styled.div`
    flex: 1;
    box-sizing: border-box;
    height: 100vh;
`;

const Wrapper = styled.div`
    padding: 15px;
    width: 1020px;
    box-sizing: border-box;
    margin: 0 auto;
    
    
    :first-of-type{
      padding-bottom: 15px;
      border-bottom: 1px solid whitesmoke;
      margin-bottom: 15px;
    }
    @media(max-width: 1020px){
       width: 1020px;
    }
`;

const MyHeading = styled.div`
    font-size: 17pt;
    line-height: 20px;
    margin-bottom: 22px;
    color: #3b3838;
`;

const P = styled.div`
    font-size: 18px;
    line-height: 23px;
    color: #000000;
    margin-bottom: 10px;
`;

const MainWrapper = styled.div`
    
`;
