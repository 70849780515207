import React from 'react';
import logo from './logo.svg';
import './App.css';
import RouterApp from "./components/apps/RouterApp";

function App() {
    return (
        <div>
            <RouterApp/>
        </div>
    );
}

export default App;
