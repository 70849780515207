import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import axios from 'axios'

import PkModal from '../../modals/PkModal'
import AllContactsPanel from "./AllContactsPanel";

export default function ContactSelectorSpan(props) {
  const {
    login, password,
    onSelect = a => {

    }
  } = props;
  const [visible, setVisible] = useState(false);

  return (
    <Wrapper>

      <Content onClick={() => {
        setVisible(true);
      }}>
        {props.children}
      </Content>

      {visible == false ? null :
        <PkModal name={'АДРЕСНАЯ КНИГА'} width={620} zIndex={1500} onClose={() => {
          setVisible(false);
        }}>

          <Inner>
            <AllContactsPanel
              login={login} password={password}
              onContactClick={c => {
                onSelect(c);
                setVisible(false);
              }}/>
          </Inner>

        </PkModal>
      }

    </Wrapper>
  );
}

const Wrapper = styled.div`
    
`;

const Content = styled.div`
    
`;

const Inner = styled.div`
    box-sizing: border-box;
`;
