import React, {
    useState,
    useEffect,
    useCallback,
    useImperativeHandle,
    forwardRef
} from 'react';
import styled from 'styled-components';
import moment from 'moment'

import {PkInput} from "../../ui/Input";
import {PkTextarea} from "../../ui/Textarea";

import {PkButton} from "../../ui/Button";
import DeliveryAPI from "../../api/DeliveryAPI";
import TaxiAPI from "../../api/TaxiAPI";
import FromToTimeSelect from "../tools/FromToTimeSelect";
import SimpleDateSelector from "../../calendar/SimpleDateSelector";
import DeliveryHelper from "../../helpers/DeliveryHelper";
import * as EmailValidator from 'email-validator';
import SimpleLoader from "../../loaders/SimpleLoader";
import ContactSelectorSpan from "../../contacts/panels/ContactSelectorSpan";
import PvzSelectorTool from "../../pvz/tools/PvzSelectorTool";
import {MAIN_ORANGE_COLOR} from "../../ui/Colors";
import PrettyCitySelect from "../../tools/PrettyCitySelect";
import TemplateCreationSpan from "../../templates/panels/TemplateCreationSpan";
import ContactCreationSpan from "../../contacts/panels/ContactCreationSpan";
import PkModal from "../../modals/PkModal";

import THEME from '../../theme'
import CodEditorTool from "../tools/CodEditorTool";

import AnalyticsAPI from '../../api/AnalyticsAPI'
import PhoneRulesTool from "../tools/PhoneRulesTool";
import FromToMapSelectorTool from "../../taxi/tools/FromToMapSelectorTool";
import YandexCreateOrderInfoTool from "../../taxi/tools/YandexCreateOrderInfoTool";

const {DELIVERY_TYPE_BORDER_COLOR, REMOVE_ADDITIONAL_PHONE_PLUS_ICON, ADD_ADDITIONAL_PHONE_PLUS_ICON} = THEME;

const hasAnyBadNumber = arr => {
    return arr.reduce((f, a) => ((a == undefined) || (`${a}`.trim() == '') || window.isNaN(a) || f), false);
}

const hasAnyEmptyString = arr => {
    return arr.reduce((f, a) => ((a == undefined) || (`${a}`.trim() == '') || f), false);
}


export default forwardRef((props, ref) => {
    let {
        balance = 0.0,
        creating = false,
        defaultInfo = undefined,
        onReplenish = () => {

        },
        onSubmit = data => {

        },
        login, password
    } = props;
    const [dirtyMap, setDirtyMap] = useState({});
    const [formData, setFormData] = useState({
        from_code: undefined,
        to_code: undefined,

        skip_door_to_door: true,

        width: 10,
        height: 10,
        length: 20,
        weight: 0.5,

        to_time_start: '09:00',
        to_time_end: '18:00',

        from_name: '',
        from_email: '',
        from_phone: '',
        from_street: '',
        from_building: '',
        from_comment: '',

        from_porch: '',
        from_sflat: '',
        from_door_code: '',

        to_name: (defaultInfo == undefined || defaultInfo.clientid == undefined) ? '' : defaultInfo.clientid,
        to_street: '',
        to_building: '',
        to_email: (defaultInfo == undefined || defaultInfo.client_email == undefined) ? '' : defaultInfo.client_email,
        to_phone: (defaultInfo == undefined || defaultInfo.client_phone == undefined) ? '' : defaultInfo.client_phone.replace(/ /g, ''),
        to_comment: '',

        to_porch: '',
        to_sflat: '',
        to_door_code: '',

        to_pvz_code: undefined,
        from_pvz_code: undefined,

        order_type: 'door-door',


        to_date_timestamp: +new Date() + 86400000,

        from_timezone: DeliveryHelper.getCurrentTimezone(),

        fromPosition: { // for Yandex

        },

        toPosition: { // for Yandex

        }

    });

    const resetForm = (d) => {
        let a = {...formData, ...d};
        if (vendor == 'yandex') {
            if (d.from_code != a.to_code) {
                a.to_code = d.from_code;
                a.to_street = '';
                a.to_building = '';
            }
        }
        setFormData(a)
    };

    useImperativeHandle(ref, () => {
        return {
            resetForm: resetForm
        }
    });

    const [vendor, setVendor] = useState('cdek');

    const [validationModalErrorMessage, setValidationModalErrorMessage] = useState();

    const [fromAdditionalPhone, setFromAdditionalPhone] = useState('');
    const [toAdditionalPhone, setToAdditionalPhone] = useState('');
    const [fromAdditionalPhoneVisible, setFromAdditionalPhoneVisible] = useState(false);
    const [toAdditionalPhoneVisible, setToAdditionalPhoneVisible] = useState(false);

    const [codFormVisible, setCodFormVisible] = useState(false);
    const [senderSaving, setSenderSaving] = useState(false);
    const [receiverSaving, setReceiverSaving] = useState(false);
    const [senderSaved, setSenderSaved] = useState(false);
    const [receiverSaved, setReceiverSaved] = useState(false);
    const [deliveryData, setDeliveryData] = useState(undefined);
    const [searching, setSearching] = useState(false);
    const [savedModalVisible, setSavedModalVisible] = useState(false);
    const [codData, setCodData] = useState({});

    const [taxiPriceData, setTaxiPriceData] = useState();

    const [phoneQuestionModalVisible, setPhoneQuestionModalVisible] = useState(false);

    const calc = useCallback(({weight, width, length, height, from_code, to_code, to_date, from_street, to_street, from_building, to_building}) => {
        setDeliveryData(undefined);
        if (hasAnyBadNumber([weight, width, height, length, from_code, to_code])) {
            return;
        }
        setSearching(true);
        DeliveryAPI.getPrice({
            to_code, from_code, weight, width, height, length, to_date,
            paykeeper_id: `${login}_`
        }).then(pld => {
            setSearching(false);
            setDeliveryData(pld);
        })
    }, []);

    // let resData = (deliveryData == undefined) ? undefined : (formData.order_type == 'warehouse-door' ? deliveryData.d : deliveryData.w); // change logic
    let resData = (deliveryData == undefined) ? undefined : {
        'warehouse-door': deliveryData.wd,
        'warehouse-warehouse': deliveryData.ww,
        'door-door': deliveryData.d,
        'door-warehouse': deliveryData.w
    }[formData.order_type];


    let priceToDoor = undefined, priceToWarehouse = undefined;

    if (deliveryData != undefined) {
        priceToDoor = (formData.order_type.indexOf('warehouse-') > -1) ? deliveryData.wd : deliveryData.d;
        priceToWarehouse = (formData.order_type.indexOf('warehouse-') > -1) ? deliveryData.ww : deliveryData.w;
        if (priceToDoor != undefined) {
            priceToDoor = (priceToDoor.customer == undefined) ? undefined : +priceToDoor.customer.price;
        }
        if (priceToWarehouse != undefined) {
            priceToWarehouse = (priceToWarehouse.customer == undefined) ? undefined : +priceToWarehouse.customer.price;
        }
    }

    const debouncedFormData = useDebounce(formData, 1000);

    // const price = (resData == undefined || resData.customer == undefined) ? undefined : +resData.customer.price;
    const price = (formData.order_type.indexOf('-door') > -1) ? priceToDoor : priceToWarehouse;

    useEffect(() => {
        calc({...formData, to_date: moment(+formData.to_date_timestamp).format('YYYY-MM-DD')});
        // setFormData({...formData, to_pvz_code: undefined, from_pvz_code: undefined, order_type: 'door-door'}); // todo: think
    }, [formData.from_code, formData.to_code, formData.width, formData.height, formData.length, formData.weight, formData.to_date_timestamp]);


    useEffect(() => {
        setCodData({...codData, deliveryPrice: price});
    }, [price]);

    const canSubmit = (EmailValidator.validate(formData.from_email) && EmailValidator.validate(formData.to_email) && !hasAnyEmptyString([
            formData.from_name, formData.to_name,
            formData.from_email, formData.to_email,
            formData.from_phone, formData.to_phone
        ]) && (formData.order_type.indexOf('-warehouse') > -1 || !hasAnyEmptyString([formData.to_street, formData.to_building]))
        && (formData.order_type.indexOf('warehouse-') > -1 || !hasAnyEmptyString([formData.from_street, formData.from_building]))
        && !(formData.to_pvz_code == undefined && formData.order_type.indexOf('-warehouse') > -1)
        && !(formData.from_pvz_code == undefined && formData.order_type.indexOf('warehouse-') > -1));

    const canSubmitTaxi = (EmailValidator.validate(formData.from_email) && EmailValidator.validate(formData.to_email) && !hasAnyEmptyString([
            formData.from_name, formData.to_name,
            formData.from_email, formData.to_email,
            formData.from_phone, formData.to_phone,
            formData.to_street, formData.to_building,
            formData.from_street, formData.from_building
        ]) && (formData.skip_door_to_door == true || !hasAnyEmptyString([formData.from_sflat, formData.from_porch, formData.from_door_code, formData.to_sflat, formData.to_porch, formData.to_door_code]))
    );

    let error = (deliveryData == undefined || deliveryData.error == undefined) ? undefined : deliveryData.error;
    if (error == undefined && (window.isNaN(formData.weight) || +DeliveryHelper.getTotalWeight(formData.width, formData.height, formData.length, formData.weight) > 30)) {
        error = {message: 'Невозможно осуществить доставку по этому направлению при заданных условиях'};
    }
    let durationMessage = ``;
    if (resData != undefined && resData.delivery_period_min != undefined) {
        durationMessage = `${resData.delivery_period_min} - ${resData.delivery_period_max} дн.`;
        if (resData.delivery_period_min == resData.delivery_period_max) {
            durationMessage = `${resData.delivery_period_min} дн.`;
        }
    }
    let selectedPvzItem = (formData == undefined || formData.to_pvz_code == undefined || deliveryData == undefined || deliveryData.to_pvz == undefined) ? undefined : deliveryData.to_pvz.filter(a => (a.code == formData.to_pvz_code))[0];
    let selectedFromPvzItem = (formData == undefined || formData.from_pvz_code == undefined || deliveryData == undefined || deliveryData.from_pvz == undefined) ? undefined : deliveryData.from_pvz.filter(a => (a.code == formData.from_pvz_code))[0];

    let ep = DeliveryHelper.getAllExtraPaymentsObject(codData);
    // console.log('render: codData = ', codData);
    // console.log('render: ep = ', ep);
    let extraIsAdded = (+ep.insuranceSum > 0 || ep.hasService == true);
    let additionalSpanText = (extraIsAdded == false) ? 'Добавить наложенный платёж или страхование' : ``;
    let additionalSecondSpanText = '';
    if (extraIsAdded == true) {
        if (+ep.insuranceSum > 0 && +ep.serviceSum > 0) {
            additionalSpanText = `Добавлен наложенный платёж на сумму ${ep.totalGoodsSum.toFixed(2)} рублей и страхование за ${ep.insuranceSum.toFixed(2)} рублей.`;
            additionalSecondSpanText = ' СТРАХОВАНИЯ И СБОРА';
        } else {
            if (ep.hasService == true) {
                additionalSpanText = `Добавлен наложенный платёж на сумму ${ep.totalGoodsSum.toFixed(2)} рублей`;
                additionalSecondSpanText = ' СБОРА';
            }
            if (+ep.insuranceSum > 0) {
                additionalSpanText = `Добавлено страхование за ${ep.insuranceSum.toFixed(2)} рублей.`
                additionalSecondSpanText = ' СТРАХОВАНИЯ';
            }
        }
    }
    let additionalPrice = (vendor == 'yandex') ? 0 : (+ep.insuranceSum + +ep.serviceSum);
    let isNotEnough = (price == undefined || balance == undefined || (+price + +additionalPrice) > +balance);

    const isDirty = fldName => (dirtyMap[fldName] != undefined);
    const setDirty = fldName => setDirtyMap({...dirtyMap, [fldName]: 1});
    const setClean = fldName => setDirtyMap({...dirtyMap, [fldName]: undefined});
    const setAllDirty = () => {
        setDirtyMap({
            ...dirtyMap,
            from_name: 1,
            to_name: 1,
            to_email: 1,
            from_email: 1,
            from_phone: 1,
            to_phone: 1,
            from_street: 1,
            to_street: 1,
            from_building: 1,
            to_building: 1,
            weight: 1,
            width: 1,
            length: 1,
            height: 1,
        });
    };


    return (
        <Wrapper>

            <VendorTabsPlaceholder>
                <VendorTabItem selected={(vendor == 'cdek')} onClick={() => {
                    setVendor('cdek');
                }}>
                    <VendorLogo src={require('../../assets/cdek_logo_2.png')}/>
                </VendorTabItem>
                <VendorTabItem selected={(vendor == 'yandex')} onClick={() => {
                    setVendor('yandex');
                    if (formData.from_code != formData.to_code) {
                        setFormData({
                            ...formData,
                            to_building: '',
                            to_street: '',
                            to_phone: '',
                            to_name: '',
                            to_email: '',
                            toAdditionalPhone: '',
                            order_type: `door-door`,
                            from_pvz_code: undefined,
                            to_pvz_code: undefined
                        });
                    } else {
                        setFormData({
                            ...formData,
                            order_type: `door-door`,
                            from_pvz_code: undefined,
                            to_pvz_code: undefined
                        });
                    }
                }}>
                    <VendorLogo src={require('../../assets/yandex-l.png')}/>
                </VendorTabItem>
            </VendorTabsPlaceholder>

            <MainRow>
                <Half>

                    <HalfHeading>
                            <span>
                              ОТКУДА
                            </span>
                        <TopHeadingAddressBookPlaceholder>
                            <ContactSelectorSpan login={login} password={password} onSelect={c => {
                                let dd = {
                                    from_name: c.name,
                                    from_building: c.building,
                                    from_street: c.street,
                                    from_phone: c.phone,
                                    from_email: c.email,
                                    from_code: c.code,
                                    from_pvz_code: undefined
                                }
                                if (formData.from_code != undefined && c.code != undefined && c.code != formData.from_code && vendor == 'yandex') {
                                    dd.to_building = '';
                                    dd.to_street = '';
                                    dd.to_code = c.code;
                                }
                                setFormData({
                                    ...formData,
                                    ...dd
                                });
                            }}>
                                <AddressBookSpan>
                                    Адресная книга
                                </AddressBookSpan>
                            </ContactSelectorSpan>
                        </TopHeadingAddressBookPlaceholder>
                    </HalfHeading>

                </Half>
                <Half>

                    <HalfHeading>
                        <span>
                          КУДА
                        </span>
                        <TopHeadingAddressBookPlaceholder>
                            <ContactSelectorSpan login={login} password={password} onSelect={c => {
                                let dd = {
                                    to_name: c.name,
                                    to_building: c.building,
                                    to_street: c.street,
                                    to_phone: c.phone,
                                    to_email: c.email,
                                    to_code: c.code,
                                    to_pvz_code: undefined
                                }
                                if (formData.from_code != undefined && c.code != undefined && c.code != formData.from_code && vendor == 'yandex') {
                                    dd.from_building = '';
                                    dd.from_street = '';
                                    dd.from_code = c.code;
                                }
                                setFormData({
                                    ...formData,
                                    ...dd
                                });
                            }}>
                                <AddressBookSpan>
                                    Адресная книга
                                </AddressBookSpan>
                            </ContactSelectorSpan>
                        </TopHeadingAddressBookPlaceholder>
                    </HalfHeading>

                </Half>

            </MainRow>

            {vendor != 'yandex' ? null :
                <Field style={{marginBottom: 0}}>
                    <FLeft>
                        Населенный пункт
                    </FLeft>
                    <FRight>
                        <PrettyCitySelect
                            code={formData.from_code}
                            onChange={newCode => {
                                if (newCode != formData.from_code) {
                                    setFormData({
                                        ...formData,
                                        from_street: '',
                                        from_building: '',
                                        to_street: '',
                                        to_building: '',
                                        from_sflat: '',
                                        to_sflat: '',
                                        from_porch: '',
                                        to_porch: '',
                                        from_door_code: '',
                                        to_door_code: '',
                                        from_code: newCode,
                                        to_code: newCode
                                    })
                                } else {
                                    setFormData({
                                        ...formData,
                                        from_code: newCode,
                                        to_code: newCode
                                    })
                                }

                            }}/>
                    </FRight>
                </Field>
            }

            <MainRow>
                <Half>
                    {/*<HalfHeading>*/}
                    {/*<span>*/}
                    {/*ОТКУДА*/}
                    {/*</span>*/}
                    {/*<TopHeadingAddressBookPlaceholder>*/}
                    {/*<ContactSelectorSpan login={login} password={password} onSelect={c => {*/}
                    {/*setFormData({*/}
                    {/*...formData,*/}
                    {/*from_name: c.name,*/}
                    {/*from_building: c.building,*/}
                    {/*from_street: c.street,*/}
                    {/*from_phone: c.phone,*/}
                    {/*from_email: c.email,*/}
                    {/*from_code: c.code,*/}
                    {/*from_pvz_code: undefined*/}
                    {/*});*/}
                    {/*}}>*/}
                    {/*<AddressBookSpan>*/}
                    {/*Адресная книга*/}
                    {/*</AddressBookSpan>*/}
                    {/*</ContactSelectorSpan>*/}
                    {/*</TopHeadingAddressBookPlaceholder>*/}
                    {/*</HalfHeading>*/}

                    {vendor == 'yandex' ? null :
                        <Field>
                            <FLeft>
                                Населенный пункт
                            </FLeft>
                            <FRight>
                                <PrettyCitySelect code={formData.from_code} onChange={newCode => {
                                    AnalyticsAPI.trackEvent(`FROM_CITY_SELECTED`, {code: newCode});
                                    if (vendor == 'yandex' && newCode != formData.from_code) {
                                        setFormData({
                                            ...formData,
                                            from_code: newCode,
                                            order_type: `door-door`,
                                            from_pvz_code: undefined,
                                            from_street: '',
                                            from_building: '',
                                        });
                                    } else {
                                        setFormData({
                                            ...formData,
                                            from_code: newCode,
                                            order_type: `door-${formData.order_type.split('-')[1]}`,
                                            from_pvz_code: undefined
                                        });
                                    }
                                }}/>
                            </FRight>
                        </Field>
                    }

                    {formData.order_type.indexOf('warehouse-') > -1 ? null :
                        <>
                        <Field>
                            <FLeft>
                                Улица
                            </FLeft>
                            <FRight>
                                <PkInput
                                    bad={isDirty('from_street') && formData.from_street.trim().length < 1}
                                    placeholder={'Ленина'} value={formData.from_street} onChange={evt => {
                                    setFormData({...formData, from_street: evt.target.value});
                                    setDirty('from_street');
                                }}/>
                            </FRight>
                        </Field>

                        <Field>
                            <FLeft>
                                Номер дома
                            </FLeft>
                            <FRight>
                                <PkInput
                                    bad={(isDirty('from_building') && formData.from_building.trim().length < 1)}
                                    placeholder={'5 стр. 3'}
                                    value={formData.from_building} onChange={evt => {
                                    setFormData({...formData, from_building: evt.target.value});
                                    setDirty('from_building');
                                }}/>
                            </FRight>
                        </Field>


                        </>
                    }

                    {vendor != 'yandex' ? null :
                        <React.Fragment>
                            <Field>
                                <FLeft>
                                    Номер квартиры
                                </FLeft>
                                <FRight>
                                    <PkInput
                                        bad={formData.skip_door_to_door == false && formData.from_sflat.trim().length < 1}
                                        disabled={formData.skip_door_to_door}
                                        placeholder={'361'} value={formData.from_sflat} onChange={evt => {
                                        setFormData({...formData, from_sflat: evt.target.value});
                                        setDirty('from_sflat');
                                    }}/>
                                </FRight>
                            </Field>
                            <Field>
                                <FLeft>
                                    Номер подъезда
                                </FLeft>
                                <FRight>
                                    <PkInput
                                        bad={formData.skip_door_to_door == false && formData.from_porch.trim().length < 1}
                                        disabled={formData.skip_door_to_door}
                                        placeholder={'2'} value={formData.from_porch} onChange={evt => {
                                        setFormData({...formData, from_porch: evt.target.value});
                                        setDirty('from_porch');
                                    }}/>
                                </FRight>
                            </Field>
                            <Field>
                                <FLeft>
                                    Код домофона
                                </FLeft>
                                <FRight>
                                    <PkInput
                                        bad={formData.skip_door_to_door == false && formData.from_door_code.trim().length < 1}
                                        disabled={formData.skip_door_to_door}
                                        placeholder={'361к7012'} value={formData.from_door_code} onChange={evt => {
                                        setFormData({...formData, from_door_code: evt.target.value});
                                        setDirty('from_door_code');
                                    }}/>
                                </FRight>
                            </Field>
                        </React.Fragment>
                    }

                    <Field>
                        <FLeft>
                            Отправитель
                        </FLeft>
                        <FRightPhone style={{marginRight: (vendor == 'yandex') ? -6 : 0}}>
                            <PkInput placeholder={'Иванов Иван'}
                                     bad={isDirty('from_name') && formData.from_name.trim().length < 3}
                                     value={formData.from_name} onChange={evt => {
                                setFormData({...formData, from_name: evt.target.value});
                                setDirty('from_name');
                            }}/>
                            {vendor != 'yandex' ? null :
                                <img src={require('../../assets/ya_red_ball.png')} style={{height: 28, marginLeft: 6}}/>
                            }
                        </FRightPhone>
                    </Field>

                    <Field>
                        <FLeft>
                            Телефон
                        </FLeft>
                        <FRightPhone>
                            <PkInput placeholder={'+79161234567'}
                                     bad={isDirty('from_phone') && !(DeliveryHelper.enoughPhoneNumbersAtTheBeginning(formData.from_phone) && DeliveryHelper.checkIfPhoneIsGood(formData.from_phone))}
                                     value={formData.from_phone} onChange={evt => {
                                let s = evt.target.value;
                                s = DeliveryHelper.ltrim(s);
                                setDirty('from_phone');
                                if (DeliveryHelper.checkIfCanUpdatePhoneInput(s) == false) {
                                    return;
                                }
                                if (s.indexOf(',') > -1) {
                                    setFromAdditionalPhone(DeliveryHelper.ltrim(s.split(',')[1].trim()));
                                    s = s.split(',')[0].trim();
                                    setFromAdditionalPhoneVisible(true);
                                }
                                setFormData({...formData, from_phone: s});
                            }}/>
                            <PhoneHelpButtonPlaceholder>
                                <QuestionImageIcon
                                    style={{visibility: (fromAdditionalPhoneVisible ? 'hidden' : 'visible')}}
                                    src={ADD_ADDITIONAL_PHONE_PLUS_ICON}
                                    onClick={() => {
                                        AnalyticsAPI.trackEvent(`FROM_ADDITIONAL_PHONE_CLICK`);
                                        setFromAdditionalPhoneVisible(true);
                                    }}/>
                                {fromAdditionalPhoneVisible == true ? null :
                                    <AddPhoneHint>
                                        добавить ещё один номер
                                    </AddPhoneHint>
                                }
                            </PhoneHelpButtonPlaceholder>
                        </FRightPhone>
                    </Field>

                    {fromAdditionalPhoneVisible == false ? null :
                        <Field>
                            <FLeft>

                            </FLeft>
                            <FRightPhone>
                                <PkInput autoFocus={true}
                                         bad={isDirty('fromAdditionalPhone') && !DeliveryHelper.enoughPhoneNumbersAtTheBeginning(fromAdditionalPhone)}
                                         placeholder={''} value={fromAdditionalPhone} onChange={evt => {
                                    let s = evt.target.value;
                                    s = DeliveryHelper.ltrim(s);
                                    setDirty('fromAdditionalPhone');
                                    if (DeliveryHelper.checkIfCanUpdatePhoneInput(s) == false) {
                                        return;
                                    }
                                    setFromAdditionalPhone(s);
                                }}/>
                                <PhoneHelpButtonPlaceholder>
                                    <QuestionImageIcon src={REMOVE_ADDITIONAL_PHONE_PLUS_ICON} onClick={() => {
                                        setFromAdditionalPhoneVisible(false);
                                        setFromAdditionalPhone('');
                                    }}/>
                                </PhoneHelpButtonPlaceholder>
                            </FRightPhone>
                        </Field>
                    }

                    <Field>
                        <FLeft>
                            E-mail
                        </FLeft>
                        <FRight>
                            <PkInput
                                bad={isDirty('from_email') && formData.from_email.trim().length >= 0 && !EmailValidator.validate(formData.from_email)}
                                placeholder={'example@mail.ru'} value={formData.from_email} onChange={evt => {
                                setFormData({...formData, from_email: evt.target.value.trim()});
                                setDirty('from_email');
                            }}/>
                        </FRight>
                    </Field>

                    <Field>
                        <FLeft>
                            Примечание
                        </FLeft>
                        <FRight>
                            <PkTextarea minRows={2}
                                        placeholder={'Любой текст для курьера'}
                                        value={formData.from_comment}
                                        onChange={evt => {
                                            setFormData({...formData, from_comment: evt.target.value});
                                        }}
                            />
                        </FRight>
                    </Field>


                    <div style={{marginTop: 20, marginBottom: -15}}>
                        <LinkSpan saved={senderSaved} onClick={async () => {
                            if (senderSaving == true || senderSaved == true) {
                                return;
                            }
                            let d = {
                                code: formData.from_code,
                                name: formData.from_name,
                                street: formData.from_street,
                                building: formData.from_building,
                                email: formData.from_email,
                                phone: formData.from_phone,
                                comment: formData.from_comment
                            };
                            setSenderSaving(true);
                            await DeliveryAPI.setDefaultAddress('sender', d, login, password);
                            setSenderSaving(false);
                            setSavedModalVisible(true);
                            // setSenderSaved(true);
                        }}>
                            {senderSaving == true ? 'Сохранение...' : (senderSaved == true ? 'Отправитель по умолчанию сохранен' : 'Сохранить отправителем по умолчанию')}
                        </LinkSpan>
                        <br/>
                        <div style={{marginTop: 5}}>
                            <ContactCreationSpan data={{
                                code: formData.from_code,
                                name: formData.from_name,
                                street: formData.from_street,
                                building: formData.from_building,
                                email: formData.from_email,
                                phone: formData.from_phone,
                                comment: formData.from_comment
                            }} login={login} password={password}>
                                <LinkSpan>
                                    Сохранить в адресную книгу
                                </LinkSpan>
                            </ContactCreationSpan>
                        </div>
                    </div>

                </Half>
                <Half>
                    {/*<HalfHeading>*/}
                    {/*<span>*/}
                    {/*КУДА*/}
                    {/*</span>*/}
                    {/*<TopHeadingAddressBookPlaceholder>*/}
                    {/*<ContactSelectorSpan login={login} password={password} onSelect={c => {*/}
                    {/*setFormData({*/}
                    {/*...formData,*/}
                    {/*to_name: c.name,*/}
                    {/*to_building: c.building,*/}
                    {/*to_street: c.street,*/}
                    {/*to_phone: c.phone,*/}
                    {/*to_email: c.email,*/}
                    {/*to_code: c.code,*/}
                    {/*to_pvz_code: undefined*/}
                    {/*});*/}
                    {/*}}>*/}
                    {/*<AddressBookSpan>*/}
                    {/*Адресная книга*/}
                    {/*</AddressBookSpan>*/}
                    {/*</ContactSelectorSpan>*/}
                    {/*</TopHeadingAddressBookPlaceholder>*/}
                    {/*</HalfHeading>*/}

                    {vendor == 'yandex' ? null :
                        <Field>
                            <FLeft>
                                Населенный пункт
                            </FLeft>
                            <FRight>
                                <PrettyCitySelect code={formData.to_code} onChange={newCode => {
                                    AnalyticsAPI.trackEvent(`TO_CITY_SELECTED`, {code: newCode});
                                    setFormData({
                                        ...formData,
                                        to_code: newCode,
                                        order_type: `${formData.order_type.split('-')[0]}-door`,
                                        to_pvz_code: undefined
                                    })
                                }}/>
                            </FRight>
                        </Field>
                    }

                    {formData.order_type.indexOf('-warehouse') > -1 ? null :
                        <>
                        <Field>
                            <FLeft>
                                Улица
                            </FLeft>
                            <FRight>
                                <PkInput
                                    bad={isDirty('to_street') && formData.to_street.trim().length < 3}
                                    placeholder={'Театральная площадь'}
                                    value={formData.to_street} onChange={evt => {
                                    setFormData({...formData, to_street: evt.target.value});
                                    setDirty('to_street');
                                }}/>
                            </FRight>
                        </Field>
                        <Field>
                            <FLeft>
                                Номер дома
                            </FLeft>
                            <FRight>
                                <PkInput
                                    bad={isDirty('to_building') && formData.to_building.trim().length < 1}
                                    placeholder={'1/3'} value={formData.to_building} onChange={evt => {
                                    setFormData({...formData, to_building: evt.target.value});
                                    setDirty('to_building');
                                }}/>
                            </FRight>
                        </Field>
                        </>
                    }

                    {vendor != 'yandex' ? null :
                        <React.Fragment>
                            <Field>
                                <FLeft>
                                    Номер квартиры
                                </FLeft>
                                <FRight>
                                    <PkInput
                                        bad={formData.skip_door_to_door == false && formData.to_sflat.trim().length < 1}
                                        disabled={formData.skip_door_to_door}
                                        placeholder={'32'} value={formData.to_sflat} onChange={evt => {
                                        setFormData({...formData, to_sflat: evt.target.value});
                                        setDirty('to_sflat');
                                    }}/>
                                </FRight>
                            </Field>
                            <Field>
                                <FLeft>
                                    Номер подъезда
                                </FLeft>
                                <FRight>
                                    <PkInput
                                        bad={formData.skip_door_to_door == false && formData.to_porch.trim().length < 1}
                                        disabled={formData.skip_door_to_door}
                                        placeholder={'1'} value={formData.to_porch} onChange={evt => {
                                        setFormData({...formData, to_porch: evt.target.value});
                                        setDirty('to_porch');
                                    }}/>
                                </FRight>
                            </Field>
                            <Field>
                                <FLeft>
                                    Код домофона
                                </FLeft>
                                <FRight>
                                    <PkInput
                                        bad={formData.skip_door_to_door == false && formData.to_door_code.trim().length < 1}
                                        disabled={formData.skip_door_to_door}
                                        placeholder={'#1512, или напишите "без кода"'} value={formData.to_door_code}
                                        onChange={evt => {
                                            setFormData({...formData, to_door_code: evt.target.value});
                                            setDirty('to_door_code');
                                        }}/>
                                </FRight>
                            </Field>
                        </React.Fragment>
                    }

                    <Field>
                        <FLeft>
                            Получатель
                        </FLeft>
                        <FRightPhone style={{marginRight: (vendor == 'yandex') ? -6 : 0}}>
                            <PkInput placeholder={'Кузнецов Сергей'}
                                     bad={isDirty('to_name') && formData.to_name.trim().length < 3}
                                     value={formData.to_name}
                                     onChange={evt => {
                                         setFormData({...formData, to_name: evt.target.value});
                                         setDirty('to_name');
                                     }}/>
                            {vendor != 'yandex' ? null :
                                <img src={require('../../assets/ya_blue_ball.png')}
                                     style={{height: 28, marginLeft: 6}}/>
                            }
                        </FRightPhone>
                    </Field>

                    <Field>
                        <FLeft>
                            Телефон
                        </FLeft>
                        <FRightPhone>
                            <PkInput placeholder={'+79267654321'}
                                     bad={isDirty('to_phone') && !(DeliveryHelper.enoughPhoneNumbersAtTheBeginning(formData.to_phone) && DeliveryHelper.checkIfPhoneIsGood(formData.to_phone))}
                                     value={formData.to_phone} onChange={evt => {
                                let s = evt.target.value;
                                s = DeliveryHelper.ltrim(s);
                                setDirty('to_phone');
                                if (DeliveryHelper.checkIfCanUpdatePhoneInput(s) == false) {
                                    return;
                                }
                                if (s.indexOf(',') > -1) {
                                    setToAdditionalPhone(DeliveryHelper.ltrim(s.split(',')[1].trim()));
                                    s = s.split(',')[0].trim();
                                    setToAdditionalPhoneVisible(true);
                                }
                                setFormData({...formData, to_phone: s});
                            }}/>
                            <PhoneHelpButtonPlaceholder>
                                <QuestionImageIcon
                                    style={{visibility: (toAdditionalPhoneVisible ? 'hidden' : 'visible')}}
                                    src={ADD_ADDITIONAL_PHONE_PLUS_ICON} onClick={() => {
                                    AnalyticsAPI.trackEvent(`TO_ADDITIONAL_PHONE_CLICK`);
                                    setToAdditionalPhoneVisible(true);
                                }}/>
                                {toAdditionalPhoneVisible == true ? null :
                                    <AddPhoneHint>
                                        добавить ещё один номер
                                    </AddPhoneHint>
                                }
                            </PhoneHelpButtonPlaceholder>

                        </FRightPhone>
                    </Field>

                    {toAdditionalPhoneVisible == false ? null :
                        <Field>
                            <FLeft>

                            </FLeft>
                            <FRightPhone>
                                <PkInput autoFocus={true}
                                         bad={isDirty('toAdditionalPhone') && !DeliveryHelper.enoughPhoneNumbersAtTheBeginning(toAdditionalPhone)}
                                         placeholder={''} value={toAdditionalPhone} onChange={evt => {
                                    let s = evt.target.value;
                                    s = DeliveryHelper.ltrim(s);
                                    setDirty('toAdditionalPhone');
                                    if (DeliveryHelper.checkIfCanUpdatePhoneInput(s) == false) {
                                        return;
                                    }
                                    setToAdditionalPhone(s);
                                }}/>
                                <PhoneHelpButtonPlaceholder>
                                    <QuestionImageIcon src={REMOVE_ADDITIONAL_PHONE_PLUS_ICON} onClick={() => {
                                        setToAdditionalPhone('');
                                        setToAdditionalPhoneVisible(false);
                                    }}/>
                                </PhoneHelpButtonPlaceholder>
                            </FRightPhone>
                        </Field>
                    }

                    <Field>
                        <FLeft>
                            E-mail
                        </FLeft>
                        <FRight>
                            <PkInput
                                bad={isDirty('to_email') && formData.to_email.trim().length >= 0 && !EmailValidator.validate(formData.to_email)}
                                placeholder={'info@gmail.com'} value={formData.to_email} onChange={evt => {
                                setFormData({...formData, to_email: evt.target.value.trim()});
                                setDirty('to_email');
                            }}/>
                        </FRight>
                    </Field>

                    <Field>
                        <FLeft>
                            Примечание
                        </FLeft>
                        <FRight>
                            <PkTextarea minRows={2}
                                        placeholder={'Любой текст для курьера'}
                                        value={formData.to_comment}
                                        onChange={evt => {
                                            setFormData({...formData, to_comment: evt.target.value});
                                        }}
                            />
                        </FRight>
                    </Field>

                    <div style={{marginTop: 20, marginBottom: -15}}>
                        <LinkSpan saved={receiverSaved} onClick={async () => {
                            if (receiverSaving == true || receiverSaved == true) {
                                return;
                            }
                            let d = {
                                code: formData.to_code,
                                name: formData.to_name,
                                street: formData.to_street,
                                building: formData.to_building,
                                email: formData.to_email,
                                phone: formData.to_phone,
                                comment: formData.to_comment
                            };
                            setReceiverSaving(true);
                            await DeliveryAPI.setDefaultAddress('receiver', d, login, password);
                            setReceiverSaving(false);
                            setSavedModalVisible(true);
                            // setReceiverSaved(true);
                        }}>
                            {receiverSaving == true ? 'Сохранение...' : (receiverSaved == true ? 'Получатель по умолчанию сохранен' : 'Сохранить получателем по умолчанию')}
                        </LinkSpan>
                        <br/>
                        <div style={{marginTop: 5}}>
                            <ContactCreationSpan data={{
                                code: formData.to_code,
                                name: formData.to_name,
                                street: formData.to_street,
                                building: formData.to_building,
                                email: formData.to_email,
                                phone: formData.to_phone,
                                comment: formData.to_comment
                            }} login={login} password={password}>
                                <LinkSpan>
                                    Сохранить в адресную книгу
                                </LinkSpan>
                            </ContactCreationSpan>
                        </div>
                    </div>

                </Half>
            </MainRow>

            <Hr/>

            {vendor != 'yandex' ? null :
                <React.Fragment key={formData.from_code}>

                    <div>
                        <HalfHeading>
                            СТОИМОСТЬ
                        </HalfHeading>
                    </div>

                    <YandexCreateOrderInfoTool {...formData}
                                               onFromAddressChange={d => {
                                                   console.log('onFromAddressChange: d = ', d);
                                                   setFormData({...formData, ...d});
                                               }}
                                               onToAddressChange={d => {
                                                   console.log('onToAddressChange: d = ', d);
                                                   setFormData({...formData, ...d});
                                               }}
                                               onSkipDoorToDoorChange={f => {
                                                   setFormData({...formData, skip_door_to_door: f})
                                               }}
                                               onFromToPositionChange={(newFrom, newTo) => {
                                                   setFormData({
                                                       ...formData,
                                                       fromPosition: newFrom,
                                                       toPosition: newTo
                                                   })
                                               }}
                    />

                </React.Fragment>
            }

            {vendor != 'cdek' ? null :

                <React.Fragment>
                    <MainRow>
                        <Half>
                            <HalfHeading>
                                ГРУЗ
                            </HalfHeading>

                            <Field>
                                <FLeft>
                                    Дата забора
                                </FLeft>
                                <FRight>
                                    <SimpleDateSelector
                                        canSelectPastDays={false}
                                        canSelectToday={+moment().format('H') < 18}
                                        selectedTimestamp={formData.to_date_timestamp} onChange={a => {
                                        let aa = a;
                                        let new_to_time_start = formData.to_time_start;
                                        let new_to_time_end = formData.to_time_end;
                                        if (+moment(a).startOf('day') == +moment().startOf('day')) {
                                            if (+moment().format('H') >= 18) {
                                                return;
                                            }
                                            aa = +moment().add(0, 'hours').startOf('hour');
                                        } else {
                                            aa = +moment(a).add(9, 'hours').startOf('hour');
                                        }
                                        new_to_time_start = `${moment(aa).format('HH')}:00`;
                                        new_to_time_end = `18:00`;
                                        setFormData({
                                            ...formData,
                                            to_date_timestamp: aa,
                                            to_time_start: new_to_time_start,
                                            to_time_end: new_to_time_end
                                        });
                                    }}/>
                                </FRight>
                            </Field>

                            <Field key={vendor}>
                                <FLeft>
                                    Время забора
                                </FLeft>
                                <FRight>
                                    <FromToTimeSelect
                                        canSelectPast={+moment().startOf('day') != +moment(formData.to_date_timestamp).startOf('day')}
                                        fromTime={formData.to_time_start}
                                        toTime={formData.to_time_end}
                                        onChange={a => {
                                            setFormData({
                                                ...formData,
                                                to_time_start: a.fromTime,
                                                to_time_end: a.toTime
                                            });
                                        }}/>
                                </FRight>
                            </Field>

                        </Half>
                        <Half>
                            <HalfHeading style={{visibility: 'hidden'}}>
                                {'_'}
                            </HalfHeading>

                            <Field>
                                <FLeft>
                                    Вес, кг
                                </FLeft>
                                <FRight>
                                    <PkInput
                                        bad={isDirty('weight') && !DeliveryHelper.isGoodWeightParam(formData.weight)}
                                        placeholder={''} value={formData.weight} onChange={evt => {
                                        setFormData({...formData, weight: evt.target.value});
                                        setDirty('weight');
                                    }}/>
                                </FRight>
                            </Field>

                            <Field>
                                <FLeft>
                                    Габариты, см
                                </FLeft>
                                <FRight>
                                    <TripletPlaceholder>
                                        <TripletItem>
                                            <PkInput
                                                bad={isDirty('width') && (!DeliveryHelper.isGoodDimParam(formData.width) || DeliveryHelper.isBigVolume(formData))}
                                                style={{textAlign: 'center'}} placeholder={''} value={formData.width}
                                                onChange={evt => {
                                                    setFormData({...formData, width: evt.target.value});
                                                    setDirty('width');
                                                }}/>
                                        </TripletItem>
                                        <TripletDelimiter>
                                            X
                                        </TripletDelimiter>
                                        <TripletItem>
                                            <PkInput
                                                bad={isDirty('height') && (!DeliveryHelper.isGoodDimParam(formData.height) || DeliveryHelper.isBigVolume(formData))}
                                                style={{textAlign: 'center'}} placeholder={''} value={formData.height}
                                                onChange={evt => {
                                                    setFormData({...formData, height: evt.target.value});
                                                    setDirty('height');
                                                }}/>
                                        </TripletItem>
                                        <TripletDelimiter>
                                            X
                                        </TripletDelimiter>
                                        <TripletItem>
                                            <PkInput
                                                bad={isDirty('length') && (!DeliveryHelper.isGoodDimParam(formData.length) || DeliveryHelper.isBigVolume(formData))}
                                                style={{textAlign: 'center'}} placeholder={''} value={formData.length}
                                                onChange={evt => {
                                                    setFormData({...formData, length: evt.target.value});
                                                    setDirty('length');
                                                }}/>
                                        </TripletItem>
                                    </TripletPlaceholder>
                                </FRight>
                            </Field>

                        </Half>
                    </MainRow>

                    <Hr/>


                    <MainRow>
                        <Half>
                            <HalfHeading style={{marginBottom: 17, justifyContent: 'flex-start'}}>
                                СТОИМОСТЬ
                                {extraIsAdded == false ? null :
                                    <>
                                    {' С УЧЁТОМ '}
                                    <LinkSpan style={{marginLeft: 5, fontSize: 16, height: 24}} onClick={() => {
                                        setCodFormVisible(true);
                                    }}>
                                        {additionalSecondSpanText}
                                    </LinkSpan>
                                    </>
                                }
                            </HalfHeading>

                            <PricePlaceholder>

                                <PriceValuePlaceholder>
                                    {searching == true ? <span><SimpleLoader/></span> :
                                        <React.Fragment>
                                            {deliveryData == undefined || deliveryData.d == undefined || deliveryData.d.customer == undefined ? null :
                                                <DeliveryType selected={(formData.order_type.indexOf('door-') > -1)}
                                                              onClick={() => {
                                                                  setFormData({
                                                                      ...formData,
                                                                      order_type: `door-${formData.order_type.split('-')[1]}`,
                                                                      from_pvz_code: undefined
                                                                  })
                                                              }}>
                                                    <TypeDetailsSpan>
                                                        От двери
                                                    </TypeDetailsSpan>
                                                </DeliveryType>
                                            }

                                            {deliveryData == undefined || deliveryData.wd == undefined || deliveryData.wd.customer == undefined ? null :
                                                <DeliveryType
                                                    selected={(formData.order_type.indexOf('warehouse-') > -1)}
                                                    onClick={() => {
                                                        setFormData({
                                                            ...formData,
                                                            order_type: `warehouse-${formData.order_type.split('-')[1]}`
                                                        })
                                                    }}>
                                                    <TypeDetailsSpan>
                                                        От ПВЗ
                                                    </TypeDetailsSpan>
                                                </DeliveryType>
                                            }

                                        </React.Fragment>
                                    }

                                </PriceValuePlaceholder>

                            </PricePlaceholder>


                        </Half>
                        <Half>

                            <HalfHeading style={{marginBottom: 17, justifyContent: 'flex-start', visibility: 'hidden'}}>
                                {'_'}
                            </HalfHeading>

                            {error != undefined ?
                                <div>
                                    {error.message}
                                </div>
                                :
                                <div>

                                    <PricePlaceholder>

                                        <PriceValuePlaceholder>
                                            {searching == true ? <span><SimpleLoader/></span> :
                                                <React.Fragment>
                                                    {priceToDoor == undefined ? null :
                                                        <DeliveryType
                                                            selected={(formData.order_type.indexOf('-door') > -1)}
                                                            onClick={() => {
                                                                // setFormData({...formData, order_type: 'warehouse-door'})
                                                                setFormData({
                                                                    ...formData,
                                                                    order_type: `${formData.order_type.split('-')[0]}-door`,
                                                                    to_pvz_code: undefined
                                                                })
                                                            }}>
                                                            <TypeDetailsSpan>
                                                                До двери:
                                                            </TypeDetailsSpan>

                                                            <span>
                            {`${(+priceToDoor + +additionalPrice).toFixed(2)} р.`}
                          </span>
                                                        </DeliveryType>
                                                    }

                                                    {priceToWarehouse == undefined ? null :
                                                        <DeliveryType
                                                            selected={(formData.order_type.indexOf('-warehouse') > -1)}
                                                            onClick={() => {
                                                                // setFormData({...formData, order_type: 'warehouse-warehouse'})
                                                                setFormData({
                                                                    ...formData,
                                                                    order_type: `${formData.order_type.split('-')[0]}-warehouse`
                                                                })
                                                            }}>
                                                            <TypeDetailsSpan>
                                                                До ПВЗ:
                                                            </TypeDetailsSpan>
                                                            <span>
                            {`${(+priceToWarehouse + +additionalPrice).toFixed(2)} р.`}
                          </span>
                                                        </DeliveryType>
                                                    }

                                                </React.Fragment>
                                            }
                                        </PriceValuePlaceholder>

                                    </PricePlaceholder>

                                </div>
                            }


                        </Half>
                    </MainRow>

                    <MainRow>
                        <Half>
                            {durationMessage == '' ? null :
                                <DurationPlaceholder>
                                    {`Время доставки: ${durationMessage}`}
                                </DurationPlaceholder>
                            }
                            {resData == undefined || isNotEnough == false ? null :
                                <NotEnoughPlaceholder>
                                    {`Баланс вашего счёта составляет ${(+balance).toFixed(2)} рублей. Этого не достаточно для создания этой отправки. `}
                                    {'Пожалуйста, '}
                                    <ReplenishSpan onClick={() => {
                                        onReplenish();
                                    }}>
                                        {'пополните счёт.'}
                                    </ReplenishSpan>
                                </NotEnoughPlaceholder>
                            }
                        </Half>
                    </MainRow>

                    <Hr/>

                    {(resData == undefined || (formData.order_type.indexOf('warehouse-') == -1) || deliveryData.from_pvz == undefined || deliveryData.from_pvz.length == 0) ? null :
                        <>
                        <HalfHeading style={{marginBottom: 17, justifyContent: 'flex-start'}}>
                            {'ПВЗ ОТПРАВИТЕЛЯ'}
                        </HalfHeading>
                        <PvzSelectorPlaceholder>
                            <PvzSelectorTool selectedCode={formData.from_pvz_code} items={deliveryData.from_pvz}
                                             onSelect={c => {
                                                 AnalyticsAPI.trackEvent(`FROM_PVZ_SELECTED`, {code: c.code});
                                                 setFormData({...formData, from_pvz_code: c.code});
                                             }}/>
                        </PvzSelectorPlaceholder>
                        {selectedFromPvzItem != undefined || resData == undefined || (formData.order_type.indexOf('warehouse-') == -1) ? null :
                            <PvzSelectedItemPlaceholder>
                                Выберите пункт, куда вы сдадите груз
                            </PvzSelectedItemPlaceholder>
                        }
                        {selectedFromPvzItem == undefined || resData == undefined || (formData.order_type.indexOf('warehouse-') == -1) ? null :
                            <PvzSelectedItemPlaceholder>
                                <div>
                                    <b>
                                        {`ПВЗ отправителя: [${selectedFromPvzItem.code}] ${selectedFromPvzItem.name}`}
                                    </b>
                                </div>
                                <div>
                                    {selectedFromPvzItem.fullAddress}
                                </div>
                            </PvzSelectedItemPlaceholder>
                        }
                        <Hr/>
                        </>
                    }


                    {(resData == undefined || (formData.order_type.indexOf('-warehouse') == -1) || deliveryData.to_pvz == undefined || deliveryData.to_pvz.length == 0) ? null :
                        <>
                        <HalfHeading style={{marginBottom: 17, justifyContent: 'flex-start'}}>
                            {'ПВЗ ПОЛУЧАТЕЛЯ'}
                        </HalfHeading>
                        <PvzSelectorPlaceholder>
                            <PvzSelectorTool selectedCode={formData.to_pvz_code} items={deliveryData.to_pvz}
                                             onSelect={c => {
                                                 AnalyticsAPI.trackEvent(`TO_PVZ_SELECTED`, {code: c.code});
                                                 setFormData({...formData, to_pvz_code: c.code});
                                             }}/>
                        </PvzSelectorPlaceholder>
                        {selectedPvzItem != undefined || resData == undefined || (formData.order_type.indexOf('-warehouse') == -1) ? null :
                            <PvzSelectedItemPlaceholder>
                                Выберите пунт выдачи заказа
                            </PvzSelectedItemPlaceholder>
                        }
                        {selectedPvzItem == undefined || resData == undefined || (formData.order_type.indexOf('-warehouse') == -1) ? null :
                            <PvzSelectedItemPlaceholder>
                                <div>
                                    <b>
                                        {`ПВЗ получателя: [${selectedPvzItem.code}] ${selectedPvzItem.name}`}
                                    </b>
                                </div>
                                <div>
                                    {selectedPvzItem.fullAddress}
                                </div>
                            </PvzSelectedItemPlaceholder>
                        }
                        <Hr/>
                        </>
                    }

                    <div style={{marginTop: 10, marginBottom: 10}}>
                        <LinkSpan onClick={() => {
                            AnalyticsAPI.trackEvent(`COD_MODAL_OPEN`);
                            setCodFormVisible(!codFormVisible);
                        }}>
                            {additionalSpanText}
                            {/*{extraIsAdded == false ? 'Добавить наложенный платёж или страхование' : 'Отключить наложенный платеж'}*/}
                        </LinkSpan>
                    </div>

                </React.Fragment>
            }

            <BottomPlaceholder>
                {creating == true ? <div style={{height: 31}}><SimpleLoader/></div> :
                    <>
                    {vendor != 'cdek' ? null :
                        <div style={{opacity: (canSubmit == true) ? 1 : 1}}>
                            <PkButton disabled={creating || (isNotEnough == true || canSubmit == false)}
                                      onClick={() => {
                                          let vErrMsg = DeliveryHelper.getAnyInputErrorMessage(formData, vendor);
                                          console.log('vErrMsg = ', vErrMsg);
                                          setValidationModalErrorMessage(vErrMsg);
                                          setAllDirty();
                                          if (creating == true || isNotEnough == true || vErrMsg != undefined) {
                                              return;
                                          }
                                          let d = JSON.parse(JSON.stringify(formData)); //deep copy
                                          d.vendor = 'cdek';
                                          d.from_phone = DeliveryHelper.getJoinedPhonesString(d.from_phone, (fromAdditionalPhoneVisible == false ? undefined : fromAdditionalPhone));
                                          d.to_phone = DeliveryHelper.getJoinedPhonesString(d.to_phone, (toAdditionalPhoneVisible == false ? undefined : toAdditionalPhone));

                                          d.to_date = moment(+formData.to_date_timestamp).format('YYYY-MM-DD');
                                          d.price_for_customer = price;
                                          delete d.to_date_timestamp;
                                          if (codData != undefined && (codData.codEnabled == true || codData.insuranceEnabled == true || codData.deliveryPaymentEnabled == true)) {
                                              d.codGoods = (codData.goods == undefined) ? [] : codData.goods;

                                              d.cod_enabled = (codData.codEnabled == undefined) ? false : (codData.codEnabled == true && d.codGoods.length > 0);
                                              d.insurance_enabled = (codData.insuranceEnabled == undefined) ? false : (codData.insuranceEnabled == true && d.codGoods.length > 0);
                                              d.delivery_payment_enabled = (codData.deliveryPaymentEnabled == undefined) ? false : codData.deliveryPaymentEnabled;

                                              d.delivery_tax = (codData.deliveryTax == undefined) ? 'x' : codData.deliveryTax;
                                              d.delivery_price = (codData.deliveryPrice == true) ? 0 : codData.deliveryPrice;

                                              d.codData = codData; // to save additionally
                                              d.is_cod = (d.cod_enabled || d.insurance_enabled || d.delivery_payment_enabled);
                                              d.customer_additional_fee = additionalPrice;
                                          }
                                          console.log('d = ', d);
                                          // if (DeliveryHelper.isDev() == false) {
                                          onSubmit(d);
                                          // }
                                      }}>
                                {creating == true ? 'ИДЕТ СОЗДАНИЕ ДОСТАВКИ...' : 'СОЗДАТЬ ДОСТАВКУ'}
                            </PkButton>
                        </div>
                    }

                    {vendor != 'yandex' ? null :
                        <div style={{opacity: (canSubmitTaxi == true) ? 1 : 1}}>
                            <PkButton disabled={creating || (canSubmitTaxi == false)} onClick={() => {
                                let vErrMsg = DeliveryHelper.getAnyInputErrorMessage(formData, vendor);
                                console.log('vErrMsg = ', vErrMsg);
                                setValidationModalErrorMessage(vErrMsg);
                                setAllDirty();

                                if (creating == true || isNotEnough == true || vErrMsg != undefined) {
                                    return;
                                }
                                let d = JSON.parse(JSON.stringify(formData)); //deep copy
                                d = {
                                    ...d,
                                    vendor: vendor,
                                    paykeeper_id: login
                                };
                                console.log('creating yandex order: d = ', d);
                                onSubmit(d);
                            }}>
                                {creating == true ? 'ИДЕТ СОЗДАНИЕ ДОСТАВКИ...' : 'СОЗДАТЬ ДОСТАВКУ'}
                            </PkButton>
                        </div>
                    }

                    <span style={{marginLeft: 15, marginRight: 15}}>
            или
          </span>
                    <TemplateCreationSpan data={formData} login={login} password={password}>
                        <LinkSpan>
                            Сохранить шаблон
                        </LinkSpan>
                    </TemplateCreationSpan>

                    </>
                }

            </BottomPlaceholder>

            {savedModalVisible == false ? null :
                <PkModal name={'СОХРАНЕНО'} zIndex={2000} width={520} hasHeaderBorder={false} onClose={() => {
                    setSavedModalVisible(false);
                }}>

                </PkModal>
            }

            {phoneQuestionModalVisible == false ? null :
                <PkModal name={'ПРАВИЛА ВВОДА ТЕЛЕФОННОГО НОМЕРА'}
                         additionalTopOffset={120}
                         zIndex={2000} width={840}
                         hasHeaderBorder={false}
                         onClose={() => {
                             setPhoneQuestionModalVisible(false);
                         }}>
                    <PhoneRulesTool/>
                </PkModal>
            }

            {validationModalErrorMessage == undefined ? null :
                <PkModal name={'НЕ УДАЛОСЬ СОХРАНИТЬ ЗАКАЗ'}
                         additionalTopOffset={120}
                         zIndex={2000} width={520}
                         hasHeaderBorder={false}
                         onClose={() => {
                             setValidationModalErrorMessage(undefined);
                         }}>
                    <div style={{marginBottom: 30, fontSize: '16px'}}>
                        {validationModalErrorMessage}
                    </div>
                </PkModal>
            }


            {codFormVisible == false ? null :
                <PkModal name={'НАЛОЖЕННЫЙ ПЛАТЁЖ И СТРАХОВАНИЕ'}
                         zIndex={2000} width={780}
                         onClose={() => {
                             setCodFormVisible(false);
                         }}>

                    <CodEditorTool
                        {...codData}
                        onCancel={() => {
                            setCodData({deliveryPrice: price});
                            setCodFormVisible(false);
                        }}
                        onSave={d => {
                            console.log('onSave: codData = ', d);
                            setCodData(d);
                            setCodFormVisible(false);
                        }}
                    />
                </PkModal>
            }


        </Wrapper>
    );
});

function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
        () => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay]
    );
    return debouncedValue;
}

const VendorTabsPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    box-sizing: border-box;
`;

const VendorTabItem = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 20px;
    height: 40px;
    line-height: 38px;
    border-bottom: ${props => (props.selected == true ? '2px solid #ae1b01' : '2px dashed lightgrey')} ;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
    opacity: ${props => (props.selected == true ? 1 : 0.75)};
    :hover{
      opacity: 1;
    }
`;

const VendorLogo = styled.img`
    height: 30px;
`;

const Wrapper = styled.div`
    padding-bottom: 0px;
`;

const LinkSpan = styled.div`
    cursor: ${props => (props.saved == true ? 'default' : 'pointer')};
    border-bottom: ${props => (props.saved != true ? '1px dotted #425684' : 'none')};
    color: ${props => (props.saved == true ? 'inherit' : '#425684')};
    font-size: 14px;
    display: inline-block;
`;


const BottomPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
`;

const MainRow = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 20px;
    @media(max-width: 720px){
      display: block;
    }
`;

const Hr = styled.div`
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

const Half = styled.div`
    flex: 1;
    padding-right: 15px;
    padding-left: 15px;
    box-sizing: border-box;
    :first-of-type{
      padding-left: 0px;
    }
    :last-of-type{
      padding-right: 0px;
    }
`;

const HalfHeading = styled.div`
    color: #a9a9a9;
    margin-bottom: 3px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const TopHeadingAddressBookPlaceholder = styled.span`
    font-weight: normal;
    color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Field = styled.div`
    margin-top: 17px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const FLeft = styled.div`
    width: 170px;
`;

const FRight = styled.div`
    flex: 1;
`;

const FRightPhone = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
`;


const TripletPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const TripletItem = styled.div`
    flex: 1;
`;

const TripletDelimiter = styled.div`
    width: 40px;
    text-align: center;
`;

const PricePlaceholder = styled.div`
    
`;

const PriceValuePlaceholder = styled.div`
    font-size: 32px;
    margin-bottom: 10px;
    color: #696969;
`;

const NotEnoughPlaceholder = styled.div`
    
`;

const ReplenishSpan = styled.span`
    text-decoration: underline;
    cursor: pointer;
    margin-left: 4px;
    :hover{
      opacity: 0.7;
    }
`;

const AddressBookSpan = styled.span`
    font-weight: normal;
    color: #425684;
    display: inline-block;
    border-bottom: 1px dotted #425684;
    font-size: 14px;
    cursor: pointer;
   
    :hover{
      opacity: 1;
    }
`;

const DurationPlaceholder = styled.div`
    font-size: 14px;
    font-weight: normal;
`;

const DeliveryType = styled.div`
    height: 48px;
    margin-bottom: 10px;
    border-radius: 6px;
    padding: 5px;
    border: ${props => (props.selected == true ? `2px solid ${DELIVERY_TYPE_BORDER_COLOR}` : '2px dashed whitesmoke')};
    background: ${props => (props.selected == true ? 'whitesmoke' : 'white')};
    cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    :hover{
      border: 2px solid ${DELIVERY_TYPE_BORDER_COLOR};
    }
`;

const TypeDetailsSpan = styled.div`
    font-size: 24px;
`;

const PvzSelectorPlaceholder = styled.div`
    height: 400px;
    width: 100%;
`;

const PvzSelectedItemPlaceholder = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
`;

const qImSize = 18;

const QuestionImageIcon = styled.img`
    opacity: 0.7;
    cursor: pointer;
    width: ${qImSize}px;
    height: ${qImSize}px;
    :hover{
      opacity: 0.95;
    }
`;

const AddPhoneHint = styled.div`
    display: none;
    background: white;
    position: absolute;
    z-index: 10;
    bottom: 25px;
    width: 120px;
    text-align: center;
    border: 1px solid lightgrey;
    padding: 4px;
    border-radius: 3px;
`;

const PhoneHelpButtonPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    position: relative;
    width: ${qImSize}px;
    &:hover ${AddPhoneHint} {
      display: block;
    }
`;
