import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import axios from 'axios'
import SimpleMonthCalendar from "./SimpleMonthCalendar";
import {MAIN_ORANGE_COLOR} from "../ui/Colors";
import DeliveryHelper from "../helpers/DeliveryHelper";


export default function SimpleDateSelector(props) {
    const {
        selectedTimestamp,
        onChange = a => {

        },
        canSelectPastDays = true,
        canSelectToday = true
    } = props;
    const node = useRef();
    const [calendarVisible, setCalendarVisible] = useState(false);
    const handleClick = e => {
        if (node == undefined || node.current == undefined) {
            return;
        }
        if (node.current.contains(e.target)) {
            return;
        }
        setCalendarVisible(false);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    console.log('render: SimpleDateSelector: selectedTimestamp = ', new Date(selectedTimestamp));

    return (
        <Wrapper>
            <InputPlaceholder>
                <InputDiv selected={calendarVisible} onClick={() => {
                    setCalendarVisible(true);
                }}>
                    <MonthName>
                        {/*{moment(selectedTimestamp).format('D MMMM YYYY')}*/}
                        {`${DeliveryHelper.getRuDayString(selectedTimestamp)}`}
                    </MonthName>
                    <CalendarImg
                        src={calendarVisible == true ? require('../assets/calendar_orange.png') : require('../assets/calendar_grey.png')}/>
                </InputDiv>
            </InputPlaceholder>
            {calendarVisible == false ? null :
                <CalendarPlaceholder ref={node}>
                    <SimpleMonthCalendar
                        canSelectToday={canSelectToday}
                        canSelectPastDays={canSelectPastDays}
                        selectedTimestamp={selectedTimestamp} onChange={a => {
                        onChange(a);
                        setCalendarVisible(false);
                    }}/>
                </CalendarPlaceholder>
            }
        </Wrapper>
    );
}

const Wrapper = styled.div`
    position: relative;
`;


const InputPlaceholder = styled.div`
    
`;

const CalendarPlaceholder = styled.div`
    position: absolute;
    top: 32px;
    background: white;
    padding: 10px;
    border: 1px solid lightgrey;
    z-index: 1000;
`;

const InputDiv = styled.div`
    padding-left: 5px;
    height: 28px;
    line-height: 26px;
    box-sizing: border-box;
    border-radius: 3px;
    border: ${props => (props.selected == true ? `1px solid ${MAIN_ORANGE_COLOR}` : `1px solid #a9a9a9`)};
    box-shadow: ${props => (props.selected == true ? `0 0 3px #de9c10` : `none`)};
    color: ${props => (props.selected == true ? `#de9c10` : `black`)};
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: white;
    :hover{
      opacity: 0.7;
    }
`;

const MonthName = styled.div`
    
`;


const CalendarImg = styled.img`
    width: 15px;
    height: 15px;
    margin-right: 7px;
    margin-left: 25px;
    margin-top: -1px;
`;
