import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';

import CreateOrderForm from "./orders/forms/CreateOrderForm";
import PkModal from "./modals/PkModal";
import DeliveryAPI from "./api/DeliveryAPI";
import AnalyticsAPI from "./api/AnalyticsAPI";
import BalanceView from "./balance/views/BalanceView";
import DeliveryHelper from "./helpers/DeliveryHelper";
import moment from "moment";
import ReplenishmentForm from "./balance/forms/ReplenishmentForm";
import DatesRangeFilterTool from "./orders/tools/DatesRangeFilterTool";
import SimpleLoader from "./loaders/SimpleLoader";
import PaginatedOrdersTable from "./orders/lists/PaginatedOrdersTable";
import TemplateSelectorSpan from "./templates/panels/TemplateSelectorSpan";
import OrderDialogView from "./orders/views/OrderDialogView";
import BrandingHelper from './helpers/BrandingHelper'

import TaxiAPI from "./api/TaxiAPI";

import {BANK_THEME} from "./branding";

import {PrimaryPlusButton} from "./ui/Button";
import GoodsEditorTool from "./orders/tools/GoodsEditorTool";

import {PkButton} from "./ui/Button";
import GlobalDeliveryNotificationPanel from "./notifications/panels/GlobalDeliveryNotificationPanel";

const getDeliveryInfoFromLocalStorage = () => {
    try {
        let s = window.localStorage.getItem('new_delivery_order');
        if (s == undefined || s.trim() == '') {
            return;
        }
        let d = JSON.parse(s);
        let t = +d.t, now = +new Date();
        if (+now - +t > 20 * 1000) {
            return;
        }
        return d.info;
    } catch (exc) {

    }
}

export default function RootDeliveryComponent(props) {
    const {
        appSettings = {
            paykeeper_id: '191205-053-1',
            delivery_secret: 'delivery-secret-05-12-2019-6b440b9b-19ab-4ecf-9d7c-91d5b3df4b0c'
        }
    } = props;
    const formRef = useRef(null);
    const [cdekIsWorking, setCdekIsWorking] = useState(true);
    const [addModalVisible, setAddModalVisible] = useState(getDeliveryInfoFromLocalStorage() != undefined);
    const [balanceModalVisible, setBalanceModalVisible] = useState(false);
    const [balance, setBalance] = useState(undefined);
    const [orders, setOrders] = useState([]);
    const [creating, setCreating] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(undefined);
    const [freshOrder, setFreshOrder] = useState(undefined);
    const [settings, setSettings] = useState({});

    const [notEnoughMoneyDialogVisible, setNotEnoughMoneyDialogVisible] = useState(false);

    const [newOrderConsignmentPrinted, setNewOrderConsignmentPrinted] = useState(false);
    const [printingConfirmationModalOpen, setPrintingConfirmationModalOpen] = useState(false);

    const [filterData, setFilterData] = useState({
        start: moment().startOf('day') - 30 * 86400000,
        end: moment().endOf('day'),
    });

    useEffect(() => {
        DeliveryAPI.init(undefined, appSettings.paykeeper_id, appSettings.delivery_secret);
        try {
            AnalyticsAPI.init(appSettings.paykeeper_id);
        } catch (e) {
        }

        setLoading(true);
        BrandingHelper.setFavicon(BANK_THEME);

        DeliveryAPI.getBalanceAndOrders(appSettings.paykeeper_id, appSettings.delivery_secret).then(({balance, orders}) => {
            setBalance(balance);
            setOrders(orders);
            DeliveryAPI.getSettings(appSettings.paykeeper_id, appSettings.delivery_secret).then(settingsPld => {
                setSettings(settingsPld);
                setLoading(false);
            });
        })
    }, [appSettings.paykeeper_id, appSettings.delivery_secret]);

    if (appSettings.paykeeper_id == undefined || appSettings.delivery_secret == undefined) {
        return (
            <Wrapper className={'container'}>
                Загрузка доставки...
            </Wrapper>
        )
    }

    const filteredOrders = orders
        .filter(a => (+new Date(a.createdAt) > +filterData.start && (+new Date(a.createdAt) < filterData.end)))
        .map(a => ({
            ...a,
            order_number: (a.partner_order_id == undefined) ? `${appSettings.paykeeper_id.replace(/-/g, '')}${moment(a.createdAt).format('DDMMHHmmss')}`.slice(9) : a.partner_order_id,
            status: a.partner_status_description
        }));


    return (
        <Wrapper className={'card container'}>

            <div className={'card-header'} style={{paddingTop: 0, paddingBottom: 0}}>

                {/*<PrimaryPlusButton>*/}
                {/*test*/}
                {/*</PrimaryPlusButton>*/}

                <div className="d-flex align-items-center header-block">
                    <h3 style={{marginRight: 20, fontSize: 16}}>
                        ДОСТАВКА
                    </h3>
                    <PrimaryPlusButton onClick={async () => {
                        setAddModalVisible(true);
                        AnalyticsAPI.trackEvent('CREATE_DELIVERY_MODAL_OPEN');
                        let sttngs = await DeliveryAPI.getSettings(appSettings.paykeeper_id, appSettings.delivery_secret);
                        let fd = DeliveryHelper.getOrderFormDataBySettings(sttngs);
                        if (getDeliveryInfoFromLocalStorage() == undefined && fd != undefined) {
                            try {
                                formRef.current.resetForm(fd);
                            } catch (exc) {
                                console.log('can not set default data');
                            }
                        }
                        let isW = await DeliveryAPI.cdekIsWorking();
                        setCdekIsWorking(isW);
                    }}>
                        {'Создать доставку'}
                    </PrimaryPlusButton>
                </div>

                <DatesRangeFilterTool orders={orders} onChange={(start, end) => {
                    setFilterData({start, end})
                }}/>

            </div>

            <div className={'card-body'}>

                <GlobalDeliveryNotificationPanel/>

                {loading == true && orders.length == 0 ?
                    <div style={{textAlign: 'center'}}>
                        <SimpleLoader/>
                    </div>
                    :
                    <PaginatedOrdersTable
                        login={appSettings.paykeeper_id}
                        password={appSettings.delivery_secret}
                        onDelete={async orderId => {
                            AnalyticsAPI.trackEvent('DELETE_ORDER_CLICK');
                            await DeliveryAPI.deleteOrder(orderId, appSettings.paykeeper_id, appSettings.delivery_secret);
                            window.location.reload();
                        }}
                        orders={filteredOrders} topRightContent={
                        balance == undefined ? null :
                            <BalanceView
                                loading={loading}
                                balance={balance} onReplenish={() => {
                                setBalanceModalVisible(true);
                            }}/>
                    }/>
                }

                {(addModalVisible == false || cdekIsWorking == true) ? null :
                    <PkModal hasHeaderBorder={false} name={'Сервис временно недоступен'} width={420} zIndex={1500}
                             onClose={() => {
                                 setAddModalVisible(false);
                             }}>

                    </PkModal>
                }

                {(addModalVisible == false || cdekIsWorking == false) ? null :
                    <PkModal
                        name={(
                            <OrderDialogHeader>
                                <OrderDialogName>
                                    ПОДГОТОВИТЬ ДОСТАВКУ
                                </OrderDialogName>
                                <OrderDialogTemplatesSpanPlaceholder>
                                    <TemplateSelectorSpan
                                        login={appSettings.paykeeper_id}
                                        password={appSettings.delivery_secret}
                                        onSelect={d => {
                                            try {
                                                formRef.current.resetForm(d);
                                            } catch (exc) {

                                            }
                                        }}
                                    >
                                        <LinkSpan>
                                            Загрузить шаблон
                                        </LinkSpan>
                                    </TemplateSelectorSpan>
                                </OrderDialogTemplatesSpanPlaceholder>

                            </OrderDialogHeader>
                        )}
                        onClose={() => {
                            setAddModalVisible(false);
                            window.localStorage.setItem('new_delivery_order', JSON.stringify({t: 0}));
                        }}>
                        <CreateOrderForm
                            ref={formRef}
                            defaultInfo={getDeliveryInfoFromLocalStorage()}
                            login={appSettings.paykeeper_id}
                            password={appSettings.delivery_secret}
                            creating={creating}
                            balance={balance}
                            onReplenish={() => {
                                setBalanceModalVisible(true);
                            }}
                            onSubmit={async d => {
                                console.log('onSubmit: d  = ', d);
                                // if (window.location.href.indexOf('localhost:') > -1) {
                                //   return;
                                // }

                                let {price_for_customer, vendor} = d;
                                let bl = await DeliveryAPI.getBalance(appSettings.paykeeper_id, appSettings.delivery_secret);
                                if (+price_for_customer > +bl) {
                                    setNotEnoughMoneyDialogVisible(true);
                                    AnalyticsAPI.trackEvent('NOT_ENOUGH_MONEY_MODAL_OPEN');
                                    return;
                                }
                                setCreating(true);
                                setLoading(true);
                                if (vendor == 'cdek') {
                                    let pld = await DeliveryAPI.createOrder(d, appSettings.paykeeper_id, appSettings.delivery_secret);
                                    if (pld.error != undefined) {
                                        AnalyticsAPI.trackEvent('CREATE_ORDER_ERROR', {error: pld.error, data: d});
                                        setError(pld.error);
                                        setLoading(false);
                                        setCreating(false);
                                        return;
                                    }
                                    AnalyticsAPI.trackEvent('CDEK_CREATE_ORDER_SUCCESS', {data: d});
                                    let {order} = pld;
                                    let {balance, orders} = await DeliveryAPI.getBalanceAndOrders(appSettings.paykeeper_id, appSettings.delivery_secret);
                                    setCreating(false);
                                    setBalance(balance);
                                    setOrders(orders);
                                    setAddModalVisible(false);
                                    setLoading(false);
                                    setFreshOrder(order);
                                    setNewOrderConsignmentPrinted(false);
                                    return;
                                }

                                if (vendor == 'yandex') {
                                    // return;
                                    //todo: implement logic here
                                    let cityName = await DeliveryAPI.getCityNameByCode(d.from_code);
                                    AnalyticsAPI.trackEvent('YANDEX_CREATE_START', {
                                        data: d
                                    });
                                    let pld = await TaxiAPI.createOrder({...d, city: cityName});
                                    if (pld.error != undefined) {
                                        AnalyticsAPI.trackEvent('YANDEX_CREATE_ORDER_ERROR', {
                                            error: pld.error,
                                            data: d
                                        });
                                        setError(pld.error);
                                        setLoading(false);
                                        setCreating(false);
                                        return;
                                    }
                                    let {order} = pld;
                                    let {partner_order_id} = order;
                                    let redirUrl = `https://share.taxi.delivery.paykeeper.ru/?id=${partner_order_id}`;
                                    await delay(3 * 1000); // waiting for 3 sec
                                    let {balance, orders} = await DeliveryAPI.getBalanceAndOrders(appSettings.paykeeper_id, appSettings.delivery_secret);
                                    setCreating(false);
                                    setBalance(balance);
                                    setOrders(orders);
                                    setAddModalVisible(false);
                                    setLoading(false);
                                    AnalyticsAPI.trackEvent('YANDEX_CREATE_ORDER_SUCCESS', {
                                        data: {
                                            orderId: order.id
                                        }
                                    });
                                    let win1 = window.open(redirUrl, '_blank');
                                    win1.focus();
                                    return;
                                }


                            }}
                        />
                    </PkModal>
                }

                {balanceModalVisible == false ? null :
                    <PkModal z={1410}
                             width={720}
                             name={'Пополнение счёта доставки'}
                             onClose={() => {
                                 setBalanceModalVisible(false)
                             }}>
                        <InnerBalance>
                            <ReplenishmentForm onSubmit={(sum, type) => {
                                AnalyticsAPI.trackEvent('REPLENISHMENT_INITIATED');
                                if (type == 'bill') {
                                    let url = DeliveryHelper.getBillUrl(appSettings.paykeeper_id, sum);
                                    let win = window.open(url, '_blank');
                                    win.focus();
                                } else {
                                    DeliveryHelper.submitReplenishmentRequest(sum, appSettings.paykeeper_id, `${appSettings.paykeeper_id}-${moment().format('YYYY-MM-DD-HH-mm-ss')}`);
                                }
                                setBalanceModalVisible(false);
                            }}/>
                        </InnerBalance>
                    </PkModal>
                }

                {error == undefined ? null :
                    <PkModal z={1420}
                             width={420}
                             name={'Не получилось сохранить заказ'}
                             onClose={() => {
                                 setError(undefined);
                             }}>
                        <InnerError>
                            <ErrorMessage>
                                {error.message}
                            </ErrorMessage>
                        </InnerError>
                    </PkModal>
                }

            </div>


            {freshOrder == undefined ? null :
                <PkModal name={'Заявка на доставку создана'} width={1020} onClose={() => {
                    if (newOrderConsignmentPrinted == true) {
                        setFreshOrder(undefined);
                    } else {
                        setPrintingConfirmationModalOpen(true);
                        AnalyticsAPI.trackEvent('PRINTING_CONFIRMATION_MODAL_OPEN');
                    }
                }}>

                    <OrderDialogView {...{...freshOrder}}
                                     onClose={() => {
                                         if (newOrderConsignmentPrinted == true) {
                                             setFreshOrder(undefined);
                                         } else {
                                             setPrintingConfirmationModalOpen(true);
                                             AnalyticsAPI.trackEvent('PRINTING_CONFIRMATION_MODAL_OPEN');
                                         }
                                     }}
                                     onPrinted={() => {
                                         console.log('onPrinted!');
                                         setNewOrderConsignmentPrinted(true);
                                     }}
                    />

                </PkModal>
            }

            {notEnoughMoneyDialogVisible == false ? null :
                <PkModal name={'Недостаточно денег на счету'} width={620} onClose={() => {
                    setNotEnoughMoneyDialogVisible(false);
                }}>

                    <NotEnoughPlaceholder>
                        {`Баланс вашего счёта составляет ${(+balance).toFixed(2)} рублей. Этого не достаточно для создания этой отправки. `}
                        {'Пожалуйста, '}
                        <ReplenishSpan onClick={() => {
                            setBalanceModalVisible(true);
                        }}>
                            {'пополните счёт.'}
                        </ReplenishSpan>
                    </NotEnoughPlaceholder>

                </PkModal>
            }

            {printingConfirmationModalOpen == false || freshOrder == undefined ? null :
                <PkModal name={'Необходимо распечатать накладную'}
                         width={620} canClose={false}
                         additionalTopOffset={window.innerHeight * 0.2 - 30}
                >

                    <div>
                        Распечатайте, пожалуйста, накладную. Её нужно будет передать вместе с грузом курьеру.
                    </div>

                    <div style={{textAlign: 'center', padding: 20}}>
                        <PkButton style={{display: 'inline-block'}} onClick={() => {
                            openInNewTab(`https://delivery.paykeeper.ru/api/order/${freshOrder.id}/consignment`);
                            setPrintingConfirmationModalOpen(false);
                            setNewOrderConsignmentPrinted(true);
                            // setFreshOrder(undefined);
                        }}>
                            {'Распечатать'}
                        </PkButton>
                    </div>

                </PkModal>
            }

        </Wrapper>

    );
}

const Wrapper = styled.div`
    padding: 0px !important;
    margin-bottom: 20px !important;
    font-family: "PT Sans" !important;
`;

const InnerBalance = styled.div`
    min-height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const InnerError = styled.div`
    min-height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const ErrorMessage = styled.div`
    font-size: 20px;
    text-align: center;
`;

const OrderDialogHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const OrderDialogName = styled.div`
    margin-right: 50px;
`;

const OrderDialogTemplatesSpanPlaceholder = styled.div`
    font-weight: normal;
    text-transform: none;
`;

const LinkSpan = styled.div`
    cursor: pointer;
    border-bottom: 1px dotted #425684;
    color: #425684;
    font-size: 14px;
`;

const NotEnoughPlaceholder = styled.div`

`;

const ReplenishSpan = styled.span`
    text-decoration: underline;
    cursor: pointer;
    margin-left: 4px;
    :hover{
      opacity: 0.7;
    }
`;

const CreateDeliveryLink = styled.a`

`;

function openInNewTab(url) {
    let win = window.open(url, '_blank');
    win.focus();
}

function delay(time) {
    return new Promise(function (resolve) {
        setTimeout(resolve, time)
    });
}
