import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import axios from 'axios'
import DeliveryAPI from "../delivery/api/DeliveryAPI";

const gn = a => (+a < 10 ? `0${a}` : a);

const renderDetails = arr => {
    return (
        <ul>
            {arr.map((it, k) => {
                let {input, result} = it;
                let {passed} = result;
                let imSrc = (passed == true) ? require('../../assets/images/success.svg') : require('../../assets/images/error.svg')
                return (
                    <li key={k}>
                        <LiItemContent>
                            <StatusImage src={imSrc}/>
                            <LiItemCont>
                                <div>
                                    {input.name}
                                </div>
                                {passed == true ? null :
                                    <div>
                                        {result.comment}
                                    </div>
                                }
                            </LiItemCont>
                        </LiItemContent>
                    </li>
                )
            })}
        </ul>
    )
}

export default function MonitoringApp(props) {
    const [items, setItems] = useState([]);
    const [expandedSet, setExpandedSet] = useState(Set());

    useEffect(() => {
        DeliveryAPI.getCdekMonitoringItems().then(arr => {
            setItems(arr);
        });
    }, []);

    return (
        <Wrapper>

            {/*{JSON.stringify(items)}*/}

            <ListPlaceholder>

                {items.map((it, k) => {
                    let {minutes, passed} = it;
                    let h = Math.floor((+minutes) / 60);
                    let m = minutes - 60 * h;
                    let s = `${gn(h)}:${gn(m)}`;
                    let expanded = expandedSet.has(minutes);
                    let imSrc = (passed == true) ? require('../../assets/images/success.svg') : require('../../assets/images/error.svg')
                    return (
                        <Item red={!passed} key={`${k}_${it.minutes}`} onClick={() => {
                            if (expanded) {
                                setExpandedSet(expandedSet.delete(it.minutes));
                            } else {
                                setExpandedSet(expandedSet.add(it.minutes));
                            }
                        }}>
                            <Name>
                                <span>
                                    {s}
                                </span>
                                <StatusImage src={imSrc}/>
                            </Name>
                            {expanded == false ? null :
                                <ExpContent>
                                    {renderDetails(it.items)}
                                </ExpContent>
                            }
                        </Item>
                    )
                })}

            </ListPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const ListPlaceholder = styled.div`
    
`;

const Item = styled.div`
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    background: ${props => (props.red == true ? 'red' : 'white')};
    cursor: pointer;
    :hover{
      opacity: 0.7;
    }
`;

const Name = styled.div`
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const ExpContent = styled.div`
    
`;

const StatusImage = styled.img`
    width: 16px;
    height: 16px;
    margin-right: 10px;
`;

const LiItemContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const LiItemCont = styled.div`
    
`;
