import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import axios from 'axios'

export default function NumberEditor(props) {
  const {
    min = 1, value = 1, onChange = nv => {

    }
  } = props;

  return (
    <Wrapper>
      <ArrowPlaceholder>
        <ArrowImage src={require('../../assets/negative-sign-inside-circle.svg')} onClick={() => {
          if (+value - 1 < min) {
            return;
          }
          onChange(+value - 1)
        }}/>
      </ArrowPlaceholder>
      <ContentPlaceholder>
        {value}
      </ContentPlaceholder>
      <ArrowPlaceholder>
        <ArrowImage src={require('../../assets/add-circular-interface-button.svg')} onClick={() => {
          onChange(+value + 1)
        }}/>
      </ArrowPlaceholder>
    </Wrapper>
  );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const ArrowPlaceholder = styled.div`
    margin-left: 3px;
    margin-right: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ContentPlaceholder = styled.div`
    font-size: 16px;
    width: 30px;
    text-align: center;
`;

const ArrowImage = styled.img`
    width: 16px;
    height: 16px;
    opacity: 0.5;
    cursor: pointer;
    :hover{
      opacity: 1;
    }
`;
