import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import PkTemplate from "../templates/PkTemplate";

export default function BackApp(props) {

    return (
        <PkTemplate active={'back'}>
            <Wrapper>
                <H1>
                    Правила оплаты
                </H1>
                <p>
                    К оплате принимаются платежные карты: VISA Inc, MasterCard WorldWide, МИР.
                </p>
                <p>
                    Для оплаты товара банковской картой при оформлении заказа в интернет-магазине выберите способ
                    оплаты: банковской картой.
                </p>
                <p>
                    При оплате заказа банковской картой, обработка платежа происходит на авторизационной странице банка,
                    где Вам необходимо ввести данные Вашей банковской карты:
                </p>
                <ol>
                    <li>
                        тип карты
                    </li>
                    <li>
                        номер карты,
                    </li>
                    <li>
                        срок действия карты (указан на лицевой стороне карты)
                    </li>
                    <li>
                        Имя держателя карты (латинскими буквами, точно также как указано на карте)
                    </li>
                    <li>
                        CVC2/CVV2 код
                    </li>
                </ol>
                <p>
                    Если Ваша карта подключена к услуге 3D-Secure, Вы будете автоматически переадресованы на страницу
                    банка, выпустившего карту, для прохождения процедуры аутентификации. Информацию о правилах и методах
                    дополнительной идентификации уточняйте в Банке, выдавшем Вам банковскую карту.
                </p>
                <p>
                    Безопасность обработки интернет-платежей через платежный шлюз банка гарантирована международным
                    сертификатом безопасности PCI DSS. Передача информации происходит с применением технологии
                    шифрования TLS. Эта информация недоступна посторонним лицам.
                </p>
                <p>
                    <b>
                        Советы и рекомендации по необходимым мерам безопасности проведения платежей с использованием
                        банковской карты:
                    </b>
                </p>

                <ol>
                    <li>
                        берегите свои пластиковые карты так же, как бережете наличные деньги. Не забывайте их в машине,
                        ресторане, магазине и т.д.
                    </li>
                    <li>
                        никогда не передавайте полный номер своей кредитной карты по телефону каким-либо лицам или
                        компаниям
                    </li>
                    <li>
                        всегда имейте под рукой номер телефона для экстренной связи с банком, выпустившим вашу карту, и
                        в случае ее утраты немедленно свяжитесь с банком
                    </li>
                    <li>
                        вводите реквизиты карты только при совершении покупки. Никогда не указывайте их по каким-то
                        другим причинам.
                    </li>
                </ol>

                <p>
                    В течение 15 минут после оплаты Вы получите на e-mail письмо с подтверждением об оплате.
                </p>

                <H1>
                    Отказ от услуги
                </H1>
                <p>
                    Право потребителя на расторжение договора об оказании услуги регламентируется статьей 32
                    федерального закона «О защите прав потребителей»
                </p>
                <ul>
                    <li>
                        Потребитель вправе расторгнуть договор об оказании услуги в любое время, уплатив исполнителю
                        часть цены пропорционально части оказанной услуги до получения извещения о расторжении
                        указанного договора и возместив исполнителю расходы, произведенные им до этого момента в целях
                        исполнения договора, если они не входят в указанную часть цены услуги;
                    </li>
                    <li>
                        Потребитель при обнаружении недостатков оказанной услуги вправе по своему выбору потребовать:
                        <ul>
                            <li>
                                безвозмездного устранения недостатков;
                            </li>
                            <li>
                                соответствующего уменьшения цены;
                            </li>
                            <li>
                                возмещения понесенных им расходов по устранению недостатков своими силами или третьими
                                лицами;
                            </li>
                        </ul>

                    </li>
                    <li>
                        Потребитель вправе предъявлять требования, связанные с недостатками оказанной услуги, если они
                        обнаружены в течение гарантийного срока, а при его отсутствии в разумный срок, в пределах двух
                        лет со дня принятия оказанной услуги;
                    </li>
                    <li>
                        Исполнитель отвечает за недостатки услуги, на которую не установлен гарантийный срок, если
                        потребитель докажет, что они возникли до ее принятия им или по причинам, возникшим до этого
                        момента;
                    </li>


                </ul>

            </Wrapper>
        </PkTemplate>
    );
}

const Wrapper = styled.div`
    box-sizing: border-box;
    padding: 25px;
`;

const MainHeading = styled.div`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 40px;  
`;

const Box = styled.div`
    
`;

const H1 = styled.div`
    padding-bottom: 15px;
    border-bottom: 1px solid lightgrey;
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: bold;
    opacity: 0.85;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
`;

const Left = styled.div`
    width: 240px;
    font-size: 14px;
`;

const Right = styled.div`
    flex: 1;
    font-size: 14px;
`;

const Section = styled.div`
    margin-bottom: 40px;
`;
