import styled from 'styled-components';
import {BROWN, DARK_SIDE, LIGHT_BROWN, MAIN_ORANGE_COLOR} from "./Colors";

const DEFAULT_BACKGROUND = LIGHT_BROWN;


const DEFAULT_INPUT_HEIGHT = 28;
const DEFAULT_INPUT_FONT_SIZE = 14;

export const Input = styled.input`
    width: 100%;
    box-sizing: border-box;
    height: ${DEFAULT_INPUT_HEIGHT}px;
    line-height: ${DEFAULT_INPUT_HEIGHT}px;
    border: 1px solid whitesmoke;
    border-radius: 4px;
    outline: none;
    padding-left: 3px;
    font-size: ${DEFAULT_INPUT_FONT_SIZE}px;
    :focus{
      border-color: ${MAIN_ORANGE_COLOR};
    }
`;

export const PkInput = styled.input`
    font-size: 14px;
    height: 28px;
    padding: 7px 5px;
    line-height: 1.5;
    border: 1px solid ${props => (props.bad == true ? 'red' : '#a9a9a9')};
    border-radius: 3px;
    outline: none;
    width: 100%;
    box-sizing: border-box;
    :focus{
       color: #495057;
       background-color: #fff;
       border-color: ${props => (props.bad == true ? 'red' : '#80bdff')};
       outline: 0;
       box-shadow: 0 0 0 0.2rem  ${props => (props.bad == true ? 'rgba(255, 0, 0, 0.25)' : 'rgba(0, 123, 255, 0.25)')};
    }
`;
