import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import PkTemplate from "../templates/PkTemplate";

export default function CreditsApp(props) {


    return (
        <PkTemplate active={'credits'}>
            <Wrapper>

                <MainHeading>
                    Реквизиты компании
                </MainHeading>

                <Box>

                    <Section>
                        <H1>
                            Наименование организации:
                        </H1>

                        <Row>
                            <Left>
                                Полное:
                            </Left>
                            <Right>
                                Общество с ограниченной ответственностью «ПЭЙКИПЕР»
                            </Right>
                        </Row>

                        <Row>
                            <Left>
                                Сокращенное:
                            </Left>
                            <Right>
                                ООО «ПЭЙКИПЕР»
                            </Right>
                        </Row>
                    </Section>

                    <Section>

                        <H1>
                            Наименование организации на английском языке:
                        </H1>

                        <Row>
                            <Left>
                                Полное:
                            </Left>
                            <Right>
                                PayKeeper, Limited
                            </Right>
                        </Row>

                        <Row>
                            <Left>
                                Сокращенное:
                            </Left>
                            <Right>
                                PayKeeper, Ltd.
                            </Right>
                        </Row>
                    </Section>

                    <Section>
                        <H1>
                            Реквизиты:
                        </H1>

                        <Row>
                            <Left>
                                Юридический адрес:
                            </Left>
                            <Right>
                                141701, МО, г. Долгопрудный, Лихачевский проезд 4, стр 1, п. 512, офис 1
                            </Right>
                        </Row>

                        <Row>
                            <Left>
                                Физический адрес:
                            </Left>
                            <Right>
                                119019, г. Москва, улица Новый Арбат, д. 21
                            </Right>
                        </Row>

                        <Row>
                            <Left>
                                Почтовый адрес:
                            </Left>
                            <Right>
                                119019, г. Москва, улица Новый Арбат, д. 21, п/я 33
                            </Right>
                        </Row>

                        <Row>
                            <Left>
                                Телефон/Факс:
                            </Left>
                            <Right>
                                +7 (495) 98-48-750
                            </Right>
                        </Row>


                        <Row>
                            <Left>
                                ИНН:
                            </Left>
                            <Right>
                                5008053934
                            </Right>
                        </Row>


                        <Row>
                            <Left>
                                КПП:
                            </Left>
                            <Right>
                                500801001
                            </Right>
                        </Row>


                        <Row>
                            <Left>
                                Корреспондентский счет:
                            </Left>
                            <Right>
                                30101810145250000411
                            </Right>
                        </Row>


                        <Row>
                            <Left>
                                Расчетный счет:
                            </Left>
                            <Right>
                                40702810701540000001
                            </Right>
                        </Row>


                        <Row>
                            <Left>
                                БИК:
                            </Left>
                            <Right>
                                044525411
                            </Right>
                        </Row>

                        <Row>
                            <Left>
                                Наименование банка:
                            </Left>
                            <Right>
                                ФИЛИАЛ "ЦЕНТРАЛЬНЫЙ" БАНКА ВТБ (ПАО), г. МОСКВА
                            </Right>
                        </Row>

                          <Row>
                            <Left>
                                Основной государственный регистрационный номер
                            </Left>
                            <Right>
                                1105047004622
                            </Right>
                        </Row>

                           <Row>
                            <Left>
                                ОКПО, ОКВЭД
                            </Left>
                            <Right>
                                66124910,   72.19
                            </Right>
                        </Row>




                    </Section>

                    <Section>
                        <H1>
                            Исполнительный орган:
                        </H1>

                        <Row>
                            <Left>
                                Наименование
                            </Left>
                            <Right>
                                Генеральный директор
                            </Right>
                        </Row>

                        <Row>
                            <Left>
                                Генеральный директор
                            </Left>
                            <Right>
                                Горбач Сергей Николаевич
                            </Right>
                        </Row>


                        <Row>
                            <Left>
                                Действующего на основании устава
                            </Left>
                            <Right>
                                Устава
                            </Right>
                        </Row>


                    </Section>


                </Box>

            </Wrapper>
        </PkTemplate>
    );
}

const Wrapper = styled.div`
    box-sizing: border-box;
    padding: 25px;
`;

const MainHeading = styled.div`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 40px;  
`;

const Box = styled.div`
    
`;

const H1 = styled.div`
    padding-bottom: 15px;
    border-bottom: 1px solid lightgrey;
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: bold;
    opacity: 0.85;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
`;

const Left = styled.div`
    width: 240px;
    font-size: 14px;
`;

const Right = styled.div`
    flex: 1;
    font-size: 14px;
`;

const Section = styled.div`
    margin-bottom: 40px;
`;
