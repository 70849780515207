import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import AnalyticsAPI from "../../../api/AnalyticsAPI";
import {PkInput} from "../../../ui/Input";
import SimpleDropdown from "../../../dropdown/SimpleDropdown";

import {PkButton} from "../../../ui/Button";

const TAX_OPTIONS = [
  {
    value: 'x',
    label: 'НДС не облагается'
  },
  {
    value: '20',
    label: 'НДС 20%'
  }
];

export default function UpdateGoodForm(props) {
  const {
    onSave = d => {

    },
    onCancel = () => {

    }
  } = props;

  const [shouldHighlightErrors, setShouldHighlightErrors] = useState(false);

  const [quantity, setQuantity] = useState(props.quantity == undefined ? 1 : props.quantity);
  const [name, setName] = useState(props.name == undefined ? '' : props.name);
  const [pricePerOne, setPricePerOne] = useState(props.pricePerOne == undefined ? 0 : props.pricePerOne);
  const [weightPerOne, setWeightPerOne] = useState(props.weightPerOne == undefined ? 0 : props.weightPerOne);
  const [tax, setTax] = useState(props.tax == undefined ? 'x' : props.tax);

  let newFormTotalPrice = (window.isNaN(quantity) || window.isNaN(pricePerOne)) ? 0 : ((+quantity) * (+pricePerOne));

  let canSubmit = (name.trim() != '' && !window.isNaN(quantity) && !window.isNaN(weightPerOne) && !window.isNaN(pricePerOne) && (+pricePerOne > 0) && (+weightPerOne > 0));

  let isBadQuantity = shouldHighlightErrors && (window.isNaN(quantity) || +quantity <= 0);
  let isBadWeightPerOne = shouldHighlightErrors && (window.isNaN(weightPerOne) || +weightPerOne <= 0);
  let isBadPricePerOne = shouldHighlightErrors && (window.isNaN(pricePerOne) || +pricePerOne <= 0);
  let isBadName = shouldHighlightErrors && (name == undefined || name.trim().length == 0);

  return (
    <Wrapper>


      <Row>
        <Label>
          Наименование
        </Label>
        <FCont>
          <PkInput value={name}
                   placeholder={'Стол деревянный'}
                   bad={isBadName}
                   onChange={evt => {
                     setName(evt.target.value);
                   }}/>
          {isBadName &&
          <ErrorPlaceholder style={{left: 355, top: 0, width: 300}}>
            Необходимо ввести наименование товара.
          </ErrorPlaceholder>}
        </FCont>
      </Row>

      <Row>
        <Label>
          Цена и количество
        </Label>
        <FCont>
          <RowValsField>

            <FirstRowValItem>
              <PkInput bad={isBadPricePerOne} style={{width: 92}} placeholder={''} value={pricePerOne}
                       onChange={evt => {
                         setPricePerOne(evt.target.value);
                       }}/>
              <ItemRubPlaceholder>
                {'₽'}
              </ItemRubPlaceholder>
              {(isBadPricePerOne || isBadQuantity) &&
              <ErrorPlaceholder style={{left: 220, width: 290, top: 0}}>
                Необходимо ввести положительное число.
              </ErrorPlaceholder>}
            </FirstRowValItem>

            <RowValItem>
              X
            </RowValItem>

            <RowValItem>
              <PkInput style={{width: 45}}
                       bad={isBadQuantity}
                       placeholder={''} value={quantity} onChange={evt => {
                setQuantity(evt.target.value);
              }}/>
              {/*{isBadQuantity &&*/}
              {/*<ErrorPlaceholder>*/}
              {/*Необходимо ввести положительное число.*/}
              {/*</ErrorPlaceholder>}*/}
            </RowValItem>

            <RowValItem>
              =
            </RowValItem>

            <RowValItem>
              {`${(+newFormTotalPrice).toFixed(2)} ₽`}
            </RowValItem>

          </RowValsField>

        </FCont>
      </Row>

      <Row>
        <Label>
          Вес за ед. товара в граммах
        </Label>
        <FCont>
          <PkInput style={{width: 92}}
                   placeholder={''}
                   value={weightPerOne}
                   bad={isBadWeightPerOne}
                   onChange={evt => {
                     setWeightPerOne(evt.target.value);
                   }}/>
          {isBadWeightPerOne &&
          <ErrorPlaceholder style={{left: 105, top: 0}}>
            Необходимо ввести положительное число.
          </ErrorPlaceholder>}
        </FCont>
      </Row>

      <Row>
        <Label>
          Налог
        </Label>
        <FCont>
          <SimpleDropdown options={TAX_OPTIONS}
                          value={TAX_OPTIONS.filter(a => (a.value == tax))[0]}
                          onChange={a => {
                            setTax(a.value);
                          }}
          />
        </FCont>
      </Row>

      <NewItemButtonPlaceholder>
        <PkButton disabled={!canSubmit} onClick={() => {
          setShouldHighlightErrors(true);
          if (canSubmit == false) {
            return;
          }
          AnalyticsAPI.trackEvent(`ADD_GOOD_BUTTON_CLICK`);
          onSave({
            name,
            quantity,
            pricePerOne,
            tax,
            weightPerOne
          });
        }}>
          СОХРАНИТЬ
        </PkButton>
        <AddGoodRightPlaceholder>
                  <span style={{marginRight: 10}}>
                    {'или'}
                  </span>
          <VoidMessageBottomButtonSpan onClick={() => {
            onCancel();
          }}>
            Отменить
          </VoidMessageBottomButtonSpan>

        </AddGoodRightPlaceholder>
      </NewItemButtonPlaceholder>

    </Wrapper>
  );
}

const Wrapper = styled.div`
    width: 100%;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
`;

const VoidMessageBottomButtonSpan = styled.span`
    cursor: pointer;
    font-size: 16px;
    border-bottom: 1px dotted #425684;
    color: #425684;
    :hover{
      opacity: 0.9;
    }
`;

const NewItemButtonPlaceholder = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 10px;
    justify-content: center;
`;

const AddGoodRightPlaceholder = styled.div`
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;


const FCont = styled.div`
    flex: 1;
    position: relative;
`;

const Label = styled.div`
    box-sizing: border-box;
    width: 220px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;


const RowValItem = styled.div`
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
`;

const FirstRowValItem = styled(RowValItem)`
    position: relative;
`;

const RowValsField = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;


const ItemRubPlaceholder = styled.div`
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    padding-left: 4px;
    padding-right: 4px;
    height: 100%;
    border-left: 1px solid #a9a9a9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #a9a9a9;
`;

const ErrorPlaceholder = styled.div`
    border: 2px solid red;
    padding: 7px;
    border-radius: 4px;
    position: absolute;
    top: 36px;
    left: -20px;
    background-color: white;
    z-index: 4;
`;
