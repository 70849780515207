import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import DeliveryAPI from "../api/DeliveryAPI";

export default function PrettyCitySelect(props) {
    const {
        code, onChange = newCode => {

        },
        className = '',
        red = false,
        placeholder = 'Название населённого пункта'
    } = props;

    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState(undefined);
    const [text, setText] = useState('');
    const [searching, setSearching] = useState(false);
    const [results, setResults] = useState([]);

    useEffect(() => {
        setSearching(true);
        DeliveryAPI.getCityByCode(code).then(city => {
            setSearching(false);
            if (city != undefined) {
                setSelectedOption({label: city.fullName, value: city.code});
            }
        })
    }, [code]);

    const node = useRef();
    const handleClick = e => {
        if (node == undefined || node.current == undefined) {
            return;
        }
        if (node.current.contains(e.target)) {
            return;
        }
        setDropdownVisible(false);
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    useEffect(() => {
        setSearching(true);
        DeliveryAPI.getDropdownCityOptions(text).then(options => {
            setSearching(false);
            setResults(options);
        });
    }, [text]);

    let isNotFound = (searching == false && text.trim() != '' && results.length == 0);

    return (
        <Wrapper ref={node} className={className}>
            <InputPlaceholder className={'pretty_select_input_placeholder'}>
                <InputDiv red={red} className={'pretty_select_input_div'} selected={dropdownVisible} onClick={() => {
                    setDropdownVisible(!dropdownVisible);
                }}>
                    <MonthName className={'pretty_select_city_name'}>
                        {selectedOption == undefined ?
                            <span style={{opacity: 0.6}}>{placeholder}</span> : selectedOption.label}
                    </MonthName>
                    <ArrowImage
                        className={'pretty_select_arrow_image'}
                        src={require('../../../assets/images/edit_ch.svg')}
                        style={{transform: `rotate(${dropdownVisible == true ? 180 : 0}deg)`}}/>
                </InputDiv>
            </InputPlaceholder>
            {dropdownVisible == false ? null :
                <DropdownPlaceholder className={'pretty_select_dropdown_placeholder'}>
                    <InnerInputPlaceholder className={'pretty_select_inner_input_placeholder'}>
                        <Input autoFocus={true} value={text} onChange={evt => {
                            setText(evt.target.value);
                        }}/>
                    </InnerInputPlaceholder>
                    {!(text == undefined || text.trim() == '') ? null :
                        <VoidPlaceholder className={'pretty_select_void_placeholder'}>
                            Начните набирать и выберите из выпадающего списка
                        </VoidPlaceholder>
                    }
                    {isNotFound == false ? null :
                        <VoidPlaceholder className={'pretty_select_void_placeholder'}>
                            Ничего не найдено
                        </VoidPlaceholder>
                    }
                    {searching == false ? null :
                        <VoidPlaceholder style={{opacity: 0.5}} className={'pretty_select_void_placeholder'}>
                            Поиск...
                        </VoidPlaceholder>
                    }

                    {results.length == 0 ? null :
                        <ResultsPlaceholder className={'pretty_select_results_placeholder'}>
                            {results.map((res, k) => {
                                return (
                                    <Item
                                        className={'pretty_select_item'}
                                        selected={((selectedOption != undefined) && (+selectedOption.value == +res.value))}
                                        key={res.value} onClick={() => {
                                        setSelectedOption(res);
                                        setDropdownVisible(false);
                                        onChange(res.value);
                                    }}>
                                        {res.label}
                                    </Item>
                                )
                            })}
                        </ResultsPlaceholder>
                    }

                </DropdownPlaceholder>
            }
        </Wrapper>
    );
}

const Wrapper = styled.div`
position: relative;
`;

const InputPlaceholder = styled.div`

`;

const DropdownPlaceholder = styled.div`
position: absolute;
top: 38px;
background: white;
border: 1px solid #a9a9a9;
z-index: 1000;
left: 0px;
right: 0px;
border-bottom-left-radius: 3px;
border-bottom-right-radius: 3px;
`;

const InputDiv = styled.div`
font-size: 18px;
background: #FFFFFF;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
width: 100%;
max-width: 100%;
color: #444;
padding-left: 11px;
height: 39px;
line-height: 39px;
box-sizing: border-box;
border-top-left-radius: 3px;
border-top-right-radius: 3px;

border-bottom-right-radius: $
{
    props => (props.selected == true ? 0 : 3)
}
px;
border-bottom-left-radius: $
{
    props => (props.selected == true ? 0 : 3)
}
px;

border: 1px solid $
{
    props => (props.red == true ? 'red' : '#646464')
}
;
cursor: pointer;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;

:hover
{
    opacity: 0.85;
}
`;

const MonthName = styled.div`

`;


const ArrowImage = styled.img`
width: 16px;
height: 16px;
margin-right: 11px;
opacity: 0.5;
transition-duration: 0.2s
`;

const Input = styled.input`
box-sizing: border-box;
width: 100%;
font-size: 18px;
border: 1px solid rgb(170, 170, 170);
line-height: 39px;
height: 39px;
padding-left: 4px;
outline: none;
`;

const VoidPlaceholder = styled.div`
padding: 4px;
line-height: 20px;
font-size: 14px;
margin-bottom: 4px;
font-family: PT Sans;
`;

const InnerInputPlaceholder = styled.div`
padding: 4px;
box-sizing: border-box;
`;

const ResultsPlaceholder = styled.div`
max-height: 200px;
overflow-y: auto;
`;

const Item = styled.div`
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
font-family: PT Sans;
width: 100%;
box-sizing: border-box;
height: 32px;
padding: 6px;
line-height: 20px;
font-size: 14px;
cursor: pointer;
background: $
{
    props => (props.selected == true ? 'lightgrey' : 'transparent')
}
;

:hover
{
    background - color
:
    #F7A40C;
    color: white;
}
`;
