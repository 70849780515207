import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import axios from 'axios'

import * as EmailValidator from 'email-validator';

import {PkInput} from "../../ui/Input";
import {PkTextarea} from "../../ui/Textarea";
import DeliveryHelper from "../../helpers/DeliveryHelper";
import CitySelect from "../../tools/CitySelect";

import {PkButton} from "../../ui/Button";
import PrettyCitySelect from "../../tools/PrettyCitySelect";


export default function UpdateContactForm(props) {
  const {
    code, street, building, phone, email, description, buttonName = 'Сохранить',
    onSave = d => {

    },
    canDelete = false,
    onDelete = () => {

    },
    loading = false
  } = props;

  const [formData, setFormData] = useState({
    code: props.code,
    name: props.name == undefined ? '' : props.name,
    email: props.email == undefined ? '' : props.email,
    phone: props.phone == undefined ? '' : props.phone,
    street: props.street == undefined ? '' : props.street,
    building: props.building == undefined ? '' : props.building
  });

  const canSubmit = ((EmailValidator.validate(formData.email) || formData.email.trim() == '') && (formData.name.trim() != ''));

  return (
    <Wrapper>

      <Field>
        <FLeft>
          Название
        </FLeft>
        <FRight>
          <PkInput placeholder={''} value={formData.name} onChange={evt => {
            setFormData({...formData, name: evt.target.value});
          }}/>
        </FRight>
      </Field>

      <Field>
        <FLeft>
          Населенный пункт
        </FLeft>
        <FRight>
          <PrettyCitySelect code={formData.code} onChange={newCode => {
            setFormData({...formData, code: newCode})
          }}/>
        </FRight>
      </Field>

      <Field>
        <FLeft>
          Улица
        </FLeft>
        <FRight>
          <PkInput placeholder={''} value={formData.street} onChange={evt => {
            setFormData({...formData, street: evt.target.value});
          }}/>
        </FRight>
      </Field>

      <Field>
        <FLeft>
          Номер дома
        </FLeft>
        <FRight>
          <PkInput placeholder={''} value={formData.building} onChange={evt => {
            setFormData({...formData, building: evt.target.value});
          }}/>
        </FRight>
      </Field>


      <Field>
        <FLeft>
          Телефон
        </FLeft>
        <FRight>
          <PkInput placeholder={''} value={formData.phone} onChange={evt => {
            setFormData({...formData, phone: evt.target.value});
          }}/>
        </FRight>
      </Field>

      <Field>
        <FLeft>
          E-mail
        </FLeft>
        <FRight>
          <PkInput
            bad={formData.email.trim().length > 0 && !EmailValidator.validate(formData.email)}
            placeholder={''} value={formData.email} onChange={evt => {
            setFormData({...formData, email: evt.target.value.trim()});
          }}/>
        </FRight>
      </Field>

      <Field>
        <FLeft>
          Примечание
        </FLeft>
        <FRight>
          <PkTextarea minRows={2}
                      placeholder={'Любой текст пометок о контакте'}
                      value={formData.description}
                      onChange={evt => {
                        setFormData({...formData, description: evt.target.value});
                      }}
          />
        </FRight>
      </Field>

      <BottomPlaceholder>
        <ButtonPlaceholder>
          {canDelete == false ? null :
            <span>
              {loading == true ? <span>...</span> :
                <DeleteSpan onClick={() => {
                  onDelete();
                }}>
                  удалить
                </DeleteSpan>
              }
            </span>

          }
          <PkButton disabled={!canSubmit} onClick={() => {
            if (canSubmit == false) {
              return;
            }
            onSave(formData);
          }}>
            {buttonName}
          </PkButton>
        </ButtonPlaceholder>
      </BottomPlaceholder>

    </Wrapper>
  );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-top: 17px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const FLeft = styled.div`
    width: 170px;
`;

const FRight = styled.div`
    flex: 1;
`;

const BottomPlaceholder = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

const ButtonPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

const DeleteSpan = styled.div`
    color: red;
    cursor: pointer;
    opacity: 0.7;
    margin-right: 20px;
    :hover{
      opacity: 1;
      text-decoration: underline;
    }
`;
