import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import axios from 'axios'

import PkModal from '../../modals/PkModal'
import AllTemplatesPanel from "./AllTemplatesPanel";

export default function TemplateSelectorSpan(props) {
  const {
    login, password,
    onSelect = a => {
    }
  } = props;
  const [visible, setVisible] = useState(false);

  return (
    <Wrapper>

      <Content onClick={() => {
        setVisible(true);
      }}>
        {props.children}
      </Content>

      {visible == false ? null :
        <PkModal name={'ШАБЛОНЫ'} width={620} zIndex={1500} onClose={() => {
          setVisible(false);
        }}>

          <Inner>
            <AllTemplatesPanel
              login={login} password={password}
              onTemplateClick={c => {
                let a = JSON.parse(JSON.stringify(c));
                delete a.name;
                delete a.description;
                delete a.createdAt;
                delete a.updatedAt;
                delete a.id;
                delete a.name;
                delete a.paykeeper_id;
                onSelect(a);
                setVisible(false);
              }}/>
          </Inner>

        </PkModal>
      }

    </Wrapper>
  );
}

const Wrapper = styled.div`
    
`;

const Content = styled.div`
    
`;

const Inner = styled.div`
    box-sizing: border-box;
`;
