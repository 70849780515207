import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import axios from 'axios'

const KEY = 'e0116c1d-91cc-4e5e-8377-64268bf330fa';

export default function MapWithClusters(props) {
    const {items = [], mapId = 'ymaps'} = props;
    const mapRef = useRef();

    useEffect(() => {
        setTimeout(() => {
            try{
                mapRef.current = new window.ymaps.Map(mapId, {
                    center: [55.76, 37.64],
                    zoom: 3
                });
            }catch(e){

            }
        }, 2000);
    }, []);

    return (
        <Wrapper id={mapId}>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 700px;
    height: 400px;
`;
