import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {Set} from 'immutable';
import styled from 'styled-components';

import {YMaps, Map, Placemark, GeoObject} from 'react-yandex-maps';
import {MAIN_ORANGE_COLOR, MAIN_RED_COLOR} from "../../ui/Colors";

const getMiddle = (a, b) => {
    console.log('getMiddle: a, b = ', a, b);
    if (a == undefined || b == undefined || a.lat == undefined || b.lat == undefined) {
        return undefined;
    }
    return {
        lat: 0.5 * (+a.lat + +b.lat),
        lon: 0.5 * (+a.lon + +b.lon),
    }
}

function getBounds(from, to) {
    let latArr = [from.lat, to.lat];
    let lonArr = [from.lon, to.lon];
    let minLat = Math.min(...latArr);
    let maxLat = Math.max(...latArr);
    let minLon = Math.min(...lonArr);
    let maxLon = Math.max(...lonArr);
    let dLat = maxLat - minLat;
    let dLon = maxLon - minLon;
    let latPadding = dLat * 0.05;
    let lonPadding = dLon * 0.05;
    return [
        [minLat - latPadding, minLon - lonPadding],
        [maxLat + latPadding, maxLon + lonPadding],
    ]
}

export default function FromToMapSelectorTool(props) {
    const {
        from: fromPoint, to: toPoint,
        onChange = d => {

        }
    } = props;

    const [from, setFrom] = useState(fromPoint);
    const [to, setTo] = useState(toPoint);

    useEffect(() => {
        onChange({
            from: from,
            to: to
        })
    }, [from, to]);

    useEffect(() => {
        // if ((JSON.stringify(fromPoint) != JSON.stringify(from)) || (JSON.stringify(toPoint) != JSON.stringify(to))) {
        //
        // }
        if (JSON.stringify(fromPoint) != JSON.stringify(from)) {
            setFrom(fromPoint);
        }
        if (JSON.stringify(toPoint) != JSON.stringify(to)) {
            setTo(toPoint);
        }
    }, [fromPoint, toPoint]);

    const map = useRef(null);
    const toPlacemark = useRef(null);
    const fromPlacemark = useRef(null);


    if (from == undefined || to == undefined || from.lat == undefined || to.lat == undefined) {
        return null;
    }
    let center = getMiddle(from, to);

    return (
        <Wrapper>


            <YMaps onApiAvaliable={(ymaps) => {

            }}>
                <Map instanceRef={map}
                     height="100%"
                     width="100%"
                     defaultState={{center: [center.lat, center.lon], zoom: 8}}
                     onLoad={() => {
                         let bounds = getBounds(from, to);
                         try {
                             map.current.setBounds(bounds);
                         } catch (exc) {
                         }
                     }}
                     options={{
                         maxZoom: 17,
                         minZoom: 10
                     }}
                >

                    {from == undefined ? null :
                        <GeoObject geometry={{
                            type: 'Point',
                            coordinates: [from.lat, from.lon],
                        }}
                                   ref={fromPlacemark}
                                   options={{
                                       preset: `islands#redIcon`,
                                       draggable: true
                                   }}
                                   onClick={() => {

                                   }}
                                   onDragEnd={e => {
                                       try {
                                           let arr = e.get('target').geometry.getCoordinates();
                                           console.log('onDragEnd: arr = ', arr);
                                           if (arr == undefined || arr.length == undefined) {
                                               return;
                                           }
                                           let [lat, lon] = arr;
                                           setFrom({
                                               lat: lat,
                                               lon: lon
                                           })
                                       } catch (e) {

                                       }
                                   }}
                        />
                    }

                    {to == undefined ? null :
                        <Placemark geometry={[to.lat, to.lon]}
                                   ref={toPlacemark}
                                   options={{
                                       preset: `islands#blueIcon`,
                                       // iconLayout: 'default#image',
                                       // iconImageHref: finishImage,
                                       // iconImageSize: [60, 60],
                                       // iconImageOffset: [-3, -60],
                                       draggable: true
                                   }}
                                   onDragEnd={e => {
                                       try {
                                           let arr = e.get('target').geometry.getCoordinates();
                                           console.log('onDragEnd: arr = ', arr);
                                           if (arr == undefined || arr.length == undefined) {
                                               return;
                                           }
                                           let [lat, lon] = arr;
                                           setTo({
                                               lat: lat,
                                               lon: lon
                                           })
                                       } catch (e) {

                                       }
                                   }}
                        />
                    }
                </Map>
            </YMaps>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`;
