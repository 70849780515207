import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import CalculatorPanel from "../../delivery/calculator/CalculatorPanel";
import AllPvzPanel from "../../pvz/AllPvzPanel";


export default function MapMarkerSection(props) {

    return (
        <Wrapper>

            <InnerPlaceholder>
                <LeftHeading>
                    Пункты выдачи
                </LeftHeading>
                <SubLeftHeader>
                    В России работают 1900 пунктов выдачи заказов.
                    <br/>
                    Пункты выдачи работают без выходных и позволяют получателю забрать свой заказ в удобное кремя.
                </SubLeftHeader>

                <CalculatorPlaceholder>
                    <AllPvzPanel/>
                </CalculatorPlaceholder>

            </InnerPlaceholder>


        </Wrapper>
    );
}

const CalculatorPlaceholder = styled.div`

`;

const breakWidth = 1200;


const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100vw;
  margin-top: 80px;
  padding-bottom: 40px;
`;


const InnerPlaceholder = styled.div`
  width: ${breakWidth}px;
  margin: 0 auto;
  box-sizing: border-box;
`;

const LeftHeading = styled.div`
  color: #292828;
  font-size: 36px;
  margin-bottom: 10px;
  font-weight: bold;
`;

const SubLeftHeader = styled.div`
  font-size: 16px;
  color: #000000;
  margin-bottom: 40px;
`;