import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

const LINKS = [
    {
        name: 'index',
        link: '/',
        label: 'Калькулятор доставки'
    },
    {
        name: 'credits',
        link: '/credits',
        label: 'Реквизиты'
    },
    {
        name: 'back',
        link: '/back',
        label: 'Информация по оплате'
    },
    {
        name: 'pvz',
        link: '/pvz',
        label: 'Пункты выдачи заказов'
    }
]

export default function PkTemplate(props) {
    let {active} = props;

    return (
        <Wrapper>
            <Header>
                <LinksPlaceholder>
                    {LINKS.map((lnk, k) => {
                        let isSelected = (active == lnk.name);
                        return (
                            <Item selected={isSelected} key={k} onClick={() => {
                                linkTo(lnk.link);
                            }}>
                                {lnk.label}
                            </Item>
                        )
                    })}
                </LinksPlaceholder>
            </Header>

            <Content>
                {props.children}
            </Content>

        </Wrapper>
    );
}

const headerHeight = 40;

const Wrapper = styled.div`
    color: #3b3838;
    font-family: "PT Sans";
    box-sizing: border-box;
    width: 1000px;
    margin: 0 auto;
    background: white;
    min-height: 100vh;
    box-shadow: 1px 0 1px 1px rgba(0,0,0,0.1), -1px 0 1px 1px rgba(0,0,0,0.1);
    @media(max-width: 1000px){
      width: 100%;
    }
`;

const Header = styled.div`
    height: ${headerHeight}px;
    width: 100%;
    //background: rgb(204, 22, 38, 0.95);
    background: white;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    border-bottom: 1px solid whitesmoke;
`;

const LinksPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
`;

const Item = styled.div`
    cursor: pointer;
    margin-right: 45px;
    font-size: 14px;
    display: flex;
    align-items: center;
    flex-direction: row;
    border-bottom: ${props => (props.selected == true ? '2px solid rgb(204, 22, 38, 0.95)' : 'none')};
    :hover{
      opacity: 0.75;
    }
`;

export const linkTo = (pkUrl) => {
    let url = window.location.origin + window.location.pathname + '#' + pkUrl;
    window.location.href = url;
}

const Content = styled.div`
    
`;
