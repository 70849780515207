import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import DeliveryAPI from "../../api/DeliveryAPI";

import PkModal from '../../modals/PkModal'
import DeliveryHelper from "../../helpers/DeliveryHelper";

import {PkInput} from "../../ui/Input";
import ReplenishmentForm from "../forms/ReplenishmentForm";
import {ReplenishmentButton} from "../../ui/Button";

export default function BalanceView(props) {
  let {
    balance, onReplenish = () => {

    },
    loading = false
  } = props;

  if (balance == undefined) {
    return null;
  }

  return (
    <Wrapper>

      <BalanceSpan>
        <span>
          {`Баланс ${(loading == true ? '...' : (balance == undefined ? '...' : (+balance).toFixed(2)))} руб.`}
        </span>
      </BalanceSpan>

      <ButtonSpan>

        {/*<ReplenishButton onClick={() => {*/}
          {/*onReplenish();*/}
        {/*}}>*/}
          {/*<CoinsImg src={require('../../assets/coins.png')} />*/}
          {/*<span><b>Пополнить</b></span>*/}
        {/*</ReplenishButton>*/}

        <ReplenishmentButton onClick={() => {
          onReplenish();
        }} >
          Пополнить
        </ReplenishmentButton>

      </ButtonSpan>

    </Wrapper>
  );
}

const InnerDialog = styled.div`
    min-height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;    
`;

const BalanceSpan = styled.div`
    margin-right: 20px;
    border-bottom: 1px dotted #212121;
    font-size: 14px;
`;

const ButtonSpan = styled.div`
    
`;

const ReplenishLink = styled.a`
    
`;

const ReplenishButton = styled.div`
    
    min-height: 27px;
    max-height: 27px;
    text-transform: uppercase;
    color: #e7920e;
    height: inherit;
    line-height: 1.5;
    font-size: 12px;
    padding: 2px 10px 2px;
    border-radius: 3px;
    background: #f4f4f4;;
    
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.05882) 0%, rgba(0, 0, 0, 0.03137) 100%);
    box-shadow: 0 1px 3px 0 #000000;
    margin-bottom: 1px;

    display: flex;
    flex-direction: row;
    align-items: center;
    background: white;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const CoinsImg = styled.img`
    width: 24px;
    height: 24px;
    margin-right: 4px;
`;
