import axios from 'axios'
import basic from 'basic-authorization-header'

export const DELIVERY_API_ENDPOINT = 'https://delivery.paykeeper.ru/api2'
const MAX_DROPDOWN_RESULTS_VALUE = 50;

const DeliveryAPI = {

    init(apiEndpoint = DELIVERY_API_ENDPOINT, paykeeperId, secret) {
        this.initialized = true
        this.apiEndpoint = apiEndpoint
        this.paykeeperId = paykeeperId;
        this.secret = secret;
        this.auth = {
            username: paykeeperId,
            password: secret
        };
    },


    getAuthHeaders(l, p) {
        let login = l || this.paykeeperId, password = p || this.secret;
        let d = {
            'Authorization': basic(login, password)
        };
        return d;
    },
    async createOrder(data, login, password) {
        // login = '191205-053-1'; // Sabir ИП
        let pld = (await axios.post(`${DELIVERY_API_ENDPOINT}/order?prod=1`, data, {
            auth: {
                username: login,
                password: password
            }
        })).data
        return pld
    },
    async deleteOrder(id, login, password) {
        try {
            let pld = (await axios.post(`${DELIVERY_API_ENDPOINT}/order/${id}/delete`, {id: id}, {
                auth: {
                    username: login,
                    password: password
                }
            })).data;
        } catch (exc) {
            console.log('caught exc during trying to delete the order: exc = ', exc);
        }
    },
    async getOrderStatus(id, login, password) {
        let pld = (await axios.get(`${DELIVERY_API_ENDPOINT}/order/${id}/status`, {
            auth: {
                username: login,
                password: password
            }
        })).data;
        return pld
    },
    async getPrice(data) {
        let pld = (await axios.get(`${DELIVERY_API_ENDPOINT}/calc/price/formatted`, {
            params: data
        }, {auth: this.auth})).data
        // console.log('formatted: getPrice: pld = ', pld);
        return pld
    },
    async getAllOrders() {
        let url = (window.location.href.indexOf('stab=1') > -1) ? `${DELIVERY_API_ENDPOINT}/mock/orders` : `${DELIVERY_API_ENDPOINT}/orders`;
        let pld = (await axios.get(url, {auth: this.auth})).data
        return pld
    },
    async getBalance(login, password) {
        let pld = (await axios.get(`${DELIVERY_API_ENDPOINT}/balance`, {
            headers: this.getAuthHeaders(login, password)
        })).data;
        return pld.balance
    },
    async getBalanceAndOrders(login, password) {
        if (allItemsAreEmpty([login, password])) {
            return {
                balance: 0,
                orders: []
            }
        }
        let auth = {
            username: login,
            password: password
        };
        let balance = (await axios.get(`${DELIVERY_API_ENDPOINT}/balance`, {
            auth: auth
        })).data.balance;
        let isStab = (window.location.href.indexOf('stab=1') > -1 || window.location.href.indexOf('dev-delivery') > -1);
        let orderUrl = isStab ? `${DELIVERY_API_ENDPOINT}/mock/orders` : `${DELIVERY_API_ENDPOINT}/orders`;
        let orders = (await axios.get(orderUrl, {auth: auth})).data;
        orders = orders.sort((a, b) => ((+new Date(b.createdAt)) - (+new Date(a.createdAt))));
        return {
            balance,
            orders
        }
    },
    async getCityByCode(code) {
        if (code == undefined || code == '') {
            return undefined;
        }
        return (await axios.get(`${DELIVERY_API_ENDPOINT}/cdek/search/city/code`, {params: {code: code}})).data;
    },
    async getCityNameByCode(code) {
        if (code == undefined || code == '') {
            return undefined;
        }
        let d = (await axios.get(`${DELIVERY_API_ENDPOINT}/cdek/search/city/code`, {params: {code: code}})).data;
        if (d == undefined) {
            return undefined;
        }
        return d.cityName;
    },
    async getPvzByCode(code) {
        if (code == undefined || code == '') {
            return undefined;
        }
        return (await axios.get(`${DELIVERY_API_ENDPOINT}/pvz_info/${code}`)).data;
    },
    async getDropdownCityOptions(city) {
        if (city == undefined || city == '') {
            return [];
        }
        console.log('getDropdownCityOptions: city = ', city);
        return (await axios.get(`${DELIVERY_API_ENDPOINT}/cdek/search/city_options/cache`,
            {params: {city: city, maxValue: MAX_DROPDOWN_RESULTS_VALUE}})).data;
    },

    async getDropdownStreetOptions(cityCdekCode, street) {
        if (street == undefined || street == '' || cityCdekCode == undefined) {
            return [];
        }
        console.log('getDropdownStreetOptions: cityCdekCode, street = ', cityCdekCode, street);
        return (await axios.get(`${DELIVERY_API_ENDPOINT}/cdek/search/search_options/cache`,
            {params: {cdekCode: cityCdekCode, maxValue: MAX_DROPDOWN_RESULTS_VALUE}})).data;
    },

    //contacts
    async getContacts(login, password) {
        if (allItemsAreEmpty([login, password])) {
            return []
        }
        let auth = {
            username: login,
            password: password
        };
        return (await axios.get(`${this.apiEndpoint}/contacts`, {
            auth: auth
        })).data;
    },
    async createOrUpdateContact(data, login, password) {
        if (allItemsAreEmpty([login, password])) {
            return;
        }
        return (await axios.post(`${this.apiEndpoint}/contacts`, data, {
            auth: {
                username: login,
                password: password
            }
        })).data;
    },
    async deleteContact(id, login, password) {
        if (allItemsAreEmpty([login, password])) {
            return;
        }
        return (await axios.post(`${this.apiEndpoint}/contacts/remove`, {id: id}, {
            auth: {
                username: login,
                password: password
            }
        })).data;
    },
    //templates
    async getTemplates(login, password) {
        if (allItemsAreEmpty([login, password])) {
            return []
        }
        let auth = {
            username: login,
            password: password
        };
        return (await axios.get(`${this.apiEndpoint}/templates`, {
            auth: auth
        })).data;
    },
    async createOrUpdateTemplate(data, login, password) {
        if (allItemsAreEmpty([login, password])) {
            return;
        }
        let {id, name, from_code, from_name, from_email, from_phone, from_street, from_building, from_comment, to_code, to_name, to_email, to_phone, to_street, to_building, to_comment} = data;
        let d = {
            id,
            name,
            from_code,
            from_name,
            from_email,
            from_phone,
            from_street,
            from_building,
            from_comment,
            to_code,
            to_name,
            to_email,
            to_phone,
            to_street,
            to_building,
            to_comment
        };
        return (await axios.post(`${this.apiEndpoint}/templates`, d, {
            auth: {
                username: login,
                password: password
            }
        })).data;
    },
    async deleteTemplate(id, login, password) {
        if (allItemsAreEmpty([login, password])) {
            return;
        }
        return (await axios.post(`${this.apiEndpoint}/templates/remove`, {id: id}, {
            auth: {
                username: login,
                password: password
            }
        })).data;
    },
    //settings
    async getSettings(login, password) {
        if (allItemsAreEmpty([login, password])) {
            return;
        }
        let auth = {
            username: login,
            password: password
        };
        return (await axios.get(`${this.apiEndpoint}/settings`, {
            auth: auth
        })).data;
    },
    async setDefaultAddress(role, data, login, password) {
        if (allItemsAreEmpty([login, password])) {
            return;
        }
        let auth = {
            username: login,
            password: password
        };
        return (await axios.post(`${this.apiEndpoint}/settings/set_default_address/${role}`, data, {
            auth: auth
        })).data;
    },
    async getAllStatuses(login, password) {
        let auth = {
            username: login,
            password: password
        };
        return (await axios.get(`${this.apiEndpoint}/all_statuses`, {
            auth: auth
        })).data;
    },
    async cdekIsWorking() {
        return (await axios.get(`${this.apiEndpoint}/ping`)).data.cdekIsUp;
    }
}

const allItemsAreEmpty = arr => {
    return arr.reduce((f, a) => (((a == undefined) || (`${a}`.trim() == '')) && f), true);
}

export default DeliveryAPI
