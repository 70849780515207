import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

export default function PhoneRulesTool(props) {

  return (
    <Wrapper>
      <p>
        Номер телефона рекомендуется передавать в следующем формате:
      </p>
      <ul>
        <li>
          номер должен быть написан в международном формате: код страны (для России +7) и сам номер (10 и более цифр);
        </li>
        <li>
          номер не должен содержать никаких букв и символов, кроме специальных символов:
          <ul>
            <li>
              "," (запятая), ";" (точка с запятой), "/" (косая черта), которые служат разделителями между номерами
            </li>
            <li>
              ":" (двоеточие), "#" (решетка), которые являются признаком добавочного номера
            </li>
          </ul>
        </li>
      </ul>
    </Wrapper>
  );
}

const Wrapper = styled.div`
    
`;
