import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import PkTemplate from "../templates/PkTemplate";
import CalculatorPanel from "../delivery/calculator/CalculatorPanel";
import AllPvzPanel from "../pvz/AllPvzPanel";

export default function IndexApp(props) {

    return (
        <PkTemplate active={'pvz'}>
            <Wrapper>

                <p>
                    Введите название населенного пункта, чтобы посмотреть список пунктов выдачи заказов в нем.
                </p>

                <AllPvzPanel/>
            </Wrapper>
        </PkTemplate>
    );
}

const Wrapper = styled.div`
    padding: 15px;
`;

const P = styled.div`
    line-height: 32px;
    margin-bottom: 10p;
`;
