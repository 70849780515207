import React, { useState, useEffect, useRef, useCallback, useReducer, useMemo } from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import axios from 'axios'
import PrettyAddressSelect from "../delivery2/tools/PrettyAddressSelect";
import PrettyCitySelect from "../delivery2/tools/PrettyCitySelect";
import RootLandingComponent from "../landing/RootLandingComponent";

export default function DevApp(props) {


    return (
        <Wrapper>

            <RootLandingComponent/>


            {/*<PrettyAddressSelect code={472} />*/}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;
