import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import DeliveryAPI from "../../api/DeliveryAPI";

export default function StatusesHistoryPanel(props) {
  const {id, partner_order_id} = props;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(undefined);

  useEffect(() => {
    DeliveryAPI.getOrderStatus(id).then(pld => {
      setLoading(false);
      setData(pld);
    })
  }, [id]);

  if (loading == true) {
    return (
      <div>
        Нет данных
      </div>
    )
  }
  if (data == undefined) {
    return null;
  }
  let states = data.states == undefined ? [] : data.states;
  if (data.errorCode == 'ERR_ORDERS_NOT_FOUND') {
    return (
        <div>
            Статус заказа в обработке. Попробуйте зайти на эту вкладку позже.
        </div>
    )
  }
  if (data.errorCode != undefined) {
    return (
      <div>
        {data.message}
      </div>
    )
  }

  return (
    <Wrapper>
      {/*{JSON.stringify(data)}*/}

      <TopPlaceholder>
        {`Номер накладной в СДЭК: ${partner_order_id} `}
        <br/>
        <a target={'_blank'} href={`https://www.cdek.ru/track.html?order_id=${partner_order_id}`}>
          {`Отследить на сайте СДЭК`}
        </a>
      </TopPlaceholder>

      <Item>
        <Field>
          <Heading>
            Город
          </Heading>
        </Field>
        <Field>
          <Heading>
            Дата
          </Heading>
        </Field>
        <Field>
          <Heading>
            Операция
          </Heading>
        </Field>
      </Item>
      <ItemsList>
        {states.map((st, k) => {
          return (
            <Item key={k}>
              <Field>
                {st.state_city_name}
              </Field>
              <Field>
                {moment(st.state_date).format('DD.MM.YYYY HH:mm:ss')}
              </Field>
              <Field>
                {st.state_description}
              </Field>
            </Item>
          )
        })}
      </ItemsList>
    </Wrapper>
  );
}

const Wrapper = styled.div`
    
`;

const TopPlaceholder = styled.div`
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgrey;
`;

const ItemsList = styled.div`
    
`;

const Item = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Field = styled.div`
    flex: 1;
`;

const Heading = styled.div`
    font-weight: bold;
    font-size: 14px;
`;
