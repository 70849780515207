import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import TimeSelector from "./TimeSelector";


export default function FromToTimeSelect(props) {
  let {
    fromTime, toTime,
    onChange = d => {

    },
    canSelectPast = true
  } = props;

  return (
    <Wrapper>
      <Item>
        <TimeSelector
          canSelectPast={canSelectPast}
          time={fromTime} onChange={d => {
          if (+d >= (+toTime)) {
            return;
          }
          onChange({fromTime: d, toTime});
        }}/>
      </Item>
      <CenterItem>
        {' - '}
      </CenterItem>
      <Item>
        <TimeSelector
          canSelectPast={canSelectPast}
          time={toTime} onChange={d => {
          if (+d <= (+fromTime)) {
            return;
          }
          onChange({toTime: d, fromTime});
        }}/>
      </Item>
    </Wrapper>
  );
}

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;    
`;

const Item = styled.div`
    
`;

const CenterItem = styled.div`
    text-align: center;
    width: 30px;
`;
