import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {HashRouter, Switch, Route} from 'react-router-dom'

import IndexApp from './IndexApp'
import CreditsApp from './CreditsApp'
import BackApp from './BackApp'
import PvzApp from './PvzApp'
import MonitoringApp from './MonitoringApp'
import CheckIpApp from './CheckIPApp'
import DemoApp from './DemoApp'
import DevApp from './DevApp'


const route = (
    <Switch>
        <Route exact path='/dev' component={DevApp}/>
        <Route exact path='/demo' component={DemoApp}/>
        <Route exact path='/ip' component={CheckIpApp}/>
        <Route exact path='/back' component={BackApp}/>
        <Route exact path='/credits' component={CreditsApp}/>
        <Route exact path='/pvz' component={PvzApp}/>
        <Route exact path='/monitoring' component={MonitoringApp}/>
        <Route component={IndexApp}/>
    </Switch>
);


export default function RouterApp(props) {

    return (
        <Wrapper id={'app'} >

            <HashRouter>
                {route}
            </HashRouter>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100vw;
    box-sizing: border-box;
    overflow-x: hidden !important;
`;
